import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { extractNumeric, saveAppFieldInLocalStorage } from '../utils';
import CurrencyInput from './common/CurrencyInput';
import { ProgressContext } from '../App';
import { DOMAIN, LOAN_APP_TYPE } from '../utils/constants';

export const PurchasePrice = () => {
  const router = useNavigate();
  const { setProgressPercentage } = useContext(ProgressContext);

  useEffect(() => {
    setProgressPercentage(10);
  }, []);

  function onSubmit(values) {
    saveAppFieldInLocalStorage(LOAN_APP_TYPE.HomeLoan, 'domain', DOMAIN.Home);
    saveAppFieldInLocalStorage(
      LOAN_APP_TYPE.HomeLoan,
      'purchasePrice',
      extractNumeric(values.purchasePrice),
      router,
      '/home-loans/finance/deposit'
    );
    return false;
  }

  function validateOnBlur(value) {
    let error;
    if (!value) {
      error = 'Required';
    }
    return error;
  }

  return (
    <>
      <p className="text-center text-2xl px-2">Get matched with home loan options</p>
      <div className="mt-6 px-3 w-96 mx-auto">
        <div className="border px-6 p-4 mt-5 text-sm">
          <ul className="list-disc">
            <li>Answer a few basic questions (takes about 30 seconds)</li>
            <li>Compare loans based on your specific needs and preference</li>
            <li>No impact to your credit score</li>
          </ul>
        </div>
        <div className="mt-3 mx-auto">
          <p className="text-lg">What is your expected purchase price?</p>
          <p className="text-xs">Your best guess is OK</p>
          <Formik validateOnBlur initialValues={{ purchasePrice: '' }} onSubmit={onSubmit}>
            {() => (
              <Form>
                <Field name="purchasePrice" validate={validateOnBlur}>
                  {({
                    field, // { name, value, onChange, onBlur }
                    meta
                  }) => (
                    <div>
                      <CurrencyInput
                        placeholder="$0"
                        type="text"
                        className="common-input-text"
                        {...field}
                      />
                      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                    </div>
                  )}
                </Field>
                <p className="py-4">
                  <button type="submit" className="common-button">
                    Next
                  </button>
                </p>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
