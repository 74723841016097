import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import BaseInput from '../../components/car-loans/form-components/BaseInput';
import ContinueButton from '../../components/car-loans/buttons/ContinueButton';
import { useProgressBarContext } from '../../contexts/ProgressBarContext';
import { useEffect } from 'react';
import { saveApplicantFieldInLocalStorage } from '../../utils';
import { LOAN_APP_TYPE } from '../../utils/constants';

export default function NamePage() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(13);
  }, []);

  const onFormSubmit = (data) => {
    saveApplicantFieldInLocalStorage(LOAN_APP_TYPE.CarLoan, 'firstName', data.firstName);
    navigate('../mobile-verification');
  };
  return (
    <>
      <h3 className="application-question">One more thing, what is your first name?</h3>
      <form className="application-form-container" onSubmit={handleSubmit(onFormSubmit)}>
        <BaseInput
          placeholder="Your first name"
          {...register('firstName', {
            required: 'First name is required',
            setValueAs: (val) => val.trim()
          })}
          error={errors?.firstName?.message}
        />
        <ContinueButton />
      </form>
    </>
  );
}
