import { useState, useEffect } from 'react';
import { toCurrency } from '../../utils';
import { PrincipalChart } from './PrincipalChart';

export const RepaymentsCalculator = () => {
  const [values, setValues] = useState({
    loanAmount: 450000,
    interestRate: 2.09,
    loanTerm: 30,
    frequency: 'month',
    monthlyRepayment: 0,
    repaymentType: 'principal-and-interest',
    interestOnlyPeriod: 0,
    interestOnlyRepayment: 0
  });

  const [remainingPrincipalData, setRemainingPrincipalData] = useState('');
  const [totalInterestCharged, setTotalInterestCharged] = useState(0);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((values) => {
      return {
        ...values,
        [name]: type === 'checkbox' ? checked : name === 'loanAmount' ? parseInt(value, 10) : value
      };
    });
  };

  const onCalculate = () => {
    const { loanAmount, interestRate, loanTerm, frequency } = values;

    let timePeriod;
    let yearlyPaymentCount;
    let loanTermRemainder;
    let interestOnlyEMI = 0;
    if (values.repaymentType === 'interest-only') {
      loanTermRemainder = loanTerm - values.interestOnlyPeriod;
    } else {
      loanTermRemainder = loanTerm;
    }
    if (frequency === 'month') {
      yearlyPaymentCount = 12;
    } else if (frequency === 'week') {
      yearlyPaymentCount = 52;
    } else if (frequency === 'fortnight') {
      yearlyPaymentCount = 26;
    }
    timePeriod = loanTermRemainder * yearlyPaymentCount;

    const m = monthlyPayment(
      loanAmount,
      timePeriod,
      interestRate / 100 / yearlyPaymentCount
    ).toFixed(0);

    if (values.repaymentType === 'interest-only') {
      interestOnlyEMI = interestOnlyPayment(
        loanAmount,
        interestRate / 100 / yearlyPaymentCount
      ).toFixed(0);
    }

    setValues({
      ...values,
      monthlyRepayment: m,
      interestOnlyRepayment: interestOnlyEMI
    });

    function monthlyPayment(p, n, i) {
      return (p * i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1);
    }

    /* calculate EMI for interest only period */
    function interestOnlyPayment(p, i) {
      return p * i;
    }
  };

  /* calculate EMI for interest only period */
  function getTotalInterest() {
    let totalInterestPaid = 0;
    let yearlyPaymentCount;
    let loanTermRemainder;

    if (values.frequency === 'month') {
      yearlyPaymentCount = 12;
    } else if (values.frequency === 'week') {
      yearlyPaymentCount = 52;
    } else if (values.frequency === 'fortnight') {
      yearlyPaymentCount = 26;
    }

    if (values.repaymentType === 'interest-only') {
      loanTermRemainder = values.loanTerm - values.interestOnlyPeriod;
      totalInterestPaid =
        values.interestOnlyPeriod * yearlyPaymentCount * values.interestOnlyRepayment;
    } else {
      loanTermRemainder = values.loanTerm;
    }
    totalInterestPaid =
      totalInterestPaid +
      loanTermRemainder * yearlyPaymentCount * values.monthlyRepayment -
      values.loanAmount;

    setTotalInterestCharged(totalInterestPaid);
  }

  useEffect(
    function () {
      if (values.monthlyRepayment > 0) {
        remainingPrincipalCal();
        getTotalInterest();
      }
    },
    [values.monthlyRepayment]
  );

  /* prepare chart array data */
  function remainingPrincipalCal() {
    //let totalInterestPaid = 0;
    let yearlyPaymentCount;
    let interestPaid;
    let remainingPrincipal = parseInt(values.loanAmount, 10);
    let loanTermRemainder = values.loanTerm;
    let interestOnlyPeriod = 0;
    if (values.repaymentType === 'interest-only') {
      loanTermRemainder = values.loanTerm - values.interestOnlyPeriod;
      interestOnlyPeriod = values.interestOnlyPeriod;
    }

    if (values.frequency === 'month') {
      yearlyPaymentCount = 12;
    } else if (values.frequency === 'week') {
      yearlyPaymentCount = 52;
    } else if (values.frequency === 'fortnight') {
      yearlyPaymentCount = 26;
    }
    let timePeriod = loanTermRemainder * yearlyPaymentCount;
    let remaingDataArray = [
      {
        year: 0,
        yearRemaining: values.loanTerm,
        remainingPrincipal: remainingPrincipal,
        remainingPrincipalCurrency: toCurrency(remainingPrincipal)
      }
    ];

    // prepare chart array for interest only years
    for (var i = 1; i <= interestOnlyPeriod; i++) {
      remaingDataArray.push({
        year: 0,
        yearRemaining: values.loanTerm - i,
        remainingPrincipal: remainingPrincipal,
        remainingPrincipalCurrency: toCurrency(remainingPrincipal)
      });
    }

    // prepare chart array for principal and interest years
    for (let i = 0; i < timePeriod; i++) {
      interestPaid = ((values.interestRate / yearlyPaymentCount) * remainingPrincipal) / 100;
      remainingPrincipal = remainingPrincipal - values.monthlyRepayment + interestPaid;
      if (i !== 0 && i % yearlyPaymentCount === 0) {
        remaingDataArray.push({
          year: i / yearlyPaymentCount,
          yearRemaining: loanTermRemainder - i / yearlyPaymentCount,
          remainingPrincipal: remainingPrincipal.toFixed(0),
          remainingPrincipalCurrency: toCurrency(remainingPrincipal.toFixed(0))
        });
      }
      //totalInterestPaid = totalInterestPaid + interestPaid;
    }

    // forcefully set end/last year data to 0
    remaingDataArray.push({
      year: values.loanTerm,
      yearRemaining: 0,
      remainingPrincipal: 0,
      remainingPrincipalCurrency: toCurrency(0)
    });
    setRemainingPrincipalData(remaingDataArray);
    //setTotalInterestCharged(totalInterestPaid);
  }

  return (
    <div className="max-w-6xl mx-auto">
      <p className="mt-8 text-4xl px-2 font-medium">Home Loan Repayment Calculator</p>
      <p className="mt-4 text-2xl px-2">
        Find out what your home loan repayments might look like and how they are calculated.
      </p>
      <div className="border bg-gray-100 py-5 px-5 rounded-md mt-10 mx-3">
        <div className="md:columns-3">
          <div className="pb-1">
            <label className="">Loan Amount</label>
            <input
              className="px-2 py-2 mt-1 border input-text rounded-md w-full"
              name="loanAmount"
              defaultValue={values.loanAmount}
              onChange={handleChange}
            />
          </div>
          <div className="pb-1 mt-4 md:mt-0">
            <label>Interest Rate</label>
            <input
              className="px-2 py-2 mt-1 border input-text rounded-md w-full"
              name="interestRate"
              defaultValue={values.interestRate}
              onChange={handleChange}
            />
          </div>
          <div className="pb-1 mt-4 md:mt-0">
            <label>Loan Term</label>
            <input
              className="px-2 py-2 mt-1 border input-text rounded-md w-full"
              name="loanTerm"
              defaultValue={values.loanTerm}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="md:columns-3 md:mt-10">
          <div className="mt-4 md:mt-0">
            <label className="">Repayment Frequency</label>
            <select
              className="px-2 py-0 mb-2 border input-text rounded-md w-full"
              name="frequency"
              onChange={handleChange}
              defaultValue={values.frequency}>
              <option value="week">Weekly</option>
              <option value="fortnight">Fortnightly</option>
              <option value="month">Monthly</option>
            </select>
          </div>
          <div className="w-full pb-3 mt-4 md:mt-0">
            <label>Repayment Type</label>
            <p className="md:mt-3 ">
              <span className="mr-4">
                <input
                  className="text-blue-800 focus:ring-2 focus:ring-blue-800"
                  type={'radio'}
                  name="repaymentType"
                  value="principal-and-interest"
                  onChange={handleChange}
                  checked={values.repaymentType === 'principal-and-interest'}
                />
                <label className="ml-2">Principal And Interest</label>
              </span>
              <span>
                <input
                  type={'radio'}
                  name="repaymentType"
                  value="interest-only"
                  onChange={handleChange}
                  checked={values.repaymentType === 'interest-only'}
                  className="text-blue-800 focus:ring-2 focus:ring-blue-800"
                />
                <label className="ml-2">Interest Only</label>
              </span>
            </p>
          </div>
          {values.repaymentType === 'interest-only' && (
            <div className="mt-4 md:mt-0">
              <label>Interest Only Period</label>
              <input
                className="px-2 py-2 mt-1 border input-text rounded-md w-full"
                name="interestOnlyPeriod"
                defaultValue={values.interestOnlyPeriod}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
        <div className="mt-5 flex">
          <div className="m-auto">
            <button className="common-button" onClick={onCalculate}>
              Calculate
            </button>
          </div>
        </div>
      </div>
      <div className="bg-green-100 py-10 px-10 my-6 mx-3 md:flex  ">
        {values.repaymentType === 'principal-and-interest' && (
          <div className="md:w-1/2">
            <p className="text-xl">Estimated Repayment</p>
            <p className="mt-6 text-5xl">
              {toCurrency(values.monthlyRepayment)}
              <span className="text-xl">/{values.frequency}</span>
            </p>
            <p className="text-xs mt-2">
              Based on an interest rate of {values.interestRate}% over {values.loanTerm} years
            </p>
          </div>
        )}
        {values.repaymentType === 'interest-only' && (
          <div className="md:w-2/3">
            <p className="text-xl">Estimated Repayment</p>
            <div className="grid grid-cols-2 gap-8">
              <div>
                <p className="mt-6 text-5xl">
                  {toCurrency(values.interestOnlyRepayment)}
                  <span className="text-xl">/{values.frequency}</span>
                </p>
                <p className="text-xs mt-2">First {values.interestOnlyPeriod} years</p>
              </div>
              <div>
                <p className="mt-6 text-5xl">
                  {toCurrency(values.monthlyRepayment)}
                  <span className="text-xl">/{values.frequency}</span>
                </p>
                <p className="text-xs mt-2">Remainder of loan term</p>
              </div>
            </div>
          </div>
        )}
        <div className="mt-6 md:mt-0">
          <p className="text-xl">Whats next?</p>
          <p className="text-sm">Check your home loan eligibility</p>
          <p className="mt-6">
            <button className="common-radio-rec-ghost-btn">GOTO NEXT STEP</button>
          </p>
        </div>
      </div>
      {remainingPrincipalData && <PrincipalChart remainingPrincipalData={remainingPrincipalData} />}
      {totalInterestCharged > 0 && (
        <div className="w-full py-10 px-10 mt-2 mx-3">
          <div className="grid grid-flow-col gap-3 p-1">
            <div className="col-span-4 text-right">Total interest charged</div>
            <div className="col-span-1 text-right">{toCurrency(totalInterestCharged)}</div>
          </div>
          <div className="grid grid-flow-col gap-3 p-1">
            <div className="col-span-4 text-right">Total repayments</div>
            <div className="col-span-1 text-right">
              {toCurrency(values.loanAmount + totalInterestCharged)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
