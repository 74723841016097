import {
  AnnotationIcon,
  CashIcon,
  ClipboardListIcon,
  GlobeAltIcon
} from '@heroicons/react/outline';

const features = [
  {
    name: 'Seamless Application Process',
    description:
      'Our online platform offers a user-friendly interface that simplifies the mortgage application process.',
    icon: GlobeAltIcon
  },
  {
    name: 'Comprehensive Lender Network',
    description:
      'We have partnered with a wide range of reputable lenders, giving users access to a diverse selection of mortgage options.',
    icon: ClipboardListIcon
  },
  {
    name: 'Personalized Recommendations',
    description:
      'We utilizes advanced algorithms and machine learning techniques to analyze user data and provide personalized mortgage recommendations.',
    icon: CashIcon
  },
  {
    name: 'Expert Guidance and Support',
    description:
      'While our platform enables users to navigate the mortgage process independently, we understand the importance of human expertise and guidance.',
    icon: AnnotationIcon
  }
];
export default function ChooseUs() {
  return (
    <div className="pt-28 py-32 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-2 text-lg leading-8 font-semibold tracking-tight text-teal-900 sm:text-lg">
            WHAT SETS US APART
          </p>
          <p className="mt-4 max-w-4xl text-3xl md:text-5xl text-gray-900 font-bold lg:mx-auto md:leading-snug">
            Tech to unlock low rates people to make the process easy
          </p>
        </div>

        <div className="mt-16">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative border p-9 rounded-xl shadow-lg">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-2xl leading-6 font-semibold text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16  text-gray-500 leading-relaxed text-lg">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
