import { useCallback, useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { useParams, useSearchParams } from 'react-router-dom';
import { currency, fetcher, getApplicantFullName } from '../../utils';
import {
  API_HOST,
  APPLICANT_PRIMARY,
  APPLICANT_SECONDARY,
  APPLICATION_TYPE_FINANCE
} from '../../utils/constants';
import { Link } from 'react-router-dom';
import { App, Popconfirm, Tabs } from 'antd';
import axios from 'axios';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import AddAssetModal from './AddAssetModal';
import AddLiabilityModal from './AddLiabilityModal';
import AddEmploymentIncomeModal from './AddEmploymentIncomeModal';
import AddOtherIncomeModal from './AddOtherIncomeModal';
import ExpensesModal from './ExpensesModal';
import EmploymentTab from './EmploymentTab';
import DocumentsTab from './DocumentsTab';
import ComplianceTab from './ComplianceTab';

export function ApplicationHome() {
  const { id: applicationId } = useParams();
  const [searchParams] = useSearchParams();
  const [application, setApplication] = useState(null);
  const [primaryApplicant, setPrimaryApplicant] = useState();
  const [secondaryApplicants, setSecondaryApplicants] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
  const [isLiabilityModalOpen, setIsLiabilityModalOpen] = useState(false);
  const [isEmpIncomeModalOpen, setIsEmpIncomeModalOpen] = useState(false);
  const [isOtherIncomeModalOpen, setIsOtherIncomeModalOpen] = useState(false);
  const [isExpensesModalOpen, setIsExpensesModalOpen] = useState(false);
  const [assetForEdit, setAssetForEdit] = useState(null);
  const [liabilityForEdit, setLiabilityForEdit] = useState(null);
  const [empForIncomeEdit, setEmpForIncomeEdit] = useState(null);
  const [otherIncomeForEdit, setOtherIncomeForEdit] = useState(null);

  const url = `${API_HOST}/applications/${applicationId}`;
  const quotesUrl = `${API_HOST}/applications/${applicationId}/quotes`;
  const { notification } = App.useApp();

  const { data: responseData } = useSWR(applicationId ? url : null, fetcher);
  const { data: quotesData } = useSWR(applicationId ? quotesUrl : null, fetcher);

  const refetchApplication = useCallback(() => mutate(url), []);

  /**
   * @param {string} type - Can be "asset" or "liability"
   */
  const deleteAssetLiab = async (id, type) => {
    const resource = type == 'asset' ? 'assets' : 'liabilities';
    const deleteEndpoint = `${url}/${resource}/${id}`;
    try {
      await axios.delete(deleteEndpoint);
      notification.success({
        message: `Deleted successfully`
      });
      refetchApplication();
    } catch (error) {
      notification.error({
        message: `Could not delete ${type}`,
        description: error.response?.data.message
      });
      console.error(error);
    }
  };

  const deleteOtherIncome = async (id) => {
    try {
      await axios.delete(`${url}/applicants/${primaryApplicant._id}/incomes/${id}`);
      notification.success({
        message: `Deleted successfully`
      });
      refetchApplication();
    } catch (error) {
      notification.error({
        message: `Could not delete income`,
        description: error.response?.data?.message
      });
      console.error(error);
    }
  };

  const hideLiabilityModal = useCallback(() => setIsLiabilityModalOpen(false), []);
  const hideAssetModal = useCallback(() => setIsAssetModalOpen(false), []);
  const hideEmpModal = useCallback(() => setIsEmpIncomeModalOpen(false), []);
  const hideOtherIncomeModal = useCallback(() => setIsOtherIncomeModalOpen(false), []);
  const hideExpensesModal = useCallback(() => setIsExpensesModalOpen(false), []);

  useEffect(() => {
    if (responseData) {
      setApplication(responseData);
      setPrimaryApplicant(responseData.applicants?.find((a) => a.type === APPLICANT_PRIMARY));
      setSecondaryApplicants(
        responseData.applicants?.filter((a) => a.type === APPLICANT_SECONDARY)
      );
    }
  }, [responseData]);

  useEffect(() => {
    if (quotesData) {
      setQuotes(quotesData);
    }
  }, [quotesData]);

  const defaultActiveKey = searchParams.get('tab') || 'financial-information';

  return (
    <div className="-mt-14 pb-4">
      <div className="w-11/12 md:w-2/3 mx-auto">
        <Link to="/dashboard" className="mb-3 block">
          <i className="ri-arrow-left-s-line align-text-bottom inline-block"></i> Back to
          applications
        </Link>
        {application ? (
          <Link to={`/applications/${application._id}/add-applicants`}>
            <div className="grid md:grid-cols-4 md:space-x-2 border rounded-md bg-gradient-to-r  from-blue-500 to-cyan-500 text-white">
              <div className="pt-3 pb-3 px-4 rounded-t-md ">
                <div className="text-lg">
                  {application.type === APPLICATION_TYPE_FINANCE ? 'New Purchase' : 'Refinance'}
                </div>
                <div className="text-4xl">
                  {application.type === APPLICATION_TYPE_FINANCE
                    ? currency(application.purchasePrice - application.depositAmount)
                    : currency(application?.currentLoanAmount)}
                </div>
                <div className="mt-5">
                  {application.properties[0]?.address || 'Address not known'}
                </div>
              </div>
              <div className="md:pt-10 pb-4 space-y-1">
                {/* <p>Started: {dayjs(application.createdAt).format('DD/MM/YYYY hh:mma')}</p>
                    <p>Updated: {dayjs(application.updatedAt).format('DD/MM/YYYY hh:mma')}</p> */}
                <p className="text-2xl">Loan Term</p>
                <p className="text-xl">{application.loanTermYears} Yrs</p>
              </div>
            </div>
          </Link>
        ) : (
          <div></div>
        )}
        {/* <p className='text-xl text-semibold mt-5'>Best Matches</p> */}
        <div className="mt-2">
          <div className="grid md:grid-cols-3 space-y-2 md:space-x-2 md:space-y-0">
            {quotes.map((q) => (
              <div
                className="border rounded-md shadow-sm hover:shadow-xl p-5 border-gray-300 bg-white"
                key={q._id}>
                <div>
                  <p className="text-2xl font-bold">{q.lender.name}</p>
                  <div className="grid grid-cols-2 space-x-5 mt-4  text-sm">
                    <div span={12}>
                      <p className="text-gray-400">Repayment</p>
                      <span className="font-semibold text-3xl text-green-400">
                        {currency(q.emi)}
                      </span>
                      <span className="text-gray-400"> /mo</span>
                    </div>
                    <div span={12}>
                      <p className="text-gray-400">Repayment Type</p>
                      <p className="font-semibold">{q.emiType}</p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 space-x-5 mt-6 text-sm">
                    <div span={12}>
                      <p className="text-gray-400">Customer Rate</p>
                      <p className="font-semibold">
                        {q.rateType} {q.rate}%
                      </p>
                    </div>
                    <div span={12}>
                      <p className="text-gray-400">Comparison Rate</p>
                      <p className="font-semibold">{q.comparisonRate}%</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <p className='text-xl'>Basic Information</p> */}
        <div className="grid grid-cols-2 space-x-2">
          <div className="mt-2  p-3 ">
            {/* <div className="mt-2 bg-white p-3 border border-gray-300 rounded-md"> */}
            <p className="text-xl mb-2 font-semibold">Primary Applicant</p>
            <div className="text">
              <p className="font-semibold">{getApplicantFullName(primaryApplicant?.userInfo)}</p>
              <p>{primaryApplicant?.userInfo?.email}</p>
              <p>+{primaryApplicant?.userInfo?.mobile}</p>
              <p>{primaryApplicant?.addresses[0]}</p>
            </div>
          </div>
          {secondaryApplicants?.map((secondaryApplicant) => (
            <div
              className="mt-2 bg-white p-3 border border-gray-300 rounded-md"
              key={secondaryApplicant._id}>
              <p className="mb-5 text-xl">Secondary Applicant</p>
              <div>
                <p className="font-semibold text-md">
                  {getApplicantFullName(secondaryApplicant?.userInfo)}
                </p>
                <p className="text-sm">{secondaryApplicant?.userInfo?.email}</p>
                <p className="text-sm">+{secondaryApplicant?.userInfo?.mobile}</p>
              </div>
            </div>
          ))}
        </div>
        <Tabs
          className="mt-2"
          defaultActiveKey={defaultActiveKey}
          items={[
            {
              key: 'financial-information',
              label: 'Financial Information',
              children: (
                <>
                  <div className="grid md:grid-cols-2 md:space-x-2">
                    <div className="mt-2 bg-white p-3 border border-gray-300 rounded-md">
                      <div className="grid grid-cols-[2fr_1fr_1fr] items-center mb-5">
                        <p className="text-xl font-semibold">Assets</p>
                        <p className="text-xl font-semibold text-right">
                          {currency(
                            application?.assets
                              ?.filter((c) => c.value)
                              .reduce((a, c) => a + c.value, 0)
                          )}
                        </p>
                        <div>
                          <button
                            className="dashboard-add-items-btn"
                            onClick={() => {
                              setAssetForEdit(null);
                              setIsAssetModalOpen(true);
                            }}>
                            Add more
                          </button>
                        </div>
                      </div>
                      {application?.assets?.map((asset) => {
                        return (
                          <div
                            className="text-sm grid grid-cols-[2fr_1fr_1fr] space-x-4 mt-3"
                            key={asset._id}>
                            <div>{asset.type}</div>
                            <div className="text-right">{currency(asset.value)}</div>
                            <div className="flex gap-4 justify-end items-center">
                              <PencilIcon
                                className="h-4 w-4 text-blue-500 cursor-pointer"
                                onClick={() => {
                                  setAssetForEdit(asset);
                                  setIsAssetModalOpen(true);
                                }}
                              />
                              <Popconfirm
                                title="Delete asset"
                                description="Do you want to delete the asset?"
                                okText="Delete"
                                okButtonProps={{ danger: true, type: 'primary', size: 'middle' }}
                                cancelButtonProps={{ size: 'middle' }}
                                onConfirm={() => deleteAssetLiab(asset._id, 'asset')}>
                                <TrashIcon className="h-4 w-4 text-red-600 cursor-pointer" />
                              </Popconfirm>
                            </div>
                          </div>
                        );
                      })}
                      {!application?.assets?.length && (
                        <div className="p-2 bg-orange-50 rounded text-sm">N/A</div>
                      )}
                    </div>
                    <div className="mt-2 bg-white p-3 border border-gray-300 rounded-md">
                      <div className="grid grid-cols-[2fr_1fr_1fr] items-center mb-5">
                        <p className="text-xl font-semibold">Liabilities</p>
                        <p className="text-xl font-semibold text-right">
                          {currency(
                            application?.liabilities
                              ?.filter((c) => c.outstandingAmount)
                              .reduce((a, c) => a + c.outstandingAmount, 0)
                          )}
                        </p>
                        <div>
                          <button
                            className="dashboard-add-items-btn"
                            onClick={() => {
                              setLiabilityForEdit(null);
                              setIsLiabilityModalOpen(true);
                            }}>
                            Add more
                          </button>
                        </div>
                      </div>

                      {application?.liabilities?.map((liability) => {
                        return (
                          <div
                            className="text-sm grid grid-cols-[2fr_1fr_1fr] space-x-4 mt-3"
                            key={liability._id}>
                            <div>{liability.type}</div>
                            <div className="text-right">
                              {currency(liability.outstandingAmount)}
                            </div>
                            <div className="flex gap-4 justify-end items-center">
                              <PencilIcon
                                className="h-4 w-4 text-blue-500 cursor-pointer"
                                onClick={() => {
                                  setLiabilityForEdit(liability);
                                  setIsLiabilityModalOpen(true);
                                }}
                              />
                              <Popconfirm
                                title="Delete liability"
                                description="Do you want to delete the liability?"
                                okText="Delete"
                                okButtonProps={{ danger: true, type: 'primary', size: 'middle' }}
                                cancelButtonProps={{ size: 'middle' }}
                                onConfirm={() => deleteAssetLiab(liability._id, 'liability')}>
                                <TrashIcon className="h-4 w-4 text-red-600 cursor-pointer" />
                              </Popconfirm>
                            </div>
                          </div>
                        );
                      })}
                      {!application?.liabilities?.length && (
                        <div className="p-2 bg-orange-50 rounded text-sm">N/A</div>
                      )}
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 md:space-x-2">
                    <div className="mt-2 bg-white p-3 border border-gray-300 rounded-md">
                      <p className="text-xl mb-1 font-semibold">Incomes</p>
                      <div className="flex justify-between items-center">
                        <p className="text-md mb-1 mt-3 font-semibold">Employment Incomes</p>
                      </div>
                      {!primaryApplicant?.employments?.length && (
                        <div className="p-2 bg-orange-50 rounded text-sm mt-2">N/A</div>
                      )}
                      {primaryApplicant?.employments?.map((employment) => {
                        return (
                          <div
                            className="text-sm grid grid-cols-[2fr_1fr_1fr] mt-2"
                            key={employment._id}>
                            <div>{employment.employerName}</div>
                            <div className="text-right">
                              {employment.netIncome ? (
                                currency(employment.netIncome)
                              ) : (
                                <i>Not added</i>
                              )}
                            </div>
                            <div className="text-right">
                              <PencilIcon
                                className="h-4 w-4 text-blue-500 cursor-pointer inline-block"
                                onClick={() => {
                                  setEmpForIncomeEdit(employment);
                                  setIsEmpIncomeModalOpen(true);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                      <div className="flex justify-between items-center mt-5 mb-4">
                        <p className="text-md font-semibold">Other Incomes</p>
                        <button
                          className="dashboard-add-items-btn"
                          onClick={() => {
                            setOtherIncomeForEdit(null);
                            setIsOtherIncomeModalOpen(true);
                          }}>
                          Add more
                        </button>
                      </div>
                      {primaryApplicant?.incomes?.map((income) => {
                        return (
                          <div
                            className="text-sm grid grid-cols-[2fr_1fr_1fr] mt-2"
                            key={income._id}>
                            <div>{income.type}</div>
                            <div className="text-right">{currency(income.netIncome)}</div>
                            <div className="flex gap-4 justify-end items-center">
                              <PencilIcon
                                className="h-4 w-4 text-blue-500 cursor-pointer"
                                onClick={() => {
                                  setOtherIncomeForEdit(income);
                                  setIsOtherIncomeModalOpen(true);
                                }}
                              />
                              <Popconfirm
                                title="Delete income"
                                description="Do you want to delete this income?"
                                okText="Delete"
                                okButtonProps={{ danger: true, type: 'primary', size: 'middle' }}
                                cancelButtonProps={{ size: 'middle' }}
                                onConfirm={() => deleteOtherIncome(income._id)}>
                                <TrashIcon className="h-4 w-4 text-red-600 cursor-pointer" />
                              </Popconfirm>
                            </div>
                          </div>
                        );
                      })}
                      {!primaryApplicant?.incomes?.length && (
                        <div className="p-2 bg-orange-50 rounded text-sm">N/A</div>
                      )}
                    </div>
                    <div className="mt-2 bg-white p-3 border border-gray-300 rounded-md self-start">
                      {/* <p className="text-xl mb-5 font-semibold">Expenses</p> */}
                      <div className="flex justify-between items-center mb-5">
                        <p className="text-xl font-semibold">Expenses</p>
                        <button
                          className="dashboard-add-items-btn"
                          onClick={() => setIsExpensesModalOpen(true)}>
                          Update
                        </button>
                      </div>
                      {!primaryApplicant?.expenses?.length ? (
                        <div className="p-2 bg-orange-50 rounded text-sm">N/A</div>
                      ) : (
                        <div className="text-sm grid grid-cols-2 space-x-4 mt-2">
                          <div>Total</div>
                          <div className="text-right">
                            {currency(
                              primaryApplicant?.expenses
                                ?.filter((c) => c.amount)
                                .reduce((a, c) => a + c.amount, 0)
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )
            },
            {
              key: 'employment',
              label: 'Employment',
              children: (
                <EmploymentTab
                  applicant={primaryApplicant}
                  refetchApplication={refetchApplication}
                />
              )
            },
            {
              key: 'documents',
              label: 'Documents',
              children: (
                <DocumentsTab
                  applicant={primaryApplicant}
                  refetchApplication={refetchApplication}
                />
              )
            },
            {
              key: 'compliance',
              label: 'Compliance',
              children: <ComplianceTab applicant={primaryApplicant} />
            }
          ]}
        />
      </div>
      <AddAssetModal
        isAssetModalOpen={isAssetModalOpen}
        hideAssetModal={hideAssetModal}
        application={application}
        refetchApplication={refetchApplication}
        assetForEdit={assetForEdit}
      />
      <AddLiabilityModal
        isLiabilityModalOpen={isLiabilityModalOpen}
        hideLiabilityModal={hideLiabilityModal}
        application={application}
        refetchApplication={refetchApplication}
        liabilityForEdit={liabilityForEdit}
      />
      <AddEmploymentIncomeModal
        isEmpIncomeModalOpen={isEmpIncomeModalOpen}
        empForIncomeEdit={empForIncomeEdit}
        hideEmpModal={hideEmpModal}
        primaryApplicant={primaryApplicant}
        refetchApplication={refetchApplication}
      />
      <AddOtherIncomeModal
        isOtherIncomeModalOpen={isOtherIncomeModalOpen}
        otherIncomeForEdit={otherIncomeForEdit}
        hideOtherIncomeModal={hideOtherIncomeModal}
        primaryApplicant={primaryApplicant}
        refetchApplication={refetchApplication}
      />
      <ExpensesModal
        isExpensesModalOpen={isExpensesModalOpen}
        hideExpensesModal={hideExpensesModal}
        refetchApplication={refetchApplication}
        primaryApplicant={primaryApplicant}
      />
    </div>
  );
}
