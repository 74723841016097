import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { VEHICLE_USAGES } from '../../utils/car-loans/constants';
import { useProgressBarContext } from '../../contexts/ProgressBarContext';
import { saveCarLoanAppFieldInLocalStorage } from '../../utils';
import SecondaryOptionButton from '../../components/car-loans/buttons/SecondaryOptionButton';

export default function UsagePage() {
  const navigate = useNavigate();

  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(4);
  }, []);

  const onOptionClick = (val) => {
    saveCarLoanAppFieldInLocalStorage('usage', val);
    navigate('../purchase-price');
  };

  return (
    <>
      <h3 className="application-question">What would be the usage of the vehicle?</h3>
      <div className="application-form-container">
        {VEHICLE_USAGES.map((type) => (
          <SecondaryOptionButton onOptionClick={onOptionClick} key={type}>
            {type}
          </SecondaryOptionButton>
        ))}
      </div>
    </>
  );
}
