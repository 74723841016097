import { useNavigate } from 'react-router-dom';
import { saveAppFieldInLocalStorage } from '../../../utils';
import { LOAN_APP_TYPE } from '../../../utils/constants';

export default function Hero() {
  const navigate = useNavigate();

  const onFinanceClick = () => {
    saveAppFieldInLocalStorage(LOAN_APP_TYPE.HomeLoan, 'source', 'website', null);
    saveAppFieldInLocalStorage(
      LOAN_APP_TYPE.HomeLoan,
      'type',
      'Finance',
      navigate,
      '/home-loans/finance/purchase-price'
    );
  };

  const onRefinanceClick = () => {
    saveAppFieldInLocalStorage(LOAN_APP_TYPE.HomeLoan, 'source', 'website', null);
    saveAppFieldInLocalStorage(
      LOAN_APP_TYPE.HomeLoan,
      'type',
      'Refinance',
      navigate,
      '/home-loans/refinance/current-loan-amount'
    );
  };

  return (
    <div className="hero">
      <div className="hero-overlay py-14 md:py-60">
        <div className="px-10 md:px-10 md:h-1/2 text-center">
          <h1 className="text-4xl md:text-7xl font-bold text-white tracking-tight">
            Buy A Home With
          </h1>
          <h1 className="text-4xl md:text-7xl font-bold text-white mt-3 tracking-tight">
            {' '}
            Confidence
          </h1>
          <h2 className="text-xl md:text-xl mt-2 text-white">What are you looking for?</h2>
          <div className="flex flex-col md:flex-row mt-4 md:mt-12 md:w-1/2  md:space-x-8 mx-auto justify-center space-y-4 md:space-y-0">
            <button
              className="bg-blue-700 text-lg text-white py-4 px-8 rounded-md  block hover:bg-blue-600 md:text-xl"
              onClick={onRefinanceClick}>
              I want to refinance
            </button>
            <button
              className=" bg-blue-700 text-lg text-white py-4 px-8 rounded-md  block hover:bg-blue-600 md:text-xl"
              onClick={onFinanceClick}>
              I want to buy a new home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
