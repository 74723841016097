// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

const starrating = [
  {
    id: 1,
    rating: (
      <svg className="w-24" role="img" viewBox="0 0 251 46" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fill="#16b831" d="M0 46.330002h46.375586V0H0z"></path>
          <path
            fill="#16b831"
            d="M39.533936 19.711433L13.230239 38.80065l3.838216-11.797827L7.02115 19.711433h12.418975l3.837417-11.798624 3.837418 11.798624h12.418975zM23.2785 31.510075l7.183595-1.509576 2.862114 8.800152L23.2785 31.510075z"></path>
        </g>
        <g>
          <path fill="#16b831" d="M51.24816 46.330002h46.375587V0H51.248161z"></path>
          <path fill="#16b831" d="M51.24816 46.330002h23.187793V0H51.248161z"></path>
          <path
            d="M74.990978 31.32991L81.150908 30 84 39l-9.660206-7.202786L64.30279 39l3.895636-11.840666L58 19.841466h12.605577L74.499595 8l3.895637 11.841466H91L74.990978 31.329909z"
            fill="#FFF"></path>
        </g>
        <g>
          <path fill="#16b831" d="M102.532209 46.330002h46.375586V0h-46.375586z"></path>
          <path fill="#16b831" d="M102.532209 46.330002h23.187793V0h-23.187793z"></path>
          <path
            d="M142.066994 19.711433L115.763298 38.80065l3.838215-11.797827-10.047304-7.291391h12.418975l3.837418-11.798624 3.837417 11.798624h12.418975zM125.81156 31.510075l7.183595-1.509576 2.862113 8.800152-10.045708-7.290576z"
            fill="#FFF"></path>
        </g>
        <g>
          <path fill="#16b831" d="M153.815458 46.330002h46.375586V0h-46.375586z"></path>
          <path fill="#16b831" d="M153.815458 46.330002h23.187793V0h-23.187793z"></path>
          <path
            className="tp-star__shape"
            d="M193.348355 19.711433L167.045457 38.80065l3.837417-11.797827-10.047303-7.291391h12.418974l3.837418-11.798624 3.837418 11.798624h12.418974zM177.09292 31.510075l7.183595-1.509576 2.862114 8.800152-10.045709-7.290576z"
            fill="#FFF"></path>
        </g>
        <g>
          <path fill="#16b831" d="M205.064416 46.330002h46.375587V0h-46.375587z"></path>
          <path fill="#16b831" d="M205.064416 46.330002h23.187793V0h-23.187793z"></path>
          <path
            d="M244.597022 19.711433l-26.3029 19.089218 3.837419-11.797827-10.047304-7.291391h12.418974l3.837418-11.798624 3.837418 11.798624h12.418975zm-16.255436 11.798642l7.183595-1.509576 2.862114 8.800152-10.045709-7.290576z"
            fill="#FFF"></path>
        </g>
      </svg>
    )
  }
];

const slideData = [
  {
    id: 1,
    day: '1 day ago',
    title: 'Excellent service and great staff',
    desc: 'Excellent service and great staff. Everything was fantastic and on time. Definitely Everything was fantastic and on time. Definitely.',
    auth: 'Dora and Miklos'
  },
  {
    id: 2,
    day: '1 day ago',
    title: 'Excellent service and great staff',
    desc: 'Excellent service and great staff. Everything was fantastic and on time. Definitely Everything was fantastic and on time. Definitely.',
    auth: 'Dora and Miklos'
  },
  {
    id: 3,
    day: '1 day ago',
    title: 'Excellent service and great staff',
    desc: 'Excellent service and great staff. Everything was fantastic and on time. Definitely Everything was fantastic and on time. Definitely.',
    auth: 'Dora and Miklos'
  },
  {
    id: 4,
    day: '1 day ago',
    title: 'Excellent service and great staff',
    desc: 'Excellent service and great staff. Everything was fantastic and on time. Definitely Everything was fantastic and on time. Definitely.',
    auth: 'Dora and Miklos'
  },
  {
    id: 5,
    day: '1 day ago',
    title: 'Excellent service and great staff',
    desc: 'Excellent service and great staff. Everything was fantastic and on time. Definitely Everything was fantastic and on time. Definitely.',
    auth: 'Dora and Miklos'
  },
  {
    id: 6,
    day: '1 day ago',
    title: 'Excellent service and great staff',
    desc: 'Excellent service and great staff. Everything was fantastic and on time. Definitely Everything was fantastic and on time. Definitely.',
    auth: 'Dora and Miklos'
  }
];

const Rating = () => {
  return (
    <Swiper
      loop={true}
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      spaceBetween={20}
      navigation={true}
      //pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      autoplay={{
        delay: 6000,
        disableOnInteraction: false
      }}
      breakpoints={{
        414: {
          slidesPerView: 1
        },
        768: {
          slidesPerView: 4
        }
      }}>
      <div className="testimonials">
        {slideData.map((rating) => (
          <SwiperSlide key={rating}>
            <div className="bg-white p-6 rounded shadow-xl relative pb-10" key={rating.id}>
              <div className="flex">
                <div className="w-9/12">{starrating[0].rating}</div>
                <div className="w-3/12 text-xs text-right text-gray-500 font-light">
                  {rating.day}
                </div>
              </div>
              <div className="mt-3">
                <h4 className="font-semibold text-medium text-sm mb-2">
                  <a href="!#">{rating.title}</a>
                </h4>
                <a href="!#" className="text-xs testi-text">
                  {rating.desc}
                </a>
              </div>
              <a href="!#" className="absolute text-xs bottom-3 text-gray-500">
                {rating.auth}
              </a>
            </div>
          </SwiperSlide>
        ))}
      </div>
    </Swiper>
  );
};
export default Rating;
