import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import {
  getFromLocalStorage,
  saveApplicantFieldInLocalStorage,
  setLocalStorageData
} from '../utils';
import { Field, Form, Formik } from 'formik';
import axios from 'axios';
import { API_HOST, LOAN_APP_TYPE } from '../utils/constants';
import { ProgressContext } from '../App';

export const Signup = () => {
  const router = useNavigate();
  const [smsSent] = useState(false);
  const { setProgressPercentage } = useContext(ProgressContext);

  useEffect(() => {
    setProgressPercentage(100);
  }, []);

  function validateNumber(value) {
    let error;
    if (!value) {
      error = 'Required';
    } else if (isNaN(+value)) {
      error = 'Not a valid mobile number';
    }
    return error;
  }

  async function onSubmit(values) {
    const application = getFromLocalStorage(LOAN_APP_TYPE.HomeLoan);
    application.applicants[0].mobile = values.mobile;

    // Set mobile number for authentication
    setLocalStorageData('mobile', values.mobile);
    const lead = {
      application,
      source: 'website',
      leadCreationSource: 'Funnel'
    };

    try {
      await axios.post(`${API_HOST}/leads`, lead);
      await axios.post(`${API_HOST}/auth/auth-number`, {
        mobile: `${values.mobile}`
      });
      saveApplicantFieldInLocalStorage(
        LOAN_APP_TYPE.HomeLoan,
        'mobile',
        values.mobile,
        router,
        '/verify'
      );
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="md:mt-20 mt-0 flex justify-around">
      <div className="md:w-2/3 md:px-40 ">
        <p className="text-center mt-8 text-3xl md:text-4xl px-2">
          Protecting your information is our number one priority
        </p>
        <p className="text-center mt-8 text-md px-2">
          Loanspad uses a one-time password with your Australian mobile number. You will receive a
          one-time password via SMS.
        </p>
        <div className="mt-6 px-3 w-96 mx-auto">
          <Formik validateOnBlur initialValues={{ mobile: '' }} onSubmit={onSubmit}>
            {() => (
              <Form>
                <Field name="mobile" validate={validateNumber}>
                  {({ field, meta }) => (
                    <div>
                      <input
                        type={'text'}
                        pattern="[0-9]*"
                        placeholder="+61"
                        disabled={smsSent}
                        className="common-input-text"
                        {...field}
                      />
                      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                    </div>
                  )}
                </Field>
                <p className="py-8">
                  <button disabled={smsSent} type="submit" className="common-button">
                    Next
                  </button>
                </p>
              </Form>
            )}
          </Formik>
          <p className="text-center text-gray-400 mt-0 text-sm px-2">
            We will never sell your personal details to anyone. Ever. By registering you agree to
            Loanspad’s Privacy Policy.
          </p>
        </div>
      </div>
      <div className="w-1/3 hidden md:block mt-10">
        <img src="/signup-image.jpeg" width={372} height={234} alt="" />
        <ul className="mt-10 list-disc text-md leading-8">
          <li>Unlock our best home loan rates</li>
          <li> Get free property reports</li>
          <li> Save your progress for later</li>
          <li> Get free advice from a lending specialist</li>
        </ul>
      </div>
    </div>
  );
};
