import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import axios from 'axios';
import { API_HOST } from '../utils/constants';
import { setLocalStorageData } from '../utils';

export const Signin = () => {
  const router = useNavigate();
  const [apiError, setApiError] = useState();

  function validateNumber(value) {
    let error;
    if (!value) {
      error = 'Required';
    } else if (isNaN(+value)) {
      error = 'Not a valid mobile number';
    }
    return error;
  }

  async function onSubmit(values) {
    try {
      await axios.post(`${API_HOST}/auth/auth-number`, { mobile: `${values.mobile}` });
      // const { token } = responseData;
      setLocalStorageData('mobile', values.mobile);
      router('/verify');
    } catch (e) {
      console.log(e);
      setApiError(e?.response?.data?.message);
    }
  }

  return (
    <>
      <p className="text-center mt-16 text-2xl md:text-3xl px-2">
        Signin by entering your registered mobile number
      </p>
      {apiError ? (
        <div className="text-center mt-5">
          <p className="p-5 bg-red-100 text-red-800 rounded-md w-96 m-auto">{apiError}</p>
        </div>
      ) : null}
      <div className="mt-10">
        <div className="mt-3 flex justify-between px-3 w-96 mx-auto">
          <Formik validateOnBlur initialValues={{ mobile: '' }} onSubmit={onSubmit}>
            {() => (
              <Form>
                <Field name="mobile" validate={validateNumber}>
                  {({
                    field, // { name, value, onChange, onBlur }
                    meta
                  }) => (
                    <div>
                      <input
                        // valid
                        // validateOnBlur
                        type={'text'}
                        pattern="[0-9]*"
                        placeholder="+61"
                        // disabled={smsSent}
                        className="common-input-text"
                        {...field}
                      />
                      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                    </div>
                  )}
                </Field>
                <div className="mt-6 px-3 w-96 mx-auto">
                  <button className="bg-blue-700 hover:bg-blue-600 focus:bg-blue-600 text-white py-3 px-1 w-full rounded-md shadow-lg">
                    Sign in
                  </button>
                </div>
                <p className="text-center mt-6 text-md">
                  <button>Resend SMS?</button>
                </p>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
