/* eslint-disable react/prop-types */
import clsx from 'clsx';

export default function ActionButton({
  children,
  className: passedClassName,
  onButtonClick,
  ...rest
}) {
  return (
    <button
      className={clsx(
        'bg-primary-blue text-white font-semibold py-2 px-5 rounded-md',
        passedClassName
      )}
      onClick={onButtonClick}
      {...rest}>
      {children}
    </button>
  );
}
