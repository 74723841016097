/* eslint-disable react/prop-types */
import clsx from 'clsx';

export default function CommonOptions({ children, className: passedClassName }) {
  return (
    <div className={clsx(passedClassName)}>
      <p className="mb-2 text-sm">Common options:</p>
      <div className="flex gap-3">{children}</div>
    </div>
  );
}

CommonOptions.Tag = CommonOptionTag;

function CommonOptionTag({ children, value, onTagClick }) {
  return (
    <div
      className="bg-bluey-gray text-primary-blue border border-dashed border-primary-blue/70 py-1 px-2 rounded-[4px] text-xs font-semibold cursor-pointer"
      onClick={() => onTagClick(value)}>
      {children}
    </div>
  );
}
