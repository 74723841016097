import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import CommonOptions from '../../components/car-loans/page-components/CommonOptions';
import ContinueButton from '../../components/car-loans/buttons/ContinueButton';
import ApplicationAmountInput from '../../components/car-loans/form-components/ApplicationAmountInput';
import { useProgressBarContext } from '../../contexts/ProgressBarContext';
import { saveCarLoanAppFieldInLocalStorage } from '../../utils';

export default function PurchasePricePage() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue
  } = useForm();

  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(5);
  }, []);

  const onFormSubmit = (data) => {
    const purchasePriceNum = Number(data.purchasePrice);
    if (isNaN(purchasePriceNum)) {
      return setError('purchasePrice', { message: 'Invalid input' });
    }
    saveCarLoanAppFieldInLocalStorage('purchasePrice', purchasePriceNum);
    navigate('../deposit');
  };

  const setPurchasePrice = (val) => {
    setValue('purchasePrice', val);
  };

  return (
    <>
      <h3 className="application-question">What is the vehicle purchase price?</h3>
      <form className="application-form-container" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="grid gap-2">
          <ApplicationAmountInput
            name="purchase-price-input"
            {...register('purchasePrice', {
              required: 'Purchase price is required',
              min: {
                value: 2000,
                message: 'Minimum purchase price is 2000'
              }
            })}
            error={errors?.purchasePrice?.message}
          />
          <CommonOptions className="mt-2">
            <CommonOptions.Tag value={20000} onTagClick={setPurchasePrice}>
              20,000
            </CommonOptions.Tag>
            <CommonOptions.Tag value={25000} onTagClick={setPurchasePrice}>
              25,000
            </CommonOptions.Tag>
            <CommonOptions.Tag value={30000} onTagClick={setPurchasePrice}>
              30,000
            </CommonOptions.Tag>
          </CommonOptions>
        </div>
        <ContinueButton />
      </form>
    </>
  );
}
