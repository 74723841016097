/* eslint-disable no-unused-vars */
import { Button, Col, Form, InputNumber, Modal, Row, Select, App, Divider, Typography } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_HOST, Frequency, expenseTypes } from '../../utils/constants';
import axios from 'axios';
import PropTypes from 'prop-types';

function ExpensesModal({
  isExpensesModalOpen,
  hideExpensesModal,
  refetchApplication,
  primaryApplicant
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { id: applicationId } = useParams();
  const { notification } = App.useApp();

  useEffect(() => {
    const initialData = {};
    primaryApplicant?.expenses?.forEach((expense) => {
      initialData[expense.type] = expense.amount || 0;
      initialData[`${expense.type}-frequency`] = expense.frequency || Frequency.Monthly;
    });
    form.setFieldsValue(initialData);
  }, [primaryApplicant]);

  const onFinish = async (values) => {
    const endpoint = `${API_HOST}/applications/${applicationId}/applicants/${primaryApplicant._id}/expenses`;

    const data = [];
    Object.keys(values).forEach((key) => {
      if (!key.includes('-frequency')) {
        data.push({
          type: key,
          amount: values[key],
          frequency: values[`${key}-frequency`]
        });
      }
    });

    //submit data
    try {
      setIsLoading(true);
      await axios.put(endpoint, data);
      setIsLoading(false);
      hideExpensesModal();
      notification.success({
        message: `Expenses updated successfully`
      });
      form.resetFields();
      refetchApplication();
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: `Could not update expenses`,
        description: error.response?.data?.message
      });
      console.log(error);
    }
  };

  return (
    <Modal
      title="Update Expenses"
      // footer={null}
      style={{ top: 20 }}
      open={isExpensesModalOpen}
      onCancel={hideExpensesModal}
      width={800}
      onOk={form.submit}
      okButtonProps={{ loading: isLoading }}
      // styles={{ body: { maxWidth: '90%' } }}
      forceRender>
      <div className="max-h-[500px] overflow-x-hidden pr-3">
        <Row gutter={12} style={{ marginBottom: 20 }} className="mt-6">
          <Col span={12}>
            <Typography.Text strong>Expense Type</Typography.Text>
          </Col>
          <Col span={4}>
            <Typography.Text strong>Amount</Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text strong>Frequency</Typography.Text>
          </Col>
        </Row>
        <Divider />
        <Form form={form} layout="vertical" onFinish={onFinish}>
          {expenseTypes.map((expenseType) => (
            <div key={expenseType}>
              <Row gutter={12}>
                <Col span={12}>
                  <p>
                    <Typography.Text>{expenseType}</Typography.Text>
                    <br />
                    <Typography.Text style={{ fontSize: '0.75rem' }} type="secondary" />
                  </p>
                </Col>

                <Col span={4}>
                  <Form.Item name={expenseType}>
                    <InputNumber
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <div>
                    <Form.Item name={`${expenseType}-frequency`}>
                      <Select style={{ width: '100%' }}>
                        {Object.keys(Frequency).map((k) => (
                          <Select.Option key={k} value={k}>
                            {Frequency[k]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </Form>
      </div>
    </Modal>
  );
}

export default memo(ExpensesModal);

ExpensesModal.propTypes = {
  isExpensesModalOpen: PropTypes.bool,
  hideExpensesModal: PropTypes.func,
  refetchApplication: PropTypes.func,
  primaryApplicant: PropTypes.object
};
