/* eslint-disable react/prop-types */
import { TopBar } from './TopBar';
import { ProgressBar } from './ProgressBar';
import { useLocation } from 'react-router-dom';

export default function Layout(props) {
  const { pathname } = useLocation();
  const isFunnelStep = () => {
    return pathname.includes('finance') || pathname.includes('refinance');
  };

  return (
    <>
      <div className="h-full">
        <div className="border-b shadow-sm bg-white">
          <div className="container mx-auto">
            <TopBar />
          </div>
        </div>
        {isFunnelStep() ? <ProgressBar /> : null}
        <div className="bg-gray-50 h-full pt-10 md:pt-20">{props.children}</div>
      </div>
    </>
  );
}
