import { useNavigate } from 'react-router-dom';
import { INCOME_DURATION_LABEL_VALUE_ARRAY } from '../../utils/car-loans/constants';
import { useProgressBarContext } from '../../contexts/ProgressBarContext';
import { useEffect } from 'react';
import { saveApplicantFieldInLocalStorage } from '../../utils';
import { LOAN_APP_TYPE } from '../../utils/constants';
import SecondaryOptionButton from '../../components/car-loans/buttons/SecondaryOptionButton';

export default function IncomeHistoryPage() {
  const navigate = useNavigate();

  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(12);
  }, []);

  const onOptionClick = (val) => {
    saveApplicantFieldInLocalStorage(LOAN_APP_TYPE.CarLoan, 'isAnnualIncomeMoreThan3Mth', val);
    navigate('../name');
  };

  return (
    <>
      <h3 className="application-question">How long have you been earning this income?</h3>
      <div className="application-form-container">
        {INCOME_DURATION_LABEL_VALUE_ARRAY.map(({ label, value }) => (
          <SecondaryOptionButton key={value} onOptionClick={onOptionClick} value={value}>
            {label}
          </SecondaryOptionButton>
        ))}
      </div>
    </>
  );
}
