import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher, headers } from '../../utils';
import { API_HOST } from '../../utils/constants';
import axios from 'axios';

export const ResidencyStatus = () => {
  const router = useNavigate();

  const { id: applicationId } = router.query;
  const [primaryApplicant, setPrimaryApplicant] = useState();

  const url = `${API_HOST}/applications`;
  const { data: responseData } = useSWR(applicationId ? `${url}/${applicationId}` : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setPrimaryApplicant(responseData.applicants.find((a) => a.type === 'Primary'));
    }
  }, [responseData]);

  const setValue = async (val) => {
    try {
      await axios.put(
        `${url}/${applicationId}/applicants/${primaryApplicant._id}`,
        { residencyStatus: val },
        { headers }
      );
      router(`/applications/${applicationId}/applicants/${primaryApplicant._id}/relationship`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <p className="text-center mt-8 text-2xl px-2">What is your residency status?</p>

      <div className="mt-6 px-3 w-96 mx-auto">
        <p className="py-4">
          <button
            onClick={() => setValue('Australian Citizen')}
            className="common-radio-rec-ghost-btn">
            Australian Citizen
          </button>
        </p>
        <p className="py-4">
          <button
            onClick={() => setValue('Permanent resident')}
            className="common-radio-rec-ghost-btn">
            Permanent resident
          </button>
        </p>
        <p className="py-4">
          <button
            onClick={() => setValue('Permanent resident')}
            className="common-radio-rec-ghost-btn">
            Permanent resident
          </button>
        </p>
        <p className="py-4">
          <button
            onClick={() => setValue("It's complicated")}
            className="common-radio-rec-ghost-btn">
            {"It's complicated"}
          </button>
        </p>
      </div>
    </>
  );
};
