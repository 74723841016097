import { Button, Card, Empty, Skeleton } from 'antd';
import { memo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

function ComplianceTab({ applicant }) {
  const { id: applicationId } = useParams();

  if (!applicant) return <Skeleton />;

  const { complianceDocsRequests } = applicant;
  if (complianceDocsRequests) {
    var { createdAt, _id } = applicant.complianceDocsRequests[0];
  }

  return (
    <div className="mt-2 bg-white pl-5 pr-10 py-4 border border-gray-300 rounded-md">
      {/* display request only if they are not signed */}
      {!complianceDocsRequests || complianceDocsRequests[0].signedAt ? (
        <Empty description="No pending e-signature" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          <p className="text-xl font-semibold">E-signature Request</p>
          <Card size="small" className="mt-4">
            <div className="flex justify-between items-center px-3">
              <div>
                <p className="font-semibold">Requested At:</p>
                <p>{dayjs(createdAt).format('DD/MM/YYYY hh:mm a')}</p>
              </div>
              <div>
                <Button
                  type="primary"
                  target="_blank"
                  href={`/esign/applications/${applicationId}/applicant/${applicant._id}/request/${_id}`}>
                  Open
                </Button>
              </div>
            </div>
          </Card>
        </>
      )}
    </div>
  );
}

ComplianceTab.propTypes = {
  applicant: PropTypes.object
};

export default memo(ComplianceTab);
