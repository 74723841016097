import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { API_HOST } from '../utils/constants';
import { fetcher } from '../utils';
import { useParams } from 'react-router-dom';
import Nav from './common/home/Nav';
import Footer from './common/home/Footer';
import Regualtions from './common/home/Regualtions';
import { Helmet } from 'react-helmet-async';

function Pages() {
  const [page, setPage] = useState([]);
  const { slug } = useParams();
  const url = `${API_HOST}/pages/${slug}`;

  const { data: responseData } = useSWR(slug ? url : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setPage(responseData);
    }
  }, [responseData]);

  return (
    <div>
      <Helmet>
        <title>{page?.metaTitle ? `${page?.metaTitle} - Loanspad` : 'Loanspad'} </title>
        <meta name="description" content={page?.metaDescription} />
        <meta name="keywords" content={page?.metaKeywords?.join(',')} />
        <meta property="og:image" content={page?.imgUrl} />
        <meta property="og:title" content={page?.metaTitle} />
        <meta property="og:description" content={page?.metaDescription} />
      </Helmet>
      <Nav />
      <div className="p-10 container mx-auto">
        <div className="text-md lg:text-lg lg:leading-8 text-gray-700 font-light">
          <div className="font-semibold text-4xl mb-6">{page?.title}</div>
          <div dangerouslySetInnerHTML={{ __html: page?.content }} />
        </div>
      </div>
      <Footer />
      <Regualtions />
    </div>
  );
}

export default Pages;
