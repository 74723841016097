import { useNavigate } from 'react-router-dom';
import { clearExistingApplicationInLocalStorate, saveAppFieldInLocalStorage } from '../utils';
import {
  APPLICATION_TYPE_FINANCE,
  APPLICATION_TYPE_REFINANCE,
  LOAN_APP_TYPE
} from '../utils/constants';

export const ApplicationType = () => {
  const router = useNavigate();
  const setValue = (val) => {
    clearExistingApplicationInLocalStorate();
    if (val === APPLICATION_TYPE_FINANCE) {
      saveAppFieldInLocalStorage(
        LOAN_APP_TYPE.HomeLoan,
        'type',
        val,
        router,
        '/home-loans/finance/purchase-price'
      );
    } else {
      saveAppFieldInLocalStorage(
        LOAN_APP_TYPE.HomeLoan,
        'type',
        val,
        router,
        '/home-loans/refinance/current-loan-amount'
      );
    }
  };

  return (
    <>
      <p className="text-center mt-0 md:mt-8 text-2xl px-2">What are you looking for?</p>
      <div className="mt-6 px-3 w-96 mx-auto">
        <p className="py-4">
          <button
            onClick={() => setValue(APPLICATION_TYPE_FINANCE)}
            className="common-radio-rec-ghost-btn">
            {APPLICATION_TYPE_FINANCE}
          </button>
        </p>
        <p className="py-4">
          <button
            onClick={() => setValue(APPLICATION_TYPE_REFINANCE)}
            className="common-radio-rec-ghost-btn">
            {APPLICATION_TYPE_REFINANCE}
          </button>
        </p>
      </div>
    </>
  );
};
