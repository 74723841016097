import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { API_HOST } from '../../../utils/constants';
import { fetcher } from '../../../utils';

export default function Regualtions() {
  const [disclaimer, setDisclaimer] = useState();
  const { data: responseData } = useSWR(`${API_HOST}/blocks/disclaimer`, fetcher);

  useEffect(() => {
    if (responseData) {
      setDisclaimer(responseData);
    }
  }, [responseData]);

  return (
    <div className="py-10 px-2 mx-auto bg-gray-900 text-gray-400 md:leading-relaxed">
      <div className="container mx-auto">
        <div dangerouslySetInnerHTML={{ __html: disclaimer?.content }} />
      </div>
    </div>
  );
}
