import { useEffect } from 'react';
import { useProgressBarContext } from '../../contexts/ProgressBarContext';

export default function SubmissionSuccessfulPage() {
  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(16);
  }, []);

  return (
    <>
      <img src="/green-check-fill.svg" alt="green tick" className="mx-auto" />
      <h3 className="font-semibold text-center font-secondary mt-10">
        Your application has been submitted successfully
      </h3>
      <p className="mt-4 text-center">Our car loan specialist will get in touch with you shortly</p>
    </>
  );
}
