/* eslint-disable react/prop-types */
import clsx from 'clsx';
import { forwardRef, useRef } from 'react';

const BaseInput = forwardRef(function BaseInput(
  { label, name, className: passedClassName, type = 'text', error, icon, ...rest },
  ref
) {
  const inputRef = useRef(null);
  if (!ref) {
    ref = inputRef;
  }
  return (
    <div>
      <label htmlFor={name} className="text-sm">
        {label}
      </label>
      <div className="mt-2 relative">
        <input
          ref={ref}
          type={type}
          id={name}
          name={name}
          className={clsx(
            'block outline outline-1 py-2.5 px-3 rounded-md w-full',
            {
              'outline-black/25 focus:outline-primary-blue': !error,
              'outline-red-600': error,
              'pl-8': icon
            },
            passedClassName
          )}
          {...rest}
        />
        {icon && (
          <div
            className="absolute left-3 top-0 bottom-0 my-auto h-fit w-fit cursor-default"
            onClick={() => ref.current.focus()}>
            {icon}
          </div>
        )}
      </div>
      {error && <p className="text-xs text-red-600 mt-2">{error}</p>}
    </div>
  );
});

export default BaseInput;
