const procs = [
  {
    id: 1,
    title: 'APPLY FOR LOAN',
    desc: 'Looking to buy a car or home loan? then apply for loan now.',
    link: 'https://tidycal.com/loanspad/15-minute-meeting',
    linkTxt: 'Get Appointment',
    icon: '/notepad-icon.svg'
  },
  {
    id: 2,
    title: 'CALL US AT',
    link: 'tel:1300 323 181',
    linkTxt: 'Contact us',
    desc: (
      <>
        <span className="text-blue-700 text-2xl">1300 323 181</span> <br />{' '}
        <a href="mailto:hello@loanspad.com.au">hello@loanspad.com.au</a>
      </>
    ),
    icon: '/phone-icon.svg'
  },
  {
    id: 3,
    title: 'TALK TO ADVISOR',
    desc: 'Need to loan advise? Talk to our Loan advisors.',
    link: '#',
    linkTxt: 'Meet The Advisor',
    icon: '/customer-service-icon.svg'
  }
];

export default function Help() {
  return (
    <div className="py-32 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
            We are here to help you
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Our mission is to deliver reliable, latest news and opinions.
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify md:justify-center gap-7">
          {procs.map((procedure) => (
            <div
              className="p-4 lg:p-12 bg-white shadow-xl mt-10 md:w-1/3 rounded-xl"
              key={procedure.id}>
              <div className="text-center opacity-90">
                <span className="icon place-content-center">
                  <img src={procedure.icon} alt="" className="w-12 mx-auto" />
                </span>
                <h3 className="text-md font-medium mt-12">{procedure.title}</h3>
                <p className="text-md my-4 text-gray-500">{procedure.desc}</p>
                <a className="text-blue-800 border-blue-800 border-b-2" href={procedure.link}>
                  {procedure.linkTxt}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
