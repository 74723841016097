import { Outlet } from 'react-router-dom';
import { ProgressBarContext } from '../../../contexts/ProgressBarContext';
import { useState } from 'react';
import { brandWebsiteUrl } from '../../../utils';

const TOTAL_CAR_LOAN_FUNNEL_STEPS = 16;

export default function CarFunnelLayout() {
  const [currentStep, setCurrentStep] = useState(1);

  const progressBarStyle = {
    width: Math.floor((currentStep / TOTAL_CAR_LOAN_FUNNEL_STEPS) * 100) + '%'
  };
  return (
    <div className="grid grid-rows-[auto_auto_1fr] h-screen">
      {/* if relative is not added, shadow is hidden under other elements */}
      <header className="px-20 py-4 border-b border-black/10 relative shadow-[0px_1px_4px_0px_rgba(0,0,0,0.25)]">
        <div className="container mx-auto flex justify-between items-center">
          <a href={brandWebsiteUrl}>
            <img src="/logo.svg" alt="Logo image" className="w-[140px]" />
          </a>
          <a
            className="flex items-center gap-2.5"
            href="tel:0433555302"
            target="_blank"
            rel="noreferrer">
            <img src="/phone-icon-blue.svg" alt="" />
            <p>0433 555 302</p>
          </a>
        </div>
      </header>
      <div className="bg-bluey-gray w-full h-4 border-b border-black/10">
        <div
          className="bg-primary-blue h-full rounded-tr-lg rounded-br-lg"
          style={progressBarStyle}></div>
      </div>
      <main className="bg-soft-gray pt-14">
        <ProgressBarContext.Provider value={{ currentStep, setCurrentStep }}>
          <Outlet />
        </ProgressBarContext.Provider>
      </main>
    </div>
  );
}
