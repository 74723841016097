function HeroPoints() {
  return (
    <div className="md:container mx-auto py-10 px-6">
      <div className="md:grid grid-cols-3 gap-4">
        <div className="flex">
          <svg
            fill="#0075E3"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            className="mt-1"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24">
            <rect width="24" height="24" rx="12" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.462 6.967c.35.318.386.87.08 1.234l-7.384 8.75a.823.823 0 0 1-1.187.085l-4.43-3.977a.898.898 0 0 1-.085-1.234.823.823 0 0 1 1.19-.089l3.795 3.408 6.83-8.095a.823.823 0 0 1 1.19-.082Z"
              fill="#fff"
            />
          </svg>
          <span className="text-lg md:text-xl float-left ml-2">No Hidden fees</span>
        </div>
        <div className="flex">
          <svg
            fill="#0075E3"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            className="mt-1"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24">
            <rect width="24" height="24" rx="12" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.462 6.967c.35.318.386.87.08 1.234l-7.384 8.75a.823.823 0 0 1-1.187.085l-4.43-3.977a.898.898 0 0 1-.085-1.234.823.823 0 0 1 1.19-.089l3.795 3.408 6.83-8.095a.823.823 0 0 1 1.19-.082Z"
              fill="#fff"
            />
          </svg>
          <span className="text-lg md:text-xl float-left ml-2">Fast Turnarround</span>
        </div>
        <div className="flex">
          <svg
            fill="#0075E3"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            className="mt-1"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24">
            <rect width="24" height="24" rx="12" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.462 6.967c.35.318.386.87.08 1.234l-7.384 8.75a.823.823 0 0 1-1.187.085l-4.43-3.977a.898.898 0 0 1-.085-1.234.823.823 0 0 1 1.19-.089l3.795 3.408 6.83-8.095a.823.823 0 0 1 1.19-.082Z"
              fill="#fff"
            />
          </svg>
          <span className="text-lg md:text-xl float-left ml-2">Get matches within 5 minutes</span>
        </div>
      </div>
    </div>
  );
}

export default HeroPoints;
