import Footer from '../components/common/home/Footer';
import Regualtions from '../components/common/home/Regualtions';
import { BlogPost } from '../components/BlogPost';
import Nav from '../components/common/home/Nav';

function BlogPostPage() {
  return (
    <div>
      <Nav />
      <BlogPost />
      <Footer />
      <Regualtions />
    </div>
  );
}

export default BlogPostPage;
