import { useNavigate } from 'react-router-dom';
import ContinueButton from '../../components/car-loans/buttons/ContinueButton';
import ApplicationAmountInput from '../../components/car-loans/form-components/ApplicationAmountInput';
import { useForm } from 'react-hook-form';
import { useProgressBarContext } from '../../contexts/ProgressBarContext';
import { useEffect } from 'react';
import { saveApplicantFieldInLocalStorage } from '../../utils';
import { LOAN_APP_TYPE } from '../../utils/constants';

export default function MonthlyIncomePage() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();

  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(11);
  }, []);

  const onFormSubmit = (data) => {
    saveApplicantFieldInLocalStorage(
      LOAN_APP_TYPE.CarLoan,
      'personalNetMonthlyIncomeGuesstimate',
      data.monthlyGrossIncome
    );
    navigate('../income-history');
  };
  return (
    <>
      <h3 className="application-question">What is your monthly gross income?</h3>
      <form className="application-form-container" onSubmit={handleSubmit(onFormSubmit)}>
        <ApplicationAmountInput
          {...register('monthlyGrossIncome', {
            required: 'Monthly gross income is required',
            validate: (val) => (isNaN(val) ? 'Invalid input' : true),
            valueAsNumber: true
          })}
          error={errors?.monthlyGrossIncome?.message}
        />
        <ContinueButton />
      </form>
    </>
  );
}
