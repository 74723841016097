import { StarIcon } from '@heroicons/react/solid';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

const slideData = [
  {
    id: 1,
    day: 'June10, 2022',
    desc: 'Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.',
    auth: 'Dora'
  },
  {
    id: 2,
    day: 'June10, 2022',
    desc: 'Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.',
    auth: 'Dora'
  },
  {
    id: 3,
    day: 'June10, 2022',
    desc: 'Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.',
    auth: 'Dora'
  },
  {
    id: 4,
    day: 'June10, 2022',
    desc: 'Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.',
    auth: 'Dora'
  },
  {
    id: 5,
    day: 'June10, 2022',
    desc: 'Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.',
    auth: 'Dora'
  },
  {
    id: 6,
    day: 'June10, 2022',
    desc: 'Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.',
    auth: 'Dora'
  }
];

const Testimonials = () => {
  return (
    <>
      <div className="testimonials-home bg-gray-100">
        <div className="py-10 xl:py-24 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex">
            <div className="md:w-6/12">
              <p className="uppercase text-md font-bold text-blue-700 mb-2">Customer Stories</p>
              <h2 className="xl:text-5xl text-3xl font-bold mb-16">
                Hundreds of Happy Customers more cash in their wallets
              </h2>
            </div>
            <div></div>
          </div>
          <Swiper
            loop={true}
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={30}
            navigation={true}
            pagination={{
              clickable: true,
              type: 'bullets',
              bulletElement: 'span',
              bulletClass: 'timeline-icon',
              bulletActiveClass: 'timeline-icon-active',
              renderBullet: function (index, className) {
                return '<span className="' + className + '">' + (index + 1) + ' . ' + '</span>';
              }
            }}
            scrollbar={{ draggable: true }}
            className="mySwiper"
            autoplay={{
              delay: 6000,
              disableOnInteraction: false
            }}
            breakpoints={{
              414: {
                slidesPerView: 1
              },
              768: {
                slidesPerView: 3
              }
            }}>
            {slideData.map((rating, i) => (
              <SwiperSlide key={i}>
                <div className="bg-white p-8 rounded-2xl shadow-xl relative pb-8" key={rating.id}>
                  <div className="flex">
                    <div className="w-9/12 flex flex-row text-orange-400 py-2">
                      <StarIcon className=" h-5 w-5"></StarIcon>
                      <StarIcon className=" h-5 w-5"></StarIcon>
                      <StarIcon className=" h-5 w-5"></StarIcon>
                      <StarIcon className=" h-5 w-5"></StarIcon>
                      <StarIcon className=" h-5 w-5"></StarIcon>
                    </div>
                  </div>
                  <div className="mt-3 text-md mb-8">{rating.desc}</div>
                  <a href="!#" className="text-md bottom-3 text-gray-800">
                    <span className="font-bold">{rating.auth}</span> <br />
                  </a>
                </div>
              </SwiperSlide>
            ))}
            <div className="swiper-pagination"></div>
          </Swiper>
        </div>
      </div>
    </>
  );
};
export default Testimonials;
