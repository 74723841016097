import { useForm } from 'react-hook-form';
import { Outlet, useNavigate, useMatch, useResolvedPath } from 'react-router-dom';
import ActionButton from '../../components/car-loans/buttons/ActionButton';
import BaseInput from '../../components/car-loans/form-components/BaseInput';
import { useProgressBarContext } from '../../contexts/ProgressBarContext';
import { useEffect } from 'react';
import { getFromLocalStorage, saveApplicantFieldInLocalStorage } from '../../utils';
import { API_HOST, LOAN_APP_TYPE } from '../../utils/constants';
import axios from 'axios';

export default function MobileVerificationPage() {
  const navigate = useNavigate();
  const isOtpEntryPage = Boolean(useMatch(useResolvedPath('otp-verification').pathname));
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(14);
  }, [isOtpEntryPage]);

  const onFormSubmit = async (data) => {
    const application = getFromLocalStorage(LOAN_APP_TYPE.CarLoan);
    application.applicants[0].mobile = data.mobile;

    const lead = {
      application,
      source: 'website',
      leadCreationSource: 'Funnel'
    };

    try {
      await axios.post(`${API_HOST}/leads`, lead);
      await axios.post(`${API_HOST}/auth/auth-number`, {
        mobile: `${data.mobile}`
      });
      saveApplicantFieldInLocalStorage(LOAN_APP_TYPE.CarLoan, 'mobile', data.mobile);
      navigate('otp-verification');
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div className="mb-10">
        <h3 className="application-question mb-2">
          {isOtpEntryPage ? 'Verify your mobile number' : 'Mobile verification'}
        </h3>
        <p className="text-subtitle-gray text-sm text-center">
          We {isOtpEntryPage ? 'sent you ' : 'will send you '}an OTP on this mobile number
        </p>
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)} className="application-form-container gap-6">
        <div>
          <BaseInput
            type="number"
            className="py-3"
            placeholder="04XXXXXXXX"
            {...register('mobile', {
              required: 'Please enter your mobile number',
              pattern: {
                value: /04\d{8}$/,
                message: 'Invalid phone number'
              }
            })}
            error={errors?.mobile?.message}
          />
          {isOtpEntryPage && <p className="text-link text-end text-xs mt-1">Edit mobile number</p>}
        </div>
        <ActionButton className="justify-self-center">Send OTP</ActionButton>
      </form>
      <Outlet />
    </>
  );
}
