import { useNavigate, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { saveAppFieldInLocalStorage } from '../utils';
import { ProgressContext } from '../App';
import { LOAN_APP_TYPE } from '../utils/constants';

export const ApplicantsProperties = () => {
  const router = useNavigate();
  const location = useLocation();
  const [, , mode] = location.pathname.split('/');
  const [numApplicants, setNumApplicants] = useState(1);
  const [numProperties, setNumProperties] = useState(1);

  const { setProgressPercentage } = useContext(ProgressContext);

  useEffect(() => {
    setProgressPercentage(90);
  }, []);

  const APPLICANTS = 'applicants';
  const PROPERTIES = 'properties';

  const setValue = (num, type) => {
    if (type === APPLICANTS) {
      setNumApplicants(num);
    } else {
      setNumProperties(num);
    }
  };
  const classes = [
    'text-blue-800',
    'bg-white',
    'border',
    'border-gray-400',
    'py-5',
    'px-5',
    'w-24',
    'rounded-md',
    'hover:shadow-xl'
  ];

  const onSubmit = () => {
    saveAppFieldInLocalStorage(LOAN_APP_TYPE.HomeLoan, 'numApplicants', numApplicants, null);
    saveAppFieldInLocalStorage(
      LOAN_APP_TYPE.HomeLoan,
      'numProperties',
      numProperties,
      router,
      `/home-loans/${mode}/applicant-name`
    );
  };

  return (
    <>
      <p className="text-center mt-0 md:mt-8 text-2xl px-2">
        How many applicants will there be for this loan?
      </p>
      <div className="mt-6 px-3 w-96 mx-auto">
        <div className="py-6 flex flex-row justify-between">
          <button
            onClick={() => setValue(1, APPLICANTS)}
            className={
              numApplicants === 1 ? [...classes, 'selected'].join(' ') : classes.join(' ')
            }>
            1
          </button>
          <button
            onClick={() => setValue(2, APPLICANTS)}
            className={
              numApplicants === 2 ? [...classes, 'selected'].join(' ') : classes.join(' ')
            }>
            2
          </button>
          <button
            onClick={() => setValue('3+', APPLICANTS)}
            className={
              numApplicants === '3+' ? [...classes, 'selected'].join(' ') : classes.join(' ')
            }>
            3+
          </button>
        </div>
      </div>
      <p className="text-center mt-8 text-2xl px-2">
        How many properties will be included as a part of this loan?
      </p>
      <div className="mt-6 px-3 w-96 mx-auto">
        <div className="py-6 flex flex-row justify-between">
          <button
            onClick={() => setValue(1, PROPERTIES)}
            className={
              numProperties === 1 ? [...classes, 'selected'].join(' ') : classes.join(' ')
            }>
            1
          </button>
          <button
            onClick={() => setValue(2, PROPERTIES)}
            className={
              numProperties === 2 ? [...classes, 'selected'].join(' ') : classes.join(' ')
            }>
            2
          </button>
          <button
            onClick={() => setValue('3+', PROPERTIES)}
            className={
              numProperties === '3+' ? [...classes, 'selected'].join(' ') : classes.join(' ')
            }>
            3+
          </button>
        </div>

        <p className="py-4">
          <button onClick={() => onSubmit()} className="common-button">
            NEXT
          </button>
        </p>
      </div>
    </>
  );
};
