import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { fetcher, headers, extractNumeric } from '../../utils';
import { API_HOST, APPLICANT_PRIMARY } from '../../utils/constants';
import axios from 'axios';

import CurrencyInput from '../common/CurrencyInput';

const schema = Yup.object().shape({
  type: Yup.string().required('Required'),
  netIncome: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  frequency: Yup.string().required('Required')
});

export const AdditionalIncome = () => {
  const router = useNavigate();
  const { id: applicationId } = router.query;

  const [, setApplication] = useState([]);
  const [primaryApplicant, setPrimaryApplicant] = useState();
  const [isAdded, setIsAdded] = useState(true);

  const url = `${API_HOST}/applications`;
  const { data: responseData } = useSWR(applicationId ? `${url}/${applicationId}` : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setApplication(responseData);
      setPrimaryApplicant(responseData.applicants?.find((a) => a.type === APPLICANT_PRIMARY));
    }
  }, [responseData]);

  const onSubmit = async (values, { resetForm }) => {
    const vals = { ...values, netIncome: extractNumeric(values.netIncome) };
    try {
      await axios.put(`${url}/${applicationId}/applicants/${primaryApplicant._id}/incomes`, vals, {
        headers
      });
      setIsAdded(true);
      resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  const redirectToNext = () =>
    router(`/applications/${applicationId}/applicants/${primaryApplicant._id}/expenses`);

  return (
    <>
      <p className="text-center mt-8 text-2xl">Do you have any additional source of Income?</p>
      <div className="mx-auto px-4 md:w-3/12">
        <div className="mt-10 mx-auto">
          {!isAdded ? (
            <Formik
              enableReinitialize
              initialValues={{
                type: '',
                netIncome: '',
                frequency: ''
              }}
              onSubmit={onSubmit}
              validationSchema={schema}>
              {({ errors, touched }) => (
                <Form>
                  <div className="mt-4">
                    <label>Type</label>
                    <Field
                      as="select"
                      name="type"
                      className="form-input px-4 py-3 rounded-md w-full">
                      <option></option>
                      <option value="Family Tax Benefit">Family Tax Benefit</option>
                      <option value="Rental">Rental</option>
                      <option value="Investment Income">Investment Income</option>
                      <option value="Salary Sacrifice">Salary Sacrifice</option>
                      <option value="Centrelink">Centrelink</option>
                      <option value="Foreign">Foreign</option>
                      <option value="Child Maintenance">Child Maintenance</option>
                      <option value="Other">Other</option>
                    </Field>
                    {errors.type && touched.type ? (
                      <div className="error">{errors.type}</div>
                    ) : null}
                  </div>

                  <div className="flex space-x-2 mt-4">
                    <div>
                      <label className="block">Amount</label>
                      <Field name="netIncome" className="form-input px-4 py-3 rounded-md ">
                        {({ field }) => (
                          <CurrencyInput
                            placeholder="$0"
                            {...field}
                            className="form-input px-4 py-3 rounded-md"
                          />
                        )}
                      </Field>
                      {errors.netIncome && touched.netIncome ? (
                        <div className="error">{errors.netIncome}</div>
                      ) : null}
                    </div>
                    <div>
                      <label htmlFor="" className="block">
                        Frequency
                      </label>
                      <Field
                        name="frequency"
                        as="select"
                        className="form-input px-4 py-3 rounded-md w-full">
                        <option></option>
                        <option value="Weekly">Weekly</option>
                        <option value="Fortnightly">Fortnightly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Yearly">Yearly</option>
                      </Field>
                      {errors.frequency && touched.frequency ? (
                        <div className="error">{errors.frequency}</div>
                      ) : null}
                    </div>
                  </div>
                  <p className="py-8 mt-1">
                    <button type="submit" className="common-button">
                      Next
                    </button>
                  </p>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="mt-10">
              <button onClick={() => setIsAdded(false)} className="common-radio-rec-ghost-btn">
                Add Additional Income
              </button>
              <button onClick={() => redirectToNext()} className="common-button mt-6">
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
