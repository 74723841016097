export const About = () => {
  return (
    <>
      <div className="py-24 container mx-auto">
        <div className="grid items-center grid-cols-1 md:grid-cols-2">
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <img className="h-full w-full" src="/about.png" alt="About Image" />
          </div>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <h3 className="mt-4 text-md text uppercase font-semibold text-green-700">
              About Our Company
            </h3>
            <h2 className="mt-2 text-3xl leading-8 text-gray-900 font-bold md:text-6xl">
              We Are Fully Dedicated To Support You
            </h2>
            <p className="mt-5 text-gray-700">
              At Loanspad, we believe that everyone should have access to the best possible mortgage
              rates. That&apos;s why we&apos;ve created an online platform that makes it easy to
              compare rates from multiple lenders, all in one place.
              <br />
              We&apos;re committed to providing our customers with the best possible experience.
              That means offering transparent pricing, clear terms, and excellent customer service.
            </p>
            <h4 className="mt-4 text-md text text-green-700">Our Mission</h4>
            <p className="mt-2 text-gray-500 font-light text-base">
              Our plan dolor sit amet conseetur diisci velit sed quiLoresum dolor sit ame
              consectetur adipisicing elit.
            </p>
            <ul className="leading-8 mt-2">
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3 text-green-700 inline-block"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  />
                </svg>{' '}
                Respect for all people
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3 text-green-700 inline-block"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  />
                </svg>{' '}
                Excellence in everything we do
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3 text-green-700 inline-block"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  />
                </svg>{' '}
                Truthfulness in our business
              </li>
            </ul>

            <h4 className="mt-4 text-md text text-green-700">Our Vision</h4>
            <p className="mt-2 text-gray-500 font-light">
              Our vision dolor sit amet conseetur diisci velit sed quiLoresum dolor sit ame
              consectetur adipisicing elit.
            </p>
            <ul className="leading-8 mt-2">
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3 text-green-700 inline-block"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  />
                </svg>{' '}
                Respect for all people
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3 text-green-700 inline-block"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  />
                </svg>{' '}
                Excellence in everything we do
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3 text-green-700 inline-block"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  />
                </svg>{' '}
                Truthfulness in our business
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container mx-auto">
        <div className="call-to-action py-24 m-auto">
          <div className="px-10 md:h-1/2 text-center">
            <h3 className="text-2xl text-gray-900 font-bold md:text-4xl leading-relaxed">
              Take The First Step Toward The <br />
              Right <span className="text-green-700">Mortgage</span>
            </h3>
            <div className="flex flex-col md:flex-row mt-4 md:mt-8 md:w-1/2 md:space-x-8 mx-auto justify-center space-y-4 md:space-y-0">
              <a
                href="!#"
                className="rounded-md bg-green-700 hover:bg-green-600 text-white inline-block py-4 px-8">
                Start a Refinance Journey
              </a>
              <a
                href="!#"
                className="rounded-md bg-blue-700 hover:bg-blue-600 text-white inline-block py-4 px-8">
                Start a Finance Journey
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="py-24 container mx-auto">
        <div className="grid items-center grid-cols-1 md:grid-cols-2">
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <h3 className="mt-4 text-md text uppercase font-semibold text-green-700">
              About Our Company
            </h3>
            <h2 className="mt-2 text-3xl leading-8 text-gray-900 font-bold md:text-6xl">
              We Are Fully Dedicated To Support You
            </h2>
            <p className="mt-5 text-gray-700">
              Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id
              enim. Vivamus magna justo lacinia eget consectetur.
            </p>
            <h4 className="mt-4 text-md text text-green-700">Our Mission</h4>
            <p className="mt-2 text-gray-500 font-light text-base">
              Our plan dolor sit amet conseetur diisci velit sed quiLoresum dolor sit ame
              consectetur adipisicing elit.
            </p>
            <ul className="leading-8 mt-2">
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3 text-green-700 inline-block"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  />
                </svg>{' '}
                Respect for all people
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3 text-green-700 inline-block"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  />
                </svg>{' '}
                Excellence in everything we do
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3 text-green-700 inline-block"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  />
                </svg>{' '}
                Truthfulness in our business
              </li>
            </ul>
          </div>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <img className="h-full w-full" src="/beyond.png" alt="Beyond" />
          </div>
        </div>
      </div>
    </>
  );
};
