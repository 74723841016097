import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProgressContext } from '../App';
import { saveAppFieldInLocalStorage } from '../utils';
import { LOAN_APP_TYPE } from '../utils/constants';

export const BuyingSituation = () => {
  const router = useNavigate();

  const { setProgressPercentage } = useContext(ProgressContext);

  useEffect(() => {
    setProgressPercentage(30);
  }, []);

  const setValue = (val) => {
    saveAppFieldInLocalStorage(
      LOAN_APP_TYPE.HomeLoan,
      'buyingSituation',
      val,
      router,
      '/home-loans/finance/first-home'
    );
  };

  return (
    <>
      <p className="text-center mt-8 text-2xl px-2">
        What best describes your home buying situation?
      </p>

      <div className="mt-6 px-3 w-96 mx-auto">
        <p className="py-4">
          <button onClick={() => setValue('Just Exploring')} className="common-radio-rec-ghost-btn">
            Just Exploring Options
          </button>
        </p>
        <p className="py-4">
          <button
            onClick={() => setValue('Buying in next 6 months')}
            className="common-radio-rec-ghost-btn">
            Plan to buy in next 6 months
          </button>
        </p>
        <p className="py-4">
          <button
            onClick={() => setValue('Actively Looking')}
            className="common-radio-rec-ghost-btn">
            Actively looking/Made an offer
          </button>
        </p>
        <p className="py-4">
          <button onClick={() => setValue('Exchanged contracts')} className="common-button">
            Exchanged contracts
          </button>
        </p>
      </div>
    </>
  );
};
