import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { saveAppFieldInLocalStorage } from '../utils';
import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { ProgressContext } from '../App';
import { LOAN_APP_TYPE } from '../utils/constants';

const schema = Yup.object().shape({
  currentInterestRate: Yup.number()
    .min('0.01', 'Too less!')
    .max('10', 'Too high!')
    .required('Required')
});

export const CurrentInterestRate = () => {
  const router = useNavigate();
  const [selectedSchema, setSelectedSchema] = useState(schema);

  const { setProgressPercentage } = useContext(ProgressContext);

  useEffect(() => {
    setProgressPercentage(20);
  }, []);

  const onSkip = () => {
    setSelectedSchema(null);
    router('/refinance/current-lender');
  };

  function onSubmit(values) {
    saveAppFieldInLocalStorage(
      LOAN_APP_TYPE.HomeLoan,
      'currentInterestRate',
      +values.currentInterestRate,
      router,
      '/home-loans/refinance/current-lender'
    );
  }

  return (
    <>
      <p className="text-center mt-8 text-2xl px-2">
        What is your Current estimated interest rate?
      </p>
      <div className="mt-6 px-3 w-96 mx-auto">
        <Formik
          enableReinitialize
          validationSchema={selectedSchema}
          initialValues={{ currentInterestRate: '' }}
          onSubmit={onSubmit}>
          {({ errors, touched }) => (
            <Form>
              <Field name="currentInterestRate" className="form-input px-4 py-3 rounded-md ">
                {({ field }) => (
                  <input
                    placeholder="0"
                    {...field}
                    className="form-input px-4 py-3 rounded-md w-full"
                  />
                )}
              </Field>
              {errors.currentInterestRate && touched.currentInterestRate ? (
                <div className="error">{errors.currentInterestRate}</div>
              ) : null}
              <p className="py-6">
                <button
                  className="text-center w-full text-blue-500 pb-6 font-semibold"
                  onClick={() => onSkip()}>
                  {"I don't know, skip it"}
                </button>
                <button type="submit" className="common-button">
                  Next
                </button>
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
