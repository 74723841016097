import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { API_HOST } from '../utils/constants';
import { fetcher } from '../utils';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

export const BlogPost = () => {
  const { slug } = useParams();
  const [blogPost, setBlogPost] = useState([]);
  const [relatedBlogPosts, setRelatedBlogPosts] = useState([]);
  const url = `${API_HOST}/blog-posts/${slug}`;

  const { data: responseData } = useSWR(url, fetcher);

  async function fetchBlogPosts(blogId) {
    const { data: blogPostsResponse } = await axios.get(
      `${API_HOST}/blog-posts?blog=${blogId}&exclude=${responseData._id}&limit=5&isActive=true`
    );
    setRelatedBlogPosts(blogPostsResponse.data);
  }

  useEffect(() => {
    if (responseData) {
      setBlogPost(responseData);

      fetchBlogPosts(responseData.blog);
    }
  }, [responseData]);

  return (
    <>
      <Helmet>
        <title>{blogPost?.metaTitle ? `${blogPost?.metaTitle} - Loanspad` : 'Loanspad'} </title>
        <meta name="description" content={blogPost?.metaDescription} />
        <meta name="keywords" content={blogPost?.metaKeywords?.join(',')} />
        <meta property="og:title" content={blogPost?.metaTitle} />
        <meta property="og:description" content={blogPost?.metaDescription} />
        <meta property="og:image" content={blogPost?.imgUrl} />
      </Helmet>

      <div className="bg-gray-100 py-6 md:py-16">
        <div className="w-11/12 md:w-9/12 lg:px-14 mx-auto">
          <div className="lg:mx-2 mx-5">
            <h2 className="text-2xl md:text-4xl leading-8 text-gray-800 font-bold tracking-tight">
              {blogPost?.title}
            </h2>
            <div className="mb-6 mt-6 text-xs md:text-sm  font-light text-gray-500 flex blog-d-main align-middle">
              <div className="blog-d">
                <span className="text-gray-500">By</span>{' '}
                <span className="font-medium">{blogPost?.authorInfo?.name}</span>
              </div>
              <div className="blog-d">
                {' '}
                Published on {dayjs(blogPost?.publishedAt).format('DD/MM/YYYY')}
              </div>
              <div className="blog-d flex gap-2">
                {blogPost?.tags?.map((tag) => (
                  <span key={tag} className="bg-blue-100 p-1 px-4 text-blue-800 rounded-md text-xs">
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-wrap md:flex-nowrap gap-14 mx-5 lg:mx-0">
            <div className="flex flex-col w-full md:w-2/3 lg:w-3/4">
              <div className="grid grid-cols-1 bg-white rounded-lg">
                <div className="place-content-center">
                  <img
                    src={blogPost?.imgUrl}
                    className="blog-img w-full rounded-tl-lg rounded-tr-lg"
                  />
                </div>
                <div className="p-4 md:p-10">
                  <div className="text-md lg:text-lg lg:leading-8 text-gray-700 font-light">
                    <div dangerouslySetInnerHTML={{ __html: blogPost?.content }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full md:w-1/3 lg:w-1/4">
              <div className="bg-white p-6 rounded-lg">
                <div className="profile-img text-center">
                  <a href="!#">
                    <div className="profile-img-bg">
                      <img
                        src={blogPost?.authorInfo?.imgUrl}
                        className="rounded-full w-full"
                        alt=""
                      />
                    </div>
                  </a>
                  <h3 className="mt-8 mb-3 text-gray-600 text-xl font-medium">
                    {blogPost?.authorInfo?.name}
                  </h3>
                </div>
              </div>
              <div className="related-post mt-16">
                <h3 className="text-center text-gray-500 text-2xl font-medium mb-10">
                  Related Posts
                </h3>
                <div className="space-y-6">
                  {relatedBlogPosts.length ? (
                    relatedBlogPosts?.map((p) => {
                      return (
                        <div
                          key={p?._id}
                          className="bg-white p-6 rounded-lg border-l-4 border-blue-500">
                          <h4 className="font-medium">
                            <Link to={`/blog-posts/${p.slug}`}>{p.title}</Link>
                          </h4>
                          <div className="flex mt-4">
                            <div className="xl:w-2/5 text-xs text-gray-500">
                              <div>{p.authorInfo?.name}</div>
                            </div>
                            <div className="xl:w-3/5 text-xs text-gray-500 text-right">
                              {dayjs(p.publishedAt).format('ddd DD/MM/YYYY')}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <h2 className="text-center">No related posts</h2>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
