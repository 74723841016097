export function validateEmail(value) {
  let error;
  if (!value) {
    error = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
}

export function validateNotEmpty(value) {
  let error;
  if (!value) {
    error = 'Required';
  }
  return error;
}

export const NAME_REGEX = /^[A-Za-z]+$/;
export const ALPHANUMERIC_REGEX = /[A-Za-z.0-9]/g;
