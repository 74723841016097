import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProgressContext } from '../App';
import { saveApplicantFieldInLocalStorage } from '../utils';
import { LOAN_APP_TYPE, MODES } from '../utils/constants';

export const EmploymentType = () => {
  const router = useNavigate();
  const location = useLocation();
  const [, , mode] = location.pathname.split('/');

  const { setProgressPercentage } = useContext(ProgressContext);

  useEffect(() => {
    setProgressPercentage(mode === MODES.finance ? 80 : 70);
  }, []);

  const setValue = (val) => {
    saveApplicantFieldInLocalStorage(
      LOAN_APP_TYPE.HomeLoan,
      'employmentStatus',
      val,
      router,
      `/home-loans/${mode}/applicants-properties`
    );
  };

  return (
    <>
      <p className="text-center mt-0 md:mt-8 text-2xl px-2">What is your employment type?</p>

      <div className="mt-6 px-3 w-96 mx-auto">
        <p className="py-4">
          <button onClick={() => setValue('Employee')} className="common-radio-rec-ghost-btn">
            Employee
          </button>
        </p>
        <p className="py-4">
          <button onClick={() => setValue('Self-Employed')} className="common-radio-rec-ghost-btn">
            Self-employed
          </button>
        </p>
        <p className="py-4">
          <button onClick={() => setValue('Other')} className="common-radio-rec-ghost-btn">
            Other
          </button>
        </p>
      </div>
    </>
  );
};
