import { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { NAME_REGEX } from '../../utils/validators';
import useSWR from 'swr';
import { fetcher, headers } from '../../utils';
import { API_HOST } from '../../utils/constants';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const schema = Yup.object().shape({
  title: Yup.string().required('Required'),
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
    .matches(NAME_REGEX, 'Only alphabets allowed'),
  middleName: Yup.string().max(50, 'Too Long!').matches(NAME_REGEX, 'Only alphabets allowed'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
    .matches(NAME_REGEX, 'Only alphabets allowed'),
  day: Yup.number().required('Required'),
  month: Yup.number().required('Required'),
  year: Yup.number().required('Required')
});

export const ApplicantInformation = () => {
  const router = useNavigate();
  const { id: applicationId } = router.query;

  const [, setApplication] = useState([]);
  const [primaryApplicant, setPrimaryApplicant] = useState();

  const url = `${API_HOST}/applications`;
  const { data: responseData } = useSWR(applicationId ? `${url}/${applicationId}` : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setApplication(responseData);
      setPrimaryApplicant(responseData.applicants.find((a) => a.type === 'Primary'));
    }
  }, [responseData]);

  async function onSubmit(values) {
    const dateString = `${values.day}/${values.month}/${values.year}`;
    let dateOfBirth = dayjs(dateString, 'DD/MM/yyyy');

    if (dateOfBirth) {
      dateOfBirth = dateOfBirth.toISOString();
    }

    const vals = { ...values, dateOfBirth };
    delete vals.day;
    delete vals.month;
    delete vals.year;

    try {
      await axios.put(`${url}/${applicationId}/applicants/${primaryApplicant._id}`, vals, {
        headers
      });
      router(`/applications/${applicationId}/applicants/${primaryApplicant._id}/residency-status`);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="w-96 md:w-3/12 px-4 mx-auto">
      <p className="text-center mt-8 text-2xl">Please share more information about yourself</p>

      <div className="mt-6">
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={schema}
          initialValues={{
            title: primaryApplicant?.userInfo?.title || '',
            firstName: primaryApplicant?.userInfo?.firstName || '',
            middleName: primaryApplicant?.userInfo?.middleName || '',
            lastName: primaryApplicant?.userInfo?.lastName || '',
            day: primaryApplicant?.dateOfBirth
              ? dayjs(primaryApplicant?.dateOfBirth).format('DD')
              : '',
            month: primaryApplicant?.dateOfBirth
              ? dayjs(primaryApplicant?.dateOfBirth).format('MM')
              : '',
            year: primaryApplicant?.dateOfBirth
              ? dayjs(primaryApplicant?.dateOfBirth).format('YYYY')
              : ''
          }}>
          {({ errors, touched }) => (
            <Form>
              <div>
                <label htmlFor="title" className="block">
                  Title
                </label>
                <Field name="title" as="select" className="form-input px-4 py-3 rounded-md w-full">
                  <option></option>
                  <option>Mr.</option>
                  <option>Mrs.</option>
                  <option>Ms.</option>
                </Field>
                {errors.title && touched.title ? <div className="error">{errors.title}</div> : null}
              </div>
              <div className="mt-5">
                <label>First Name</label>
                <Field
                  name="firstName"
                  label="First Name"
                  className="form-input px-4 py-3 rounded-md w-full"
                />
                {errors.firstName && touched.firstName ? (
                  <div className="error">{errors.firstName}</div>
                ) : null}
              </div>
              <div className="mt-5">
                <label>Middle Name</label>
                <Field
                  name="middleName"
                  label="Middle Name"
                  className="form-input px-4 py-3 rounded-md w-full"
                />
                {errors.middleName && touched.middleName ? (
                  <div className="error">{errors.middleName}</div>
                ) : null}
              </div>
              <div className="mt-5">
                <label>Last Name</label>
                <Field
                  name="lastName"
                  label="Last Name"
                  className="form-input px-4 py-3 rounded-md w-full"
                />
                {errors.lastName && touched.lastName ? (
                  <div className="error">{errors.lastName}</div>
                ) : null}
              </div>

              <div className="mt-7">
                <label htmlFor="dateOfBirth" className="block">
                  Date of Birth
                </label>
                <div className="flex">
                  <div className="w-1/3">
                    <Field
                      name="day"
                      max={2}
                      placeholder="DD"
                      className="form-input py-3 w-full text-center rounded-l-md"
                    />
                    {errors.day && touched.day ? <div className="error">{errors.day}</div> : null}
                  </div>
                  <div className="w-1/3">
                    <Field
                      name="month"
                      max={2}
                      placeholder="MM"
                      className="form-input border-l-0 border-r-0 py-3 w-full text-center"
                    />
                    {errors.month && touched.month ? (
                      <div className="error">{errors.month}</div>
                    ) : null}
                  </div>
                  <div className="w-1/3">
                    <Field
                      name="year"
                      max={4}
                      placeholder="YYYY"
                      className="form-input py-3 w-full text-center rounded-r-md"
                    />
                    {errors.year && touched.year ? (
                      <div className="error">{errors.year}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <p className="py-8 mt-1">
                {/* <button type='submit' className='bg-blue-700 hover:bg-blue-600 focus:bg-blue-600 text-white py-3 px-10 w-full rounded-md shadow-lg'>Next</button> */}
                <button
                  type="submit"
                  // onClick={() => router('/basics/relationship')}
                  className="bg-blue-700 hover:bg-blue-600 focus:bg-blue-600 text-white py-3 px-10 w-full rounded-md shadow-lg">
                  Next
                </button>
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
