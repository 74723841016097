import PropTypes from 'prop-types';
import { DeleteOutlined, FileOutlined, InboxOutlined, WarningFilled } from '@ant-design/icons';
import {
  App,
  Button,
  Card,
  Col,
  Empty,
  Modal,
  Popconfirm,
  Row,
  Transfer,
  Typography,
  Upload
} from 'antd';
import { memo, useMemo, useState } from 'react';
import axios from 'axios';
import { DOCUMENTS } from '../../utils/constants';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { headers, getLoggedInUser } from '../../utils';
const { Dragger } = Upload;

const loggedInUser = getLoggedInUser();
function DocumentsTab({ applicant, refetchApplication }) {
  const { id: applicationId } = useParams();
  const { notification } = App.useApp();
  const [isAddDocsModalOpen, setIsAddDocsModalOpen] = useState(false);
  const [isAddingDocuments, setisAddingDocuments] = useState(false);
  const [transferTargetKeys, setTransferTargetKeys] = useState([]);
  const [transferSelectedKeys, setTransferSelectedKeys] = useState([]);

  //get all documents that are already requested for the customer
  const alreadyRequestedDocs = useMemo(
    () => applicant?.requestedDocuments.map(({ type }) => type),
    [applicant]
  );

  //filter out the docs that are already requested, so as to not show them in left section of transfer
  const allDocuments = useMemo(
    () =>
      DOCUMENTS.filter((doc) => !alreadyRequestedDocs?.includes(doc))
        .sort()
        .map((d) => ({ key: d, title: d })),
    [alreadyRequestedDocs]
  );
  // console.log({ allDocuments, alreadyRequestedDocs });
  // const allDocuments = useMemo(() => DOCUMENTS.sort().map((d) => ({ key: d, title: d })), []);

  const props = {
    name: 'file',
    multiple: true,
    customRequest: async ({ data, file, onError, onSuccess }) => {
      const { applicantId } = data;

      const formData = new FormData();
      if (data) {
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
      }
      formData.append('file', file);

      try {
        let durl = `/applications/${applicationId}/applicants/${applicantId}/documents/upload`;
        await axios.put(durl, formData, { headers });
        onSuccess('Ok');
        notification.success({
          message: 'Document uploaded successfully'
        });
        refetchApplication();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Could not upload document',
          description: err.response?.data.message
        });
        onError(err);
      }
    }
  };

  const deleteDocument = async (applicantId, docId) => {
    try {
      await axios.delete(
        `/applications/${applicationId}/applicants/${applicantId}/documents/${docId}`
      );
      notification.success({
        message: 'Deleted successfully'
      });
      refetchApplication();
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Could not delete document',
        description: error.response?.data.message
      });
    }
  };

  const deleteRequestedDocument = async (applicantId, requestedDocId) => {
    try {
      await axios.delete(
        `/applications/${applicationId}/applicants/${applicantId}/documents/requested/${requestedDocId}`
      );
      notification.success({
        message: 'Deleted successfully'
      });
      refetchApplication();
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Could not delete document',
        description: error.response?.data.message
      });
    }
  };

  const getDocuments = () => {
    const docsTree = {};
    applicant.requestedDocuments.forEach((requestedDoc) => {
      const _key = requestedDoc.type;
      docsTree[_key] = docsTree[_key] || {};
      const docs = applicant.documents.filter((doc) => doc.type == _key);
      docsTree[_key].id = requestedDoc._id;
      docsTree[_key].requestedAt = requestedDoc.requestedAt;
      docsTree[_key].docs = docs;
      docsTree[_key].requestedBy = requestedDoc.requestedBy;
    });

    const jsx = Object.entries(docsTree).map(([docType, docObject]) => {
      return (
        <Col span={8} key={docType}>
          <Card
            styles={{ body: { padding: 10, paddingRight: 12 } }}
            className="border-gray-400 shadow mb-2">
            <Row gutter={24} className="mb-2">
              <Col span={21}>
                {!docObject.docs[0]?.uploadedAt && (
                  <WarningFilled className="text-xl mr-2" style={{ color: 'orange' }} />
                )}
                <Typography.Text strong>{docType}</Typography.Text>
              </Col>
              <Col span={3}>
                {loggedInUser.id == docObject.requestedBy && (
                  <Popconfirm
                    placement="top"
                    title="Are you sure to delete the document type?"
                    onConfirm={() => deleteRequestedDocument(applicant._id, docObject.id)}
                    okButtonProps={{ danger: true, type: 'primary', size: 'middle' }}
                    okText="Delete"
                    cancelButtonProps={{ size: 'middle' }}>
                    <DeleteOutlined className="text-red-600 text-base" />
                  </Popconfirm>
                )}
              </Col>
            </Row>
            <div>
              <Dragger
                data={{ type: docType, applicantId: applicant._id, isShared: false }}
                {...props}
                className="my-1">
                <p>
                  <InboxOutlined className="text-base mr-2" />
                  <span className="!text-xs text-slate-500">
                    Click or drag file to this area to upload
                  </span>
                </p>
              </Dragger>
              <p>
                <Typography.Text style={{ fontSize: '0.65rem' }} type="secondary">
                  Requested: {dayjs(docObject?.requestedAt).format('DD/MM/YYYY hh:mm a')}
                </Typography.Text>
              </p>
              <div className="mt-2">
                {docObject.docs?.map((doc) => (
                  <div className="mb-2 border border-dashed rounded-sm py-1 px-2" key={doc._id}>
                    <Row>
                      <Col span={21}>
                        <a
                          rel="noreferrer"
                          href={`/files?key=${doc.url}`}
                          target="_blank"
                          className="text-blue-500 block">
                          {doc.fileName}
                        </a>
                        {doc.uploadedAt && (
                          <Typography.Text style={{ fontSize: '0.65rem' }} type="secondary">
                            Uploaded: {dayjs(doc.uploadedAt).format('DD/MM/YYYY hh:mm a')}
                          </Typography.Text>
                        )}
                      </Col>
                      {loggedInUser.id == doc.uploadedBy && (
                        <Col span={3}>
                          <Popconfirm
                            placement="top"
                            title="Are you sure to delete document"
                            onConfirm={() => deleteDocument(applicant._id, doc._id)}
                            okText="Delete"
                            okButtonProps={{ danger: true, type: 'primary', size: 'middle' }}
                            cancelButtonProps={{ size: 'middle' }}>
                            <DeleteOutlined className="text-red-600 text-sm" />
                          </Popconfirm>
                        </Col>
                      )}
                    </Row>
                  </div>
                ))}
              </div>
            </div>
          </Card>
        </Col>
      );
    });

    return jsx;
  };

  const onDocumentTransferred = (nextTargetKeys) => {
    setTransferTargetKeys(nextTargetKeys);
  };

  const onTransferSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    console.log(sourceSelectedKeys);
    setTransferSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const addSelectedDocsFromTransfer = async () => {
    let endpoint = `/applications/${applicationId}/applicants/${applicant._id}/documents/bulk`;
    if (transferTargetKeys.length < 1) {
      return notification.warning({
        message: 'Please move at least one document to right panel first'
      });
    }
    const payload = transferTargetKeys.map((documentName) => ({ type: documentName }));
    try {
      setisAddingDocuments(true);
      await axios.post(endpoint, payload, { headers });
      setisAddingDocuments(false);
      notification.success({
        message: `${payload.length} document types added successfully`
      });
      setIsAddDocsModalOpen(false);
      setTransferTargetKeys([]);
      refetchApplication();
    } catch (err) {
      notification.error({
        message: 'Could not add document types',
        description: err.response?.data.message
      });
      console.error(err);
      setisAddingDocuments(false);
    }
  };

  if (!applicant) return null;
  return (
    <>
      <div className="mt-2 bg-white px-5 py-4 border border-gray-300 rounded-md">
        {applicant.requestedDocuments?.length < 1 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        <Row gutter={[14, 16]} className="mb-4">
          {getDocuments()}
        </Row>
        <Button
          icon={<FileOutlined />}
          onClick={() => {
            setIsAddDocsModalOpen(true);
          }}>
          Add Documents
        </Button>
      </div>
      <Modal
        title="Add Documents"
        style={{ top: 20 }}
        open={isAddDocsModalOpen}
        onCancel={() => setIsAddDocsModalOpen(false)}
        width={800}
        onOk={addSelectedDocsFromTransfer}
        okText="Add Documents"
        okButtonProps={{ loading: isAddingDocuments }}>
        <div className="max-h-[600px] overflow-x-hidden pr-3">
          <Transfer
            onChange={onDocumentTransferred}
            onSelectChange={onTransferSelectChange}
            targetKeys={transferTargetKeys}
            selectedKeys={transferSelectedKeys}
            dataSource={allDocuments}
            titles={['To select', 'Selected']}
            render={(item) => item.title}
            oneWay
            listStyle={{
              width: 400,
              height: 580
            }}
          />
        </div>
      </Modal>
    </>
  );
}

export default memo(DocumentsTab);

DocumentsTab.propTypes = {
  applicant: PropTypes.object,
  refetchApplication: PropTypes.func
};
