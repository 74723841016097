import { clarity } from 'react-microsoft-clarity';
import { useEffect } from 'react';
import { brandWebsiteUrl } from '../../utils';

export const TopBar = () => {
  useEffect(() => {
    clarity.init('htiu8jt5fd');
    // Cookie consent
    clarity.consent();

    // Setup a custom tag
    clarity.setTag('key', 'value');

    // Upgrade session
    clarity.upgrade('upgradeReason');

    // Check if Clarity has been initialized before calling its methods
    if (clarity.hasStarted()) {
      clarity.identify('USER_ID', { userProperty: 'value' });
    }
  });

  const onLogout = () => {
    window.localStorage.removeItem('loanspad');
    window.location.href = brandWebsiteUrl;
  };

  return (
    <div className="container mx-auto sm:px-6">
      <div className="flex justify-between items-center border-b-2 border-gray-100 py-3 md:justify-start md:space-x-10 mx-4 md:mx-0">
        <div className="grid grid-cols-2 px-4">
          <a href={brandWebsiteUrl} className="my-1">
            <img src="/logo.svg" alt="Loanspad" width={150} className="block" />
          </a>
        </div>
        <div className="text-right px-2 hidden">
          <button className="py-2 px-5 bg-blue-600 text-white rounded" onClick={onLogout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};
