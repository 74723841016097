/* eslint-disable react/prop-types */
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label
} from 'recharts';

export const PrincipalChart = (props) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="chart-custom-tooltip">
          <p className="desc">
            <span className="label">Principal Remaining</span>
            <span className="value">{`${payload[0].payload.remainingPrincipalCurrency}`}</span>
          </p>
          <p className="desc">
            <span className="label">Year Remaining</span>
            <span className="value">{`${payload[0].payload.yearRemaining}`}</span>
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="p-5">
      <h2 className="text-center p-5 text-2xl">Loan Balance</h2>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          width={500}
          height={300}
          data={props.remainingPrincipalData}
          margin={{
            top: 5,
            right: 30,
            left: 50,
            bottom: 25
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year">
            <Label value="Years" position="bottom" />
          </XAxis>
          <YAxis
            label={{
              value: 'Amount owing',
              angle: -90,
              position: 'left',
              offset: 40
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend /> */}
          <Line
            type="monotone"
            dataKey="remainingPrincipal"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
