import { useNavigate } from 'react-router-dom';
import SecondaryOptionButton from '../../components/car-loans/buttons/SecondaryOptionButton';
import { useProgressBarContext } from '../../contexts/ProgressBarContext';
import { useEffect } from 'react';
import { saveApplicantFieldInLocalStorage } from '../../utils';
import { LOAN_APP_TYPE } from '../../utils/constants';

export default function AgeEligibilityPage() {
  const navigate = useNavigate();

  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(9);
  }, []);

  const onOptionClick = (val) => {
    saveApplicantFieldInLocalStorage(LOAN_APP_TYPE.CarLoan, 'isAdult', val);
    navigate('../employment-status');
  };
  return (
    <>
      <h3 className="application-question">Are you over 18 years of age?</h3>
      <div className="application-form-container">
        <SecondaryOptionButton onOptionClick={onOptionClick} value={true}>
          Yes
        </SecondaryOptionButton>
        <SecondaryOptionButton onOptionClick={onOptionClick} value={false}>
          No
        </SecondaryOptionButton>
      </div>
    </>
  );
}
