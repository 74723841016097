import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import CommonOptions from '../../components/car-loans/page-components/CommonOptions';
import ContinueButton from '../../components/car-loans/buttons/ContinueButton';
import ApplicationAmountInput from '../../components/car-loans/form-components/ApplicationAmountInput';
import { useProgressBarContext } from '../../contexts/ProgressBarContext';
import { useEffect } from 'react';
import { getCarLoanFieldFromLocalStorage, saveCarLoanAppFieldInLocalStorage } from '../../utils';

export default function DepositPage() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();

  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(6);
  }, []);

  const onFormSubmit = (data) => {
    saveCarLoanAppFieldInLocalStorage('depositAmount', data.deposit);
    navigate('../credit-rating');
  };

  const setDeposit = (val) => {
    setValue('deposit', val);
  };
  return (
    <>
      <h3 className="application-question">Any deposit?</h3>
      <form className="application-form-container" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="grid gap-2">
          <ApplicationAmountInput
            name="deposit-input"
            {...register('deposit', {
              valueAsNumber: true,
              validate: {
                isNumber: (val) => (isNaN(val) ? 'Invalid input' : true),
                isLessThanPurchasePrice: (val) => {
                  const purchasePrice = getCarLoanFieldFromLocalStorage('purchasePrice');
                  if (!purchasePrice) return true;
                  return val < purchasePrice ? true : 'Deposit must be less than purchase price';
                }
              }
            })}
            error={errors?.deposit?.message}
          />
          <CommonOptions className="mt-2">
            <CommonOptions.Tag value={5000} onTagClick={setDeposit}>
              5,000
            </CommonOptions.Tag>
            <CommonOptions.Tag value={10000} onTagClick={setDeposit}>
              10,000
            </CommonOptions.Tag>
            <CommonOptions.Tag value={15000} onTagClick={setDeposit}>
              15,000
            </CommonOptions.Tag>
          </CommonOptions>
        </div>
        <ContinueButton />
      </form>
    </>
  );
}
