/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import clsx from 'clsx';

function getLabelFromValue(array, value) {
  const object = array.find((object) => object.value === value);
  return object?.label;
}

export default function BaseSelect({
  options,
  label,
  name,
  placeholder = 'Select',
  onOptionSelect,
  stateControl,
  error
}) {
  const [shouldDisplayDropdown, setShouldDisplayDropdown] = useState(false);
  const [inputText, setInputText] = useState('');

  //when an option is selected: call the callback, set the input value to the selected option
  useEffect(() => {
    const label = getLabelFromValue(options, stateControl);
    if (label) setInputText(label);
    //eslint-disable-next-line
  }, [stateControl]);

  const filteredOptions = inputText
    ? options.filter(({ label }) => label.toLowerCase().includes(inputText.toLowerCase()))
    : options;

  const onInputClick = () => {
    setInputText('');
  };

  const onInputBlur = () => {
    const label = getLabelFromValue(options, stateControl);
    if (label) {
      setInputText(label);
    }
    setShouldDisplayDropdown(false);
  };

  return (
    <div className="relative">
      <label htmlFor={name} className="text-sm">
        {label}
      </label>
      <div
        className="relative flex justify-end items-center py-5 pl-3 pr-4 w-full mt-2"
        onClick={() => setShouldDisplayDropdown(!shouldDisplayDropdown)}
        onBlur={onInputBlur}>
        <input
          name={name}
          id={name}
          type="text"
          className={clsx(
            'w-full h-full absolute top-0 left-0 bg-white outline outline-1 rounded-md  pl-3 pr-4',
            {
              'outline-red-600': error,
              'outline-black/25 focus:outline-primary-blue': !error
            }
          )}
          placeholder={placeholder}
          value={inputText}
          onChange={(e) => {
            setInputText(e.target.value);
            if (shouldDisplayDropdown == false) setShouldDisplayDropdown(true);
          }}
          onClick={onInputClick}
        />
        <img src="/hairline-arrow-down.svg" alt="" width={15} className="relative" />
      </div>
      {error && <p className="text-xs text-red-600 mt-2">{error}</p>}
      {/* https://stackoverflow.com/questions/65976223/how-to-use-calc-in-tailwind-css */}
      <ul
        className="w-full p-3 bg-white border border-black/25 rounded-md shadow-md absolute top-[calc(79.5px+8px)] z-20"
        hidden={!shouldDisplayDropdown}>
        {filteredOptions.length > 0 ? (
          filteredOptions.map(({ label, value }) => (
            //if we use onClick instead of onMouseDown, the callback will not be executed because onBlur of ancestor div is executed first
            //https://stackoverflow.com/questions/17769005/onclick-and-onblur-ordering-issue
            <li
              className={clsx('p-2 hover:bg-bluey-gray rounded-md', {
                'bg-bluey-gray': stateControl == value
              })}
              onMouseDown={() => onOptionSelect(value)}
              key={value}>
              {label}
            </li>
          ))
        ) : (
          <span className="italic text-sm text-gray-500">No result found</span>
        )}
      </ul>
    </div>
  );
}
