import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SecondaryOptionButton from '../../components/car-loans/buttons/SecondaryOptionButton';
import ContinueButton from '../../components/car-loans/buttons/ContinueButton';
import BaseSelect from '../../components/car-loans/form-components/BaseSelect';
import { useProgressBarContext } from '../../contexts/ProgressBarContext';
import { getCarLoanFieldFromLocalStorage, saveCarLoanAppFieldInLocalStorage } from '../../utils';

const makes = [
  {
    label: 'Honda',
    value: 'honda'
  },
  {
    label: 'Chevrolet',
    value: 'chevrolet'
  },
  {
    label: 'Toyota',
    value: 'toyota'
  },
  {
    label: 'Hyundai',
    value: 'hyundai'
  }
];

export default function SelectVehiclePage() {
  const [make, setMake] = useState('');
  const [makeErr, setMakeErr] = useState('');
  const [model, setModel] = useState('');
  const [modelErr, setModelErr] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (make) setMakeErr('');
    if (model) setModelErr('');
  }, [make, model]);

  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(2);
  }, []);

  const onOptionClick = () => {
    let vehicle = getCarLoanFieldFromLocalStorage('vehicle');
    vehicle = { ...vehicle, make: null, model: null, year: null, modelType: null };
    saveCarLoanAppFieldInLocalStorage('vehicle', vehicle);
    saveCarLoanAppFieldInLocalStorage('vehicleNotDecided', true);
    navigate('../car-condition');
  };

  const onContinueClick = () => {
    if (!make || !model) {
      !make && setMakeErr('Make is required');
      !model && setModelErr('Model is required');
      return;
    }
    let vehicle = getCarLoanFieldFromLocalStorage('vehicle');
    vehicle = { ...vehicle, make, model };
    saveCarLoanAppFieldInLocalStorage('vehicle', vehicle);
    saveCarLoanAppFieldInLocalStorage('vehicleNotDecided', false);
    navigate('../car-condition');
  };
  return (
    <div>
      <h3 className="application-question">Which vehicle type are you interested in?</h3>
      <div className="application-form-container">
        <BaseSelect
          name="make-input"
          label="Make"
          placeholder="E.g. Honda"
          options={makes}
          onOptionSelect={(val) => setMake(val)}
          stateControl={make}
          error={makeErr}></BaseSelect>
        <BaseSelect
          name="model-input"
          label="Model"
          placeholder="E.g. Civic"
          options={makes}
          onOptionSelect={(val) => setModel(val)}
          stateControl={model}
          error={modelErr}></BaseSelect>
        <SecondaryOptionButton onOptionClick={onOptionClick}>
          Haven&apos;t decided yet
        </SecondaryOptionButton>
        <ContinueButton onButtonClick={onContinueClick} />
      </div>
    </div>
  );
}
