import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { fetcher, saveAppFieldInLocalStorage } from '../utils';
import * as Yup from 'yup';
import { useContext, useEffect } from 'react';
import { ProgressContext } from '../App';
import AsyncSelect from 'react-select/async';
import { API_HOST, LOAN_APP_TYPE } from '../utils/constants';
import useSWR from 'swr';
import { useState } from 'react';

const schema = Yup.object().required();
// const schema = Yup.object().shape({
//   currentLender: Yup.string().min(2, 'Too short!').max('100', 'Too Long!').required('Required')
// });

export const CurrentLender = () => {
  const router = useNavigate();
  const [lenders, setLenders] = useState();
  const url = `${API_HOST}/lenders`;

  const { data: responseData } = useSWR(url, fetcher);

  useEffect(() => {
    if (responseData) {
      setLenders(responseData.data);
    }
  }, [responseData]);

  const filterLenders = (inputValue) => {
    return lenders
      ?.filter((i) => i.name?.toLowerCase().includes(inputValue.toLowerCase()))
      .map((x) => ({ label: x.name, value: x.code }));
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterLenders(inputValue));
      }, 1000);
    });

  const { setProgressPercentage } = useContext(ProgressContext);

  useEffect(() => {
    setProgressPercentage(30);
  }, []);

  function onSubmit(values) {
    const {
      currentLender: { value }
    } = values;
    // console.log(value)
    saveAppFieldInLocalStorage(
      LOAN_APP_TYPE.HomeLoan,
      'currentLender',
      value,
      router,
      '/home-loans/refinance/reason-for-refinance'
    );
  }
  return (
    <>
      <p className="text-center mt-8 text-2xl px-2">Who is your Current Lender?</p>

      <div className="mt-6 px-3 w-96 mx-auto">
        <Formik
          enableReinitialize
          validationSchema={schema}
          initialValues={{ currentLender: '' }}
          onSubmit={onSubmit}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Field name="currentLender" className="form-input px-4 py-3 rounded-md ">
                {({ field }) => (
                  <AsyncSelect
                    {...field}
                    placeholder="Start typing..."
                    cacheOptions
                    defaultOptions
                    loadOptions={promiseOptions}
                    value={values.currentLender}
                    onChange={(currentLender) => setFieldValue('currentLender', currentLender)}
                    classNames={{
                      control: () => 'form-input px-4 py-3 rounded-md w-full'
                    }}
                  />
                )}
              </Field>
              {errors.currentLender && touched.currentLender ? (
                <div className="error">{errors.currentLender}</div>
              ) : null}
              <p className="py-4">
                <button type="submit" className="common-button">
                  Next
                </button>
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
