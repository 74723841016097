import { useEffect, useRef, useState } from 'react';
import OtpInput from '../form-components/OtpInput';
import { useNavigate } from 'react-router-dom';
import { useProgressBarContext } from '../../../contexts/ProgressBarContext';
import { getFromLocalStorage, parseJwt, setLocalStorageData } from '../../../utils';
import { API_HOST, LOAN_APP_TYPE } from '../../../utils/constants';
import axios from 'axios';

export default function OtpVerificationSection() {
  const navigate = useNavigate();
  const [otpArray, setOtpArray] = useState(new Array(6).fill(''));
  const inputRef = useRef([]);

  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(15);
  }, []);

  useEffect(() => {
    inputRef.current[0].focus();
  }, []);

  const handleChange = async (e, index) => {
    let _otpArray = [...otpArray];
    // console.log({ etargetValue: e.target.value, onIndex: index })
    _otpArray[index] = e.target.value;
    setOtpArray(_otpArray);
    focusNextInput(index, e.target.value);
    //submission
    if (_otpArray.join('').length == otpArray.length) {
      const application = getFromLocalStorage(LOAN_APP_TYPE.CarLoan);
      if (application) {
        const { applicants } = application;
        var mobile = applicants[0].mobile;
      }
      try {
        const { data: responseData } = await axios.post(`${API_HOST}/auth/auth-number-verify`, {
          mobile,
          oneTimePassword: _otpArray.join('')
        });
        const { token } = responseData;
        const userInfo = parseJwt(token);
        setLocalStorageData('token', token);
        setLocalStorageData('user', JSON.stringify(userInfo));
        navigate('../../submission-success');
      } catch (e) {
        console.error(e);
      }
    }
  };

  const focusNextInput = (currIndex, currValue) => {
    //currValue will be "" in case of backspace/delete keyboard event.
    if (currValue) {
      if (currIndex < otpArray.length - 1) {
        inputRef.current[currIndex + 1].focus();
      }
    } else {
      if (currIndex > 0) {
        inputRef.current[currIndex - 1].focus();
      }
    }
  };

  return (
    <>
      <div className="application-form-container text-center">
        <hr className="mt-8 mb-2" />
        <div>
          <h4 className="font-secondary font-medium">OTP Verification</h4>
          <p className="text-subtitle-gray text-sm mt-2">
            Please enter the OTP sent to <span className="font-medium">04XXXXXXXX</span>
          </p>
        </div>
        <div className="flex gap-4 justify-center">
          {otpArray.map((digit, i) => (
            <OtpInput
              key={i}
              ref={(ref) => (inputRef.current[i] = ref)}
              value={otpArray[i]}
              onChange={(e) => handleChange(e, i)}
            />
          ))}
        </div>
        <p className="text-sm">
          Didn&apos;t receive OTP? <span className="text-link font-medium">Resend</span>
        </p>
      </div>
    </>
  );
}
