import Nav from '../../components/common/home/Nav';
import { RepaymentsCalculator } from '../../components/calculators/Repayments';
import Footer from '../../components/common/home/Footer';
import Regualtions from '../../components/common/home/Regualtions';

function RepaymentCalculatorPage() {
  return (
    <div>
      <Nav />
      <div className="my-24">
        <RepaymentsCalculator />
      </div>
      <Footer />
      <Regualtions />
    </div>
  );
}

export default RepaymentCalculatorPage;
