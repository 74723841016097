/* eslint-disable react/prop-types */
export default function SecondaryOptionButton({ children, onOptionClick, value, ...rest }) {
  const handleClick = () => {
    if (onOptionClick) {
      if (value === null || value === undefined || value === '') {
        return onOptionClick(children);
      }
      onOptionClick(value);
    }
  };
  return (
    <button
      className="text-lg bg-white text-primary-blue border-2 border-primary-blue font-semibold rounded-lg py-3 w-full hover:shadow-xl"
      onClick={handleClick}
      {...rest}>
      {children}
    </button>
  );
}
