import { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import useSWR from 'swr';
import { fetcher, headers } from '../../utils';
import { API_HOST, APPLICANT_PRIMARY, AU_STATES } from '../../utils/constants';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const schema = Yup.object().shape({
  idType: Yup.string().required('Required'),
  driverLicenceState: Yup.string().when('idType', {
    is: 'Drivers Licence',
    then: Yup.string().required('Required')
  }),
  driverLicenceNumber: Yup.string().when('idType', {
    is: 'Drivers Licence',
    then: Yup.string().min(2, 'Too Short!').max(30, 'Too Long!').required('Required')
  }),
  passportNumber: Yup.string().when('idType', {
    is: 'Passport',
    then: Yup.string().min(2, 'Too Short!').max(30, 'Too Long!').required('Required')
  }),
  day: Yup.number().required('Required'),
  month: Yup.number().required('Required'),
  year: Yup.number().required('Required')
});

export const Identification = () => {
  const router = useNavigate();
  const { id: applicationId } = router.query;

  const [primaryApplicant, setPrimaryApplicant] = useState();

  const url = `${API_HOST}/applications`;
  const { data: responseData } = useSWR(applicationId ? `${url}/${applicationId}` : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setPrimaryApplicant(responseData?.applicants?.find((a) => a.type === APPLICANT_PRIMARY));
    }
  }, [responseData]);

  async function onSubmit(values) {
    const dateString = `${values.day}/${values.month}/${values.year}`;
    let vals = {};
    if (values.idType === 'Drivers Licence') {
      let driverLicenceExpiry = dayjs(dateString, 'DD/MM/yyyy');
      if (driverLicenceExpiry) {
        driverLicenceExpiry = driverLicenceExpiry.toISOString();
      }
      vals = { ...values, driverLicenceExpiry };
    } else {
      let passportExpiry = dayjs(dateString, 'DD/MM/yyyy');
      if (passportExpiry) {
        passportExpiry = passportExpiry.toISOString();
      }
      vals = { ...values, passportExpiry };
    }

    delete vals.day;
    delete vals.month;
    delete vals.year;

    try {
      await axios.put(`${url}/${applicationId}/applicants/${primaryApplicant._id}`, vals, {
        headers
      });
      router(
        `/applications/${applicationId}/applicants/${primaryApplicant._id}/upload-identification`
      );
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <p className="text-center w-5/12 mx-auto text-2xl">
        {primaryApplicant?.userInfo.firstName}, please verify your identity
      </p>
      <p className="text-center w-5/12 mx-auto mt-2 text-md">
        {
          "We recommend using an Australian Driver's Licence so we can show you lenders with high confidence."
        }
      </p>
      <div className="w-96 md:w-3/12 px-4 mx-auto">
        <div className="mt-10">
          <Formik
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={schema}
            initialValues={{
              idType: 'Drivers Licence',
              driverLicenceState: primaryApplicant?.driverLicenceState || '',
              driverLicenceNumber: primaryApplicant?.driverLicenceNumber || '',
              day: primaryApplicant?.driverLicenceExpiry
                ? dayjs(primaryApplicant?.driverLicenceExpiry).format('DD')
                : '',
              month: primaryApplicant?.driverLicenceExpiry
                ? dayjs(primaryApplicant?.driverLicenceExpiry).format('MM')
                : '',
              year: primaryApplicant?.driverLicenceExpiry
                ? dayjs(primaryApplicant?.driverLicenceExpiry).format('YYYY')
                : '',
              passportNumber: primaryApplicant?.passportNumber || ''
              // passportExpiry: primaryApplicant?.passportExpiry ? dayjs(primaryApplicant?.passportExpiry).format('DD') : '',
            }}>
            {({ values, errors, touched }) => (
              <Form>
                <div className="mt-10">
                  <label>Identification Type</label>
                  <Field
                    as="select"
                    name="idType"
                    className="form-input px-4 py-3 rounded-md w-full">
                    <option value="Drivers Licence">Australian Drivers Licence</option>
                    <option value="Passport">Passport</option>
                  </Field>
                </div>
                {values.idType === 'Drivers Licence' ? (
                  <>
                    <div className="mt-5">
                      <label>State</label>
                      <Field
                        as="select"
                        name="driverLicenceState"
                        className="form-input px-4 py-3 rounded-md w-full">
                        <option></option>
                        {AU_STATES.map((p) => (
                          <option value={p} key={p}>
                            {p}
                          </option>
                        ))}
                      </Field>
                      {errors.driverLicenceState && touched.driverLicenceState ? (
                        <div className="error">{errors.driverLicenceState}</div>
                      ) : null}
                    </div>
                    <div className="mt-5">
                      <label>{"Driver's Licence Number"}</label>
                      <Field
                        name="driverLicenceNumber"
                        className="form-input px-4 py-3 rounded-md w-full"
                      />
                      {errors.driverLicenceNumber && touched.driverLicenceNumber ? (
                        <div className="error">{errors.driverLicenceNumber}</div>
                      ) : null}
                    </div>
                    <div className="mt-7">
                      <label htmlFor="driverLicenceExpiry" className="block">
                        Expiry Date
                      </label>
                      <div className="flex">
                        <div className="w-1/3">
                          <Field
                            name="day"
                            max={2}
                            placeholder="DD"
                            className="form-input py-3 w-full text-center rounded-l-md"
                          />
                          {errors.day && touched.day ? (
                            <div className="error">{errors.day}</div>
                          ) : null}
                        </div>
                        <div className="w-1/3">
                          <Field
                            name="month"
                            max={2}
                            placeholder="MM"
                            className="form-input border-l-0 border-r-0 py-3 w-full text-center"
                          />
                          {errors.month && touched.month ? (
                            <div className="error">{errors.month}</div>
                          ) : null}
                        </div>
                        <div className="w-1/3">
                          <Field
                            name="year"
                            max={4}
                            placeholder="YYYY"
                            className="form-input py-3 w-full text-center rounded-r-md"
                          />
                          {errors.year && touched.year ? (
                            <div className="error">{errors.year}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mt-5">
                      <label>Passport Number</label>
                      <Field
                        name="passportNumber"
                        className="form-input px-4 py-3 rounded-md w-full"
                      />
                      {errors.passportNumber && touched.passportNumber ? (
                        <div className="error">{errors.passportNumber}</div>
                      ) : null}
                    </div>
                    <div className="mt-7">
                      <label htmlFor="passportExpiry" className="block">
                        Expiry Date
                      </label>
                      <Field name="passportExpiry">
                        {({
                          form: { touched, errors } // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        }) => (
                          <div>
                            <div className="flex">
                              <div className="w-1/3">
                                <input
                                  className="form-input py-3 w-full text-center rounded-l-md"
                                  maxLength={2}
                                  placeholder="DD"
                                />
                              </div>
                              <div className="w-1/3">
                                <input
                                  className="form-input border-l-0 border-r-0 py-3 w-full text-center"
                                  maxLength={2}
                                  placeholder="MM"
                                />
                              </div>
                              <div className="w-1/3">
                                <input
                                  className="form-input py-3 w-full text-center rounded-r-md"
                                  maxLength={4}
                                  placeholder="YYYY"
                                />
                              </div>
                            </div>
                            {errors.passportExpiry && touched.passportExpiry ? (
                              <div className="error">{errors.passportExpiry}</div>
                            ) : null}
                          </div>
                        )}
                      </Field>
                    </div>
                  </>
                )}

                <button type="submit" className="common-button mt-10">
                  Next
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
