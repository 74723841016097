import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import useSWR from 'swr';
import { useState, useEffect } from 'react';
import { API_HOST } from '../utils/constants';
import { fetcher } from '../utils';

export default function Faq() {
  const url = `${API_HOST}/faqs`;
  const [faqs, setFaqs] = useState([]);

  const { data: responseData } = useSWR(url, fetcher);

  useEffect(() => {
    if (responseData) {
      setFaqs(responseData.data);
    }
  }, [responseData]);

  return (
    <div className="w-full px-2 py-4">
      <h2 className="text-4xl font-semibold">Frequently asked questions</h2>
      <div className="mx-auto w-full mt-6 bg-white border border-1">
        {faqs?.map((faq) => (
          <Disclosure key={faq.quesion}>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between px-8 pt-6 pb-6 text-left text-md font-medium text-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                  <span className="font-semibold">{faq.question}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform transition-transform' : ''
                    } h-5 w-5 text-blue-500 transition-transform`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-8 pt-2 pb-10   text-gray-700">
                  <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
}
