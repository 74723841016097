import { createContext, useState } from 'react';
import './App.css';
import './styles/global.css';
import 'remixicon/fonts/remixicon.css';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/common/Layout';
import Home from './components/HomeLoan';
import ReactGA from 'react-ga4';
import { ApplicantName } from './components/ApplicantName';
import { PurchasePrice } from './components/PurchasePrice';
import { ApplicantsProperties } from './components/ApplicantsProperties';
import { BuyingSituation } from './components/BuyingSituation';
import { CreditHistory } from './components/CreditHistory';
import { Deposit } from './components/Deposit';
import { EmploymentType } from './components/EmploymentType';
import { FirstHome } from './components/FirstHome';
import { PropertyStatus } from './components/PropertyStatus';
import { PropertyUse } from './components/PropertyUse';
import { SelfEmployed } from './components/SelfEmployed';
import { StartApplication } from './components/StartApplication';
import { Dashboard } from './components/Dashboard';
import { ApplicationType } from './components/ApplicationType';
import { CurrentInterestRate } from './components/CurrentInterestRate';
import { CurrentLender } from './components/CurrentLender';
import { CurrentLoanAmount } from './components/CurrentLoanAmount';
import { ReasonForRefinance } from './components/ReasonForRefinance';
import { Signin } from './components/Signin';
import { Signup } from './components/Signup';
import { Verify } from './components/Verify';
import { Works } from './components/Works';
import { ApplicationHome } from './components/application/ApplicationHome';
import { ResidencyStatus } from './components/application/ResidencyStatus';
import { AdditionalIncome } from './components/application/AdditionalIncome';
import { ApplicantInformation } from './components/application/ApplicantInformation';
import { Dependants } from './components/application/Dependants';
import { EConsent } from './components/application/EConsent';
import { Employment } from './components/application/Employment';
import { Expenses } from './components/application/Expenses';
import { Identification } from './components/application/Identification';
import { Relationship } from './components/application/Relationship';
import { UploadIdentification } from './components/application/UploadIdentification';
import { Vehicle } from './components/application/assets/Vehicle';
import { RepaymentsCalculator } from './components/calculators/Repayments';
import AboutPage from './pages/About';
import BlogPage from './pages/Blog';
import RepaymentCalculatorPage from './pages/calculators/Repayment';
import BlogPostPage from './pages/BlogDetail';
import Page from './components/Pages';
import { ScrollToTop } from './components/ScrollToTop';
import FaqsPage from './pages/FaqsPage';
import { API_HOST, ENV, ENVIRONMENTS, GA_MEASUREMENT_ID } from './utils/constants';
import { HelmetProvider } from 'react-helmet-async';
// import VehiclePurchasePrice from "./components/car-loans/PurchasePrice";
import VehicleTypePage from './pages/car-loans/VehicleTypePage';
import CarFunnelLayout from './components/car-loans/layouts/CarFunnelLayout';
import SelectVehiclePage from './pages/car-loans/SelectVehiclePage';
import CarConditionPage from './pages/car-loans/CarConditionPage';
import PurchasePricePage from './pages/car-loans/PurchasePricePage';
import DepositPage from './pages/car-loans/DepositPage';
import CreditRatingPage from './pages/car-loans/CreditRatingPage';
import ResidencyPage from './pages/car-loans/ResidencyPage';
import AgeEligibilityPage from './pages/car-loans/AgeEligibilityPage';
import EmploymentStatusPage from './pages/car-loans/EmploymentStatusPage';
import MonthlyIncomePage from './pages/car-loans/MonthlyIncomePage';
import IncomeHistoryPage from './pages/car-loans/IncomeHistoryPage';
import NamePage from './pages/car-loans/NamePage';
import MobileVerificationPage from './pages/car-loans/MobileVerificationPage';
import OtpVerificationSection from './components/car-loans/page-components/OtpVerificationSection';
import SubmissionSuccessfulPage from './pages/car-loans/SubmissionSuccessfulPage';
import UsagePage from './pages/car-loans/UsagePage';
import { ConfigProvider, App } from 'antd';
import axios from 'axios';
import FilesPage from './components/application/FilesPage';
import Esign from './pages/Esign';

axios.defaults.baseURL = API_HOST;

if (ENV === ENVIRONMENTS.production) {
  ReactGA.initialize(GA_MEASUREMENT_ID);
}

export const ProgressContext = createContext();

function MyApp() {
  const [progressPercentage, setProgressPercentage] = useState(0);

  return (
    <HelmetProvider>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#1D4ED8'
          }
        }}>
        <App notification={{ placement: 'bottom', duration: 3 }}>
          <ProgressContext.Provider value={{ progressPercentage, setProgressPercentage }}>
            <ScrollToTop />
            {/* {metaInfo} */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/faqs" element={<FaqsPage />} />
              <Route path="/blogs/:slug" element={<BlogPage />} />
              <Route path="/blog-posts/:slug" element={<BlogPostPage />} />
              {/* <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:slug" element={<BlogPostPage />} /> */}
              <Route path="/pages/:slug" element={<Page />} />
              <Route
                path="/dashboard"
                element={
                  <Layout>
                    <Dashboard />
                  </Layout>
                }
              />

              <Route
                path="/signin"
                element={
                  <Layout>
                    <Signin />
                  </Layout>
                }
              />
              <Route
                path="/signup"
                element={
                  <Layout>
                    <Signup />
                  </Layout>
                }
              />
              <Route
                path="/verify"
                element={
                  <Layout>
                    <Verify />
                  </Layout>
                }
              />
              <Route
                path="/how-it-works"
                element={
                  <Layout>
                    <Works />
                  </Layout>
                }
              />
              <Route
                path="/application-type"
                element={
                  <Layout>
                    <ApplicationType />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/finance/applicant-name"
                element={
                  <Layout>
                    <ApplicantName />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/refinance/applicant-name"
                element={
                  <Layout>
                    <ApplicantName />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/finance/purchase-price"
                element={
                  <Layout>
                    <PurchasePrice />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/finance/applicants-properties"
                element={
                  <Layout>
                    <ApplicantsProperties />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/refinance/applicants-properties"
                element={
                  <Layout>
                    <ApplicantsProperties />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/finance/buying-situation"
                element={
                  <Layout>
                    <BuyingSituation />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/finance/credit-history"
                element={
                  <Layout>
                    <CreditHistory />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/refinance/credit-history"
                element={
                  <Layout>
                    <CreditHistory />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/refinance/current-interest-rate"
                element={
                  <Layout>
                    <CurrentInterestRate />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/refinance/current-lender"
                element={
                  <Layout>
                    <CurrentLender />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/refinance/current-loan-amount"
                element={
                  <Layout>
                    <CurrentLoanAmount />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/finance/deposit"
                element={
                  <Layout>
                    <Deposit />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/finance/employment-type"
                element={
                  <Layout>
                    <EmploymentType />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/refinance/employment-type"
                element={
                  <Layout>
                    <EmploymentType />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/finance/first-home"
                element={
                  <Layout>
                    <FirstHome />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/finance/property-status"
                element={
                  <Layout>
                    <PropertyStatus />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/finance/property-use"
                element={
                  <Layout>
                    <PropertyUse />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/refinance/property-use"
                element={
                  <Layout>
                    <PropertyUse />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/finance/self-employed"
                element={
                  <Layout>
                    <SelfEmployed />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/refinance/self-employed"
                element={
                  <Layout>
                    <SelfEmployed />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/refinance/reason-for-refinance"
                element={
                  <Layout>
                    <ReasonForRefinance />
                  </Layout>
                }
              />
              <Route
                path="/home-loans/start-application"
                element={
                  <Layout>
                    <StartApplication />
                  </Layout>
                }
              />
              <Route path="/calculators/repayments" element={<RepaymentCalculatorPage />} />
              <Route
                path="/applications/:id"
                element={
                  <Layout>
                    <ApplicationHome />
                  </Layout>
                }
              />
              <Route
                path="/applications/:id/applicants/:applicantId/additional-incomes"
                element={
                  <Layout>
                    <AdditionalIncome />
                  </Layout>
                }
              />
              <Route
                path="/applications/:id/applicants/:applicantId/additional-information"
                element={
                  <Layout>
                    <ApplicantInformation />
                  </Layout>
                }
              />
              <Route
                path="/applications/:id/applicants/:applicantId/dependants"
                element={
                  <Layout>
                    <Dependants />
                  </Layout>
                }
              />
              <Route
                path="/applications/:id/applicants/:applicantId/e-consent"
                element={
                  <Layout>
                    <EConsent />
                  </Layout>
                }
              />
              <Route
                path="/applications/:id/applicants/:applicantId/employments"
                element={
                  <Layout>
                    <Employment />
                  </Layout>
                }
              />
              <Route
                path="/applications/:id/applicants/:applicantId/expenses"
                element={
                  <Layout>
                    <Expenses />
                  </Layout>
                }
              />
              <Route
                path="/applications/:id/applicants/:applicantId/identification"
                element={
                  <Layout>
                    <Identification />
                  </Layout>
                }
              />
              <Route
                path="/applications/:id/applicants/:applicantId/relationship"
                element={
                  <Layout>
                    <Relationship />
                  </Layout>
                }
              />
              <Route
                path="/applications/:id/applicants/:applicantId/residency-status"
                element={
                  <Layout>
                    <ResidencyStatus />
                  </Layout>
                }
              />
              <Route
                path="/applications/:id/applicants/:applicantId/upload-identification"
                element={
                  <Layout>
                    <UploadIdentification />
                  </Layout>
                }
              />
              <Route
                path="/applications/:id/assets/vehicle"
                element={
                  <Layout>
                    <Vehicle />
                  </Layout>
                }
              />
              <Route
                path="/applications/:id/properties/:propertyId/information"
                element={
                  <Layout>
                    <StartApplication />
                  </Layout>
                }
              />
              <Route
                path="/applications/:id/add-applicants"
                element={
                  <Layout>
                    <StartApplication />
                  </Layout>
                }
              />
              <Route
                path="/applications/:id/location"
                element={
                  <Layout>
                    <StartApplication />
                  </Layout>
                }
              />
              <Route
                path="/files"
                element={
                  <Layout>
                    <FilesPage />
                  </Layout>
                }
              />
              <Route
                path="/calculators/repayments"
                element={
                  <Layout>
                    <RepaymentsCalculator />
                  </Layout>
                }
              />
              <Route
                path="/esign/applications/:applicationId/applicant/:applicantId/request/:requestId"
                element={
                  <Layout>
                    <Esign />
                  </Layout>
                }
              />
              {/* Routes for car loan funnel */}
              <Route path="/car-loans" element={<CarFunnelLayout />}>
                <Route path="finance">
                  <Route path="vehicle-type" element={<VehicleTypePage />} />
                  <Route path="select-vehicle" element={<SelectVehiclePage />} />
                  <Route path="car-condition" element={<CarConditionPage />} />
                  <Route path="usage" element={<UsagePage />} />
                  <Route path="purchase-price" element={<PurchasePricePage />} />
                  <Route path="deposit" element={<DepositPage />} />
                  <Route path="credit-rating" element={<CreditRatingPage />} />
                  <Route path="residency" element={<ResidencyPage />} />
                  <Route path="age-eligibility" element={<AgeEligibilityPage />} />
                  <Route path="employment-status" element={<EmploymentStatusPage />} />
                  <Route path="monthly-income" element={<MonthlyIncomePage />} />
                  <Route path="income-history" element={<IncomeHistoryPage />} />
                  <Route path="name" element={<NamePage />} />
                  <Route path="mobile-verification" element={<MobileVerificationPage />}>
                    <Route path="otp-verification" element={<OtpVerificationSection />} />
                  </Route>
                  <Route path="submission-success" element={<SubmissionSuccessfulPage />} />
                </Route>
              </Route>
              {/* <Route path="/car-loans/finance/purchase-price" element={<PurchasePricePage />} /> */}
            </Routes>
          </ProgressContext.Provider>
        </App>
      </ConfigProvider>
    </HelmetProvider>
  );
}

export default MyApp;
