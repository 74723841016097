//RESIDENCY STATUS
export const RESIDENCY_STATUS_AUSTRALIAN_CITIZEN = 'Australian Citizen';
export const RESIDENCY_STATUS_PERMANENT_RESIDENT = 'Permanent Resident';
export const RESIDENCY_STATUSES = [
  RESIDENCY_STATUS_AUSTRALIAN_CITIZEN,
  RESIDENCY_STATUS_PERMANENT_RESIDENT
];

//EMPLOYMENT STATUS
export const EMPLOYMENT_STATUS_EMPLOYED = 'Employed';
export const EMPLOYMENT_STATUS_SELF_EMPLOYED = 'Self Employed';
export const EMPLOYMENT_STATUS_UNEMPLOYED = 'Unemployed';
export const EMPLOYMENT_STATUS_CASUAL = 'Casual';
export const EMPLOYMENT_STATUS_PENSIONER = 'Pensioner';
export const EMPLOYMENT_STATUS_CENTRELINK_NEWSTART = 'Centrelink/Newstart';
export const EMPLOYMENT_STATUSES = [
  EMPLOYMENT_STATUS_EMPLOYED,
  EMPLOYMENT_STATUS_SELF_EMPLOYED,
  EMPLOYMENT_STATUS_UNEMPLOYED,
  EMPLOYMENT_STATUS_CASUAL,
  EMPLOYMENT_STATUS_PENSIONER,
  EMPLOYMENT_STATUS_CENTRELINK_NEWSTART
];

//INCOME HISTORY
export const INCOME_LESS_THAN_3_MONTHS = 'Less than 3 months';
export const INCOME_3_MONTHS_OR_MORE = '3 months or more';
export const INCOME_DURATIONS = [INCOME_LESS_THAN_3_MONTHS, INCOME_3_MONTHS_OR_MORE];
export const INCOME_DURATION_LABEL_VALUE_ARRAY = [
  {
    label: INCOME_LESS_THAN_3_MONTHS,
    value: false
  },
  {
    label: INCOME_3_MONTHS_OR_MORE,
    value: true
  }
];
