import { Button, Col, Form, InputNumber, Modal, Row, Select, App, Input, Divider } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_HOST, Frequency, INCOME_TYPES } from '../../utils/constants';
import axios from 'axios';
import PropTypes from 'prop-types';
function AddOtherIncomeModal({
  isOtherIncomeModalOpen,
  hideOtherIncomeModal,
  primaryApplicant,
  refetchApplication,
  otherIncomeForEdit
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { id: applicationId } = useParams();
  const { notification } = App.useApp();

  useEffect(() => {
    if (otherIncomeForEdit) {
      form.setFieldsValue(otherIncomeForEdit);
    } else {
      form.resetFields();
    }
  }, [otherIncomeForEdit]);

  const onFinish = async (values) => {
    const baseEndpoint = `${API_HOST}/applications/${applicationId}/applicants/${primaryApplicant._id}/incomes`;
    const endpoint = otherIncomeForEdit
      ? `${baseEndpoint}/${otherIncomeForEdit._id}`
      : baseEndpoint;

    try {
      setIsLoading(true);
      await axios.put(endpoint, values);
      setIsLoading(false);
      hideOtherIncomeModal();
      notification.success({
        message: `Income ${otherIncomeForEdit ? 'updated' : 'added'} successfully`
      });
      form.resetFields();
      refetchApplication();
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: `Could not ${otherIncomeForEdit ? 'update' : 'add'} income`,
        description: error.response?.data?.message
      });
      console.log(error);
    }
  };

  return (
    <Modal
      title={`${otherIncomeForEdit ? 'Update' : 'Add'} Other Income`}
      footer={null}
      style={{ top: 20 }}
      open={isOtherIncomeModalOpen}
      onCancel={hideOtherIncomeModal}
      forceRender>
      <Form form={form} layout="vertical" className="mt-6" onFinish={onFinish}>
        <Form.Item
          rules={[{ required: true, message: 'Type is required' }]}
          label="Type"
          name="type">
          <Select>
            {INCOME_TYPES.map((incomeType) => (
              <Select.Option value={incomeType} key={incomeType}>
                {incomeType}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Net Income"
              name="netIncome"
              rules={[{ required: true, message: 'Net Income is required' }]}
              initialValue={1000}>
              <InputNumber
                className="w-full"
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Frequency"
              name="frequency"
              rules={[{ required: true, message: 'Frequency is required' }]}>
              <Select>
                {Object.keys(Frequency).map((key) => (
                  <Select.Option value={Frequency[key]} key={Frequency[key]}>
                    {Frequency[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>
        <Divider />
        <div className="text-right">
          <Button onClick={hideOtherIncomeModal} className="mr-4">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default memo(AddOtherIncomeModal);

AddOtherIncomeModal.propTypes = {
  isOtherIncomeModalOpen: PropTypes.bool,
  hideOtherIncomeModal: PropTypes.func,
  primaryApplicant: PropTypes.object,
  refetchApplication: PropTypes.func,
  otherIncomeForEdit: PropTypes.object
};
