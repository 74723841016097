import { Link } from 'react-router-dom';

function CompareLenders() {
  return (
    <div className="md:my-48">
      <div className="mx-5 rounded-2xl bg-blue-900 ">
        <div className="md:grid grid-cols-2">
          <div className="md:py-12 md:px-32 px-6 py-10">
            <p className="text-blue-300 text-xl md:text-xl font-semibold md:leading-tight">
              FIND THE RIGHT LOAN FOR YOU
            </p>
            <p className="text-white text-4xl md:text-6xl font-bold md:leading-tight">
              Choose your <br /> home loan with confidence
            </p>
            <p className="text-white text-xl md:text-xl mt-7 font-normal md:leading-relaxed md:tracking-wide">
              Choosing from over 2,500 different home loan options means you can find the right loan
              for your individual needs. Plus, our experts will negotiate on your behalf to get you
              the best deal possible.
            </p>
            <Link to="/application-type">
              <button className="bg-white rounded py-4 px-12 text-xl font-semibold mt-12 w-full md:w-auto hover:bg-slate-200">
                Start your journey
              </button>
            </Link>
          </div>
          <div className="choose-loan-right"></div>
        </div>
      </div>
    </div>
  );
}

export default CompareLenders;
