import { forwardRef } from 'react';
import BaseInput from './BaseInput';

const OtpInput = forwardRef(function OtpInput({ ...rest }, ref) {
  return (
    <BaseInput
      className="max-w-[45px] text-center"
      placeholder="0"
      maxLength="1"
      ref={ref}
      {...rest}
    />
  );
});

export default OtpInput;
