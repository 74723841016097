import { Alert, Spin } from 'antd';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../utils';
import { API_HOST } from '../../utils/constants';

export default function FilesPage() {
  const [queryParams] = useSearchParams();
  const key = queryParams.get('key');
  const { data: responseData, error } = useSWR(
    key ? `${API_HOST}/files?key=${key}` : null,
    fetcher
  );

  if (!key) {
    return (
      <p className="font-semibold text-xl text-center pb-32">No document specified for display</p>
    );
  }

  if (error) {
    console.log(error);
    return (
      <Alert
        type="error"
        message={
          <span className="font-semibold">Something went wrong while fetching the document.</span>
        }
        showIcon
        className="max-w-[400px] mx-auto py-4"
      />
    );
  }

  if (responseData) {
    window.location.href = responseData.url;
  }

  return <Spin spinning={true} fullscreen />;
}
