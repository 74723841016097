import Footer from '../components/common/home/Footer';
import Regualtions from '../components/common/home/Regualtions';
import Nav from '../components/common/home/Nav';
import Faq from '../components/Faq';

function FaqsPage() {
  return (
    <div>
      <Nav />
      <div className="bg-gray-100">
        <div className="w-11/12 md:w-9/12 lg:px-14 mx-auto">
          <Faq />
        </div>
      </div>
      <Footer />
      <Regualtions />
    </div>
  );
}

export default FaqsPage;
