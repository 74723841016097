//CREDIT RATING
export const CREDIT_RATING_EXCELLENT = 'Excellent (700+)';
export const CREDIT_RATING_GOOD = 'Good (600 - 699)';
export const CREDIT_RATING_AVERAGE = 'Average (500 - 599)';
export const CREDIT_RATING_BELOW_AVERAGE = 'Below Average (400 - 499)';
export const CREDIT_RATING_POOR = 'Poor (0 - 399)';
export const CREDIT_RATING_NOT_SURE = "I'm not sure";
export const CREDIT_RATINGS = [
  CREDIT_RATING_EXCELLENT,
  CREDIT_RATING_GOOD,
  CREDIT_RATING_AVERAGE,
  CREDIT_RATING_BELOW_AVERAGE,
  CREDIT_RATING_POOR,
  CREDIT_RATING_NOT_SURE
];

export const CREDIT_RATINGS_LABEL_VALUE_ARRAY = [
  {
    label: CREDIT_RATING_EXCELLENT,
    value: 'Excellent'
  },
  {
    label: CREDIT_RATING_GOOD,
    value: 'Good'
  },
  {
    label: CREDIT_RATING_AVERAGE,
    value: 'Average'
  },
  {
    label: CREDIT_RATING_BELOW_AVERAGE,
    value: 'Below average'
  },
  {
    label: CREDIT_RATING_POOR,
    value: 'Poor'
  },
  {
    label: CREDIT_RATING_NOT_SURE,
    value: 'Not sure'
  }
];
