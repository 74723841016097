import { Link } from 'react-router-dom';

function BottomBanner() {
  return (
    <div className="bg-gray-100 py-5">
      <div className="mx-5 bg-gray-300 rounded-2xl  my-10 bottom-hero">
        <div className="align-middle py-10 md:py-24 px-10 text-center">
          <p className="text-white text-2xl md:text-5xl font-semibold mb-1 md:mb-4">
            Online Application process
          </p>
          <p className="text-white text-2xl md:text-5xl font-semibold mb-1 md:mb-4">
            No hidden fees.
          </p>
          {/* <p className="text-white text-2xl md:text-5xl font-semibold mb-1 md:mb-4">Co-borrowing welcome.</p> */}
          <p className="text-white text-xl mt-10">{"Got more questions? We've got answers."}</p>
          <Link to="/faqs">
            <button className="bg-blue-600 py-5 px-10 text-white text-xl font-semibold rounded-md mt-10">
              Check our FAQs
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BottomBanner;
