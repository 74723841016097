import { useNavigate } from 'react-router-dom';
import SecondaryOptionButton from '../../components/car-loans/buttons/SecondaryOptionButton';
import { EMPLOYMENT_STATUSES } from '../../utils/car-loans/constants';
import { useProgressBarContext } from '../../contexts/ProgressBarContext';
import { useEffect } from 'react';
import { saveApplicantFieldInLocalStorage } from '../../utils';
import { LOAN_APP_TYPE } from '../../utils/constants';

export default function EmploymentStatusPage() {
  const navigate = useNavigate();

  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(10);
  }, []);

  const onOptionClick = (val) => {
    saveApplicantFieldInLocalStorage(LOAN_APP_TYPE.CarLoan, 'employmentStatus', val);
    navigate('../monthly-income');
  };
  return (
    <>
      <h3 className="application-question mb-8">What is your employment status?</h3>
      <div className="application-form-container">
        {EMPLOYMENT_STATUSES.map((type) => (
          <SecondaryOptionButton onOptionClick={onOptionClick} key={type}>
            {type}
          </SecondaryOptionButton>
        ))}
      </div>
    </>
  );
}
