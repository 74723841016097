import { useNavigate } from 'react-router-dom';
import PrimaryOptionButton from '../../components/car-loans/buttons/PrimaryOptionButton';
import SecondaryOptionButton from '../../components/car-loans/buttons/SecondaryOptionButton';
import {
  CREDIT_RATINGS_LABEL_VALUE_ARRAY,
  CREDIT_RATING_NOT_SURE
} from '../../utils/car-loans/constants';
import { useProgressBarContext } from '../../contexts/ProgressBarContext';
import { useEffect } from 'react';
import { saveApplicantFieldInLocalStorage } from '../../utils';
import { LOAN_APP_TYPE } from '../../utils/constants';

export default function CreditRatingPage() {
  const navigate = useNavigate();

  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(7);
  }, []);

  const onOptionClick = (val) => {
    saveApplicantFieldInLocalStorage(LOAN_APP_TYPE.CarLoan, 'depictedCreditHistory', val);
    navigate('../residency');
  };
  return (
    <div>
      <h3 className="application-question mb-8">Please select your credit rating</h3>
      <div className="application-form-container">
        {CREDIT_RATINGS_LABEL_VALUE_ARRAY.map(({ label, value }) =>
          label == CREDIT_RATING_NOT_SURE ? (
            <PrimaryOptionButton key={value} onOptionClick={onOptionClick} value={value}>
              {label}
            </PrimaryOptionButton>
          ) : (
            <SecondaryOptionButton key={value} onOptionClick={onOptionClick} value={value}>
              {label}
            </SecondaryOptionButton>
          )
        )}
      </div>
    </div>
  );
}
