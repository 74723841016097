import { App, Button, Result } from 'antd';
import { memo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import { API_HOST } from '../utils/constants';
import { headers } from '../utils';

function Esign() {
  const { notification } = App.useApp();
  const { applicationId, applicantId, requestId } = useParams();
  const signatureCanvasRef = useRef(null);
  const [isSignSubmitted, setIsSignSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
  };

  const saveSignature = async () => {
    const signatureBase64Url = signatureCanvasRef.current.getTrimmedCanvas().toDataURL();
    try {
      setIsLoading(true);
      await axios.post(
        `${API_HOST}/applications/${applicationId}/applicants/${applicantId}/compliance-docs/request/${requestId}/esign`,
        { signatureBase64Url },
        { headers }
      );
      setIsSignSubmitted(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      notification.error({
        message: 'Could not submit esign',
        description: error.response?.data?.message
      });
    }
  };

  const getWidth = () => {
    return document.documentElement.clientWidth > 800 ? 800 : 350;
  };

  if (isSignSubmitted)
    return <Result status="success" title="Thank you for submitting your signature." />;

  return (
    <div className="-mt-4 md:-mt-10 ml-4 md:ml-24 text-base max-w-[900px]">
      <h1 className="text-2xl font-semibold mb-8">Submit E-signature</h1>
      <div className="border border-black inline-block mb-2">
        <SignatureCanvas
          ref={signatureCanvasRef}
          canvasProps={{
            width: getWidth(),
            height: 200,
            className: 'signature-canvas',
            style: { backgroundColor: 'white' }
          }}
        />
      </div>
      <Button className="block" onClick={clearSignature}>
        Clear
      </Button>

      <p className="mt-4 pr-1 md:pr-0">
        By signing, I state that I have read, understood, and agreed to the Terms and Conditions as
        set out by the documents below.
      </p>
      <ul className="list-disc mt-4 ml-4 flex flex-col gap-2">
        <li>
          Privacy Form [
          <a
            style={{ color: 'blue' }}
            target="_blank"
            rel="noreferrer"
            href={`/files?key=applications/${applicationId}/applicants/${applicantId}/compliance-docs/privacy-form.html`}>
            View
          </a>
          ]
        </li>
        <li>
          Credit Guide [
          <a
            style={{ color: 'blue' }}
            target="_blank"
            rel="noreferrer"
            href={`/files?key=applications/${applicationId}/applicants/${applicantId}/compliance-docs/credit-guide.html`}>
            View
          </a>
          ]
        </li>
        <li>
          Credit Quote [
          <a
            style={{ color: 'blue' }}
            target="_blank"
            rel="noreferrer"
            href={`/files?key=applications/${applicationId}/applicants/${applicantId}/compliance-docs/credit-quote.html`}>
            View
          </a>
          ]
        </li>
      </ul>
      <Button
        type="primary"
        size="large"
        className="mt-6 md:mt-5 mx-auto block"
        onClick={saveSignature}
        loading={isLoading}>
        {isLoading ? 'Submitting e-sign. Please do not close this tab' : 'Submit e-sign'}
      </Button>
    </div>
  );
}

export default memo(Esign);
