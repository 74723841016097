import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { fetcher, headers, extractNumeric } from '../../utils';
import { API_HOST } from '../../utils/constants';
import axios from 'axios';

import CurrencyInput from '../common/CurrencyInput';

const schema = Yup.object().shape({
  employmentStatus: Yup.string().required('Required'),
  netIncome: Yup.string().required('Required'),

  employerName: Yup.string().when('employmentStatus', {
    is: 'Employee',
    then: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required')
  }),
  businessType: Yup.string().when('employmentStatus', {
    is: 'Self-Employed',
    then: Yup.string().required('Required')
  }),
  businessName: Yup.string().when('employmentStatus', {
    is: 'Self-Employed',
    then: Yup.string().required('Required')
  }),
  ownershipPercentage: Yup.number().when('employmentStatus', {
    is: 'Self-Employed',
    then: Yup.number().min(1, 'Too Short!').max(100, 'Too Long!').required('Required')
  }),
  hasTaxReturns: Yup.string().when('employmentStatus', {
    is: 'Self-Employed',
    then: Yup.string().required('Required')
  })
});

export const Employment = () => {
  const router = useNavigate();
  const { id: applicationId } = router.query;
  const [primaryApplicant, setPrimaryApplicant] = useState();
  const [currentEmployment, setCurrentEmployment] = useState();

  const url = `${API_HOST}/applications`;
  const { data: responseData } = useSWR(applicationId ? `${url}/${applicationId}` : null, fetcher);

  useEffect(() => {
    if (responseData) {
      const pa = responseData.applicants.find((a) => a.type === 'Primary');
      setPrimaryApplicant(pa);
      const cEmployment = pa.employments?.find((e) => e.isCurrent === true);
      setCurrentEmployment(cEmployment);
    }
  }, [responseData]);

  const onSubmit = async (values) => {
    const vals = {
      ...values,
      netIncome: extractNumeric(values.netIncome),
      netIncomeFrequency: 'Yearly',
      isCurrent: true
    };
    const durl = `${url}/${applicationId}/applicants/${primaryApplicant._id}/employments`;

    try {
      if (currentEmployment) {
        await axios.put(`${durl}/${currentEmployment._id}`, vals, { headers });
      } else {
        await axios.post(durl, vals, { headers });
      }
      await axios.put(
        `${url}/${applicationId}/applicants/${primaryApplicant._id}`,
        { employmentStatus: values.employmentStatus },
        { headers }
      );

      router(
        `/applications/${applicationId}/applicants/${primaryApplicant._id}/additional-incomes`
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <p className="text-center mt-8 text-2xl">{"What's your current employment situation?"}</p>
      <div className="mx-auto px-4 md:w-3/12">
        <div className="mt-10 mx-auto">
          <Formik
            enableReinitialize
            initialValues={{
              employmentStatus: primaryApplicant?.employmentStatus || '',
              employerName: currentEmployment?.employerName || '',
              netIncome: currentEmployment?.netIncome || '',
              businessType: '',
              businessName: '',
              ownershipPercentage: '',
              hasTaxReturns: ''
            }}
            onSubmit={onSubmit}
            validationSchema={schema}>
            {({ values, errors, touched }) => (
              <Form>
                <div className="mt-4">
                  <label>Employment Type</label>
                  <Field
                    as="select"
                    name="employmentStatus"
                    className="form-input px-4 py-3 rounded-md w-full">
                    <option></option>
                    <option value="Employee">Employee</option>
                    <option value="Self-Employed">Self-Employed</option>
                  </Field>
                  {errors.employmentStatus && touched.employmentStatus ? (
                    <div className="error">{errors.employmentStatus}</div>
                  ) : null}
                </div>
                {values.employmentStatus === 'Employee' ? (
                  <>
                    <div className="mt-4">
                      <label>Employer Name</label>
                      <Field
                        name="employerName"
                        className="form-input px-4 py-3 rounded-md w-full"
                      />
                      {errors.employerName && touched.employerName ? (
                        <div className="error">{errors.employerName}</div>
                      ) : null}
                    </div>
                    <div className="mt-4">
                      <label>Annual Base Salary before Tax</label>
                      <Field name="netIncome">
                        {({ field }) => (
                          <CurrencyInput
                            placeholder="$0"
                            className="form-input px-4 py-3 rounded-md w-full"
                            {...field}
                          />
                        )}
                      </Field>
                      {errors.netIncome && touched.netIncome ? (
                        <div className="error">{errors.netIncome}</div>
                      ) : null}
                    </div>
                  </>
                ) : values.employmentStatus === 'Self-Employed' ? (
                  <>
                    <div className="mt-4">
                      <label>Business Structure</label>
                      <Field
                        as="select"
                        name="businessType"
                        className="form-input px-4 py-3 rounded-md w-full">
                        <option></option>
                        <option value="Sole Trader">Sole Trader</option>
                        <option value="Company">Company</option>
                        <option value="Trust">Trust</option>
                      </Field>
                      {errors.businessType && touched.businessType ? (
                        <div className="error">{errors.businessType}</div>
                      ) : null}
                    </div>
                    <div className="mt-4">
                      <label>Ownership percentage</label>
                      <Field
                        name="ownershipPercentage"
                        className="form-input px-4 py-3 rounded-md w-full"
                      />
                      {errors.ownershipPercentage && touched.ownershipPercentage ? (
                        <div className="error">{errors.ownershipPercentage}</div>
                      ) : null}
                    </div>
                    <div className="mt-4">
                      <label>Business Name</label>
                      <Field
                        name="businessName"
                        className="form-input px-4 py-3 rounded-md w-full"
                      />
                      {errors.businessName && touched.businessName ? (
                        <div className="error">{errors.businessName}</div>
                      ) : null}
                    </div>
                    <div className="mt-10">
                      <label>Does the business have tax returns available?</label>
                      <div className="grid grid-cols-2 space-x-2 mt-2">
                        <div className="bg-white border border-gray-400 py-3 px-3 rounded-md col-span-1">
                          <label>
                            {' '}
                            <Field type="radio" name="hasTaxReturns" value="true" />
                            Yes
                          </label>
                        </div>
                        <div className="bg-white border border-gray-400 py-3 px-3 rounded-md col-span-1">
                          <label>
                            {' '}
                            <Field type="radio" name="hasTaxReturns" value="false" />
                            No
                          </label>
                        </div>
                        {errors.hasTaxReturns && touched.hasTaxReturns ? (
                          <div className="error">{errors.hasTaxReturns}</div>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <p className="py-8 mt-1">
                  <button
                    type="submit"
                    // onClick={() => router('/basics/relationship')}
                    className="bg-blue-700 hover:bg-blue-600 focus:bg-blue-600 text-white py-3 px-10 w-full rounded-md shadow-lg">
                    Next
                  </button>
                </p>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
