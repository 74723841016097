import { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import useSWR from 'swr';
import { fetcher, headers, extractNumeric } from '../../utils';
import { API_HOST, APPLICANT_PRIMARY } from '../../utils/constants';
import axios from 'axios';
import CurrencyInput from '../common/CurrencyInput';

const schema = Yup.object().shape({});

const expenseTypes = [
  'Groceries',
  'Childcare',
  'Education',
  'Clothing and personal care',
  'Telephone, internet, Streaming subscriptions',
  'General Basic Insurances',
  'Higher Education/Vocational Training/Professional Fees',
  'Medical and health',
  'Pet Care',
  'Transport',
  'Recreation and entertainment',
  'Primary Residence Running Costs',
  'Council fee on Principal Place of Residence',
  'Insurance (Sickness and Personal, Accident, Life)',
  'Private Schooling and Tuition',
  'Investment Property Running Costs',
  'Health Insurance',
  'Secondary Residence Running Costs',
  'Other Expense',
  'Rent',
  'Board',
  'Child and Spousal Maintenance',
  'Other Commitment'
].sort();

export const Expenses = () => {
  const router = useNavigate();
  const { id: applicationId } = router.query;
  const [primaryApplicant, setPrimaryApplicant] = useState();

  const url = `${API_HOST}/applications`;
  const { data: responseData } = useSWR(applicationId ? `${url}/${applicationId}` : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setPrimaryApplicant(responseData?.applicants?.find((a) => a.type === APPLICANT_PRIMARY));
    }
  }, [responseData]);

  async function onSubmit(values) {
    const redirectUrl = `/applications/${applicationId}/applicants/${primaryApplicant._id}/identification`;

    let data = {};
    Object.keys(values).forEach((key) => {
      const [applicantId, expenseType] = key.split('-');
      if (!expenseTypes.includes(applicantId)) {
        data[applicantId] = data[applicantId] || [];
        data[applicantId].push({
          type: expenseType,
          amount: extractNumeric(values[key]) || 0,
          frequency: values[`${expenseType}-frequency`] || 'Monthly'
        });
      }
    });

    if (Object.keys(values).length === 0) {
      router(redirectUrl);
      return;
    }

    try {
      await axios.put(
        `${url}/${applicationId}/applicants/${primaryApplicant._id}/expenses`,
        data[primaryApplicant._id],
        { headers }
      );
      router(redirectUrl);
    } catch (error) {
      console.log(error);
    }
  }

  const getFrequencies = () =>
    ['Monthly', 'Weekly', 'Fortnighly', 'Yearly'].map((d) => (
      <option value={d} key={d}>
        {d}
      </option>
    ));

  return (
    <>
      <p className="text-center w-5/12 mx-auto mt-8 text-2xl">Tell us about your living expenses</p>
      <div className="w-96 md:w-3/12 px-4 mx-auto">
        <div className="mt-10">
          <Formik
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={schema}
            initialValues={{}}>
            {() => (
              <Form>
                {expenseTypes.map((expenseType) => (
                  <div className="mt-5" key={expenseType}>
                    <label className="block text-sm mb-1">{expenseType}</label>
                    <div className="grid grid-cols-2 space-x-2" key={expenseType}>
                      <div>
                        <Field
                          name={`${primaryApplicant?._id}-${expenseType}`}
                          className="form-input px-4 py-3 rounded-md">
                          {({ field }) => (
                            <CurrencyInput
                              placeholder="$0"
                              {...field}
                              className="form-input px-4 py-3 rounded-md"
                            />
                          )}
                        </Field>
                      </div>
                      <div>
                        <Field
                          name={`${expenseType}-frequency`}
                          as="select"
                          className="form-input px-4 py-3 rounded-md w-full">
                          {getFrequencies()}
                        </Field>
                      </div>
                    </div>
                  </div>
                ))}
                <button type="submit" className="common-button mt-10">
                  Next
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
