/* eslint-disable react/prop-types */
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const BlogCard = ({ blogPost }) => {
  return (
    <>
      {/*Blog-Container-Start*/}
      <div className="shadow-md hover:shadow-xl bg-white rounded-lg overflow-hidden shadow-gray-300/30">
        <div className="w-full h-56 overflow-hidden blog-thumb p-1 bg-white">
          <Link to={`/blog-posts/${blogPost.slug}`}>
            <img
              src={blogPost.imgUrl}
              alt=""
              className="h-full w-full rounded-tl-lg rounded-tr-lg"
            />
          </Link>
        </div>
        <div className="w-full p-8">
          <div className="flex gap-2">
            {blogPost.tags?.map((tag) => (
              <span key={tag} className="bg-blue-100 p-1 px-4 text-blue-800 rounded-md text-xs">
                {tag}
              </span>
            ))}
          </div>

          <p className="mt-2 text-sm">
            {dayjs(blogPost.date).format('DD/MM/YYYY')} |{' '}
            <span className="font-bold text-xs">{blogPost?.authorInfo?.name}</span>
          </p>
          <h3 className="font-semibold text-xl mt-2">
            <Link to={`/blog-posts/${blogPost.slug}`} className="tracking-tight">
              {blogPost.title}
            </Link>
          </h3>
          <p className="text-sm font-light leading-6 mt-3 text-gray-500 blog-desc">
            {blogPost.desc}
          </p>
        </div>
      </div>
      {/*Blog-Container-End*/}
    </>
  );
};
export default BlogCard;
