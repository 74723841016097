import PropTypes from 'prop-types';
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, App } from 'antd';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  API_HOST,
  HOME_CONTENTS,
  MOTOR_VEHICLE,
  NON_APPLICANT,
  PROPERTY,
  PROPERTY_USAGES,
  TOOLS_EQUIPMENT,
  assetTypes
} from '../../utils/constants';
import { memo, useEffect, useState } from 'react';
import { SAVING_ACCOUNT, TERM_DEPOSIT } from '../../utils/constants';
import { MinusCircleOutlined } from '@ant-design/icons';
import { getApplicantFullName } from '../../utils';
const { Option } = Select;

function AddAssetModal({
  isAssetModalOpen,
  hideAssetModal,
  application,
  refetchApplication,
  assetForEdit
}) {
  const [form] = Form.useForm();
  const [type, setType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { notification } = App.useApp();
  const { id: applicationId } = useParams();
  const endpoint = `${API_HOST}/applications/${applicationId}/assets`;

  useEffect(() => {
    if (assetForEdit) {
      form.setFieldsValue(assetForEdit);
      setType(assetForEdit.type);
    } else {
      form.resetFields();
      setType(null);
    }
  }, [assetForEdit]);

  const renderFields = () => {
    if ([SAVING_ACCOUNT, TERM_DEPOSIT].includes(type)) {
      return (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Institution" rules={[{ required: true }]} name="institutionName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Account Name" rules={[{ required: true }]} name="accountName">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="BSB" rules={[{ required: true }]} name="bsb">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Account Number" name="accountNumber">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Value" rules={[{ required: true }]} name="value">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  className="w-36"
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      );
    }
    if ([MOTOR_VEHICLE].includes(type)) {
      return (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Make" rules={[{ required: true }]} name="make">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Model" rules={[{ required: true }]} name="model">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Year" rules={[{ required: true }]} name="year">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Rego" name="rego">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Estimate Basis" rules={[{ required: true }]} name="estimateBasis">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Value" rules={[{ required: true }]} name="value">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      );
    }
    if ([PROPERTY].includes(type)) {
      return (
        <>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Address" rules={[{ required: true }]} name="propertyAddress">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Property Use" rules={[{ required: true }]} name="propertyUse">
                <Select>
                  {PROPERTY_USAGES.map((pu) => (
                    <Select.Option key={pu}>{pu}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Value" rules={[{ required: true }]} name="value">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      );
    }
    if ([HOME_CONTENTS, TOOLS_EQUIPMENT].includes(type)) {
      return (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Value" rules={[{ required: true }]} name="value">
              <InputNumber
                className="w-1/2"
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
        </Row>
      );
    }
    return null;
  };

  const onFinish = async (values) => {
    const { ownership } = values;
    //if no owner is added
    if (!ownership || !ownership.length) {
      return notification.error({
        message: 'Ownership missing',
        description: 'One or more owners are required'
      });
    }

    //total percentage should be equal to 100%
    const totalPercentage = ownership?.reduce((acc, { percentage }) => acc + percentage, 0);
    if (totalPercentage != 100) {
      return notification.error({
        message: 'Invalid ownership percentage',
        description: 'Total percentage should be 100'
      });
    }

    //submit the data
    const method = assetForEdit ? 'put' : 'post';
    try {
      setIsLoading(true);
      const _endpoint = method == 'put' ? `${endpoint}/${assetForEdit._id}` : endpoint;
      await axios[method](_endpoint, values);
      setIsLoading(false);
      hideAssetModal();
      notification.success({
        message: `Asset ${method == 'put' ? 'updated' : 'added'} successfully`
      });
      form.resetFields();
      refetchApplication();
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: `Could not ${method == 'put' ? 'update' : 'add'} asset`,
        description: error.response?.data?.message
      });
      console.error(error);
    }
  };

  return (
    <Modal
      title={`${assetForEdit ? 'Update' : 'Add'} Asset`}
      footer={null}
      style={{ top: 20 }}
      open={isAssetModalOpen}
      onCancel={hideAssetModal}
      forceRender>
      <Form form={form} layout="vertical" className="mt-6" onFinish={onFinish}>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true
            }
          ]}>
          <Select placeholder="Select" onChange={(val) => setType(val)} allowClear>
            {assetTypes.map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {renderFields()}
        <hr />
        <h2 className="text-base font-semibold mt-3 mb-4">Ownership</h2>
        <Form.List name="ownership">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row key={key} gutter={12}>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      name={[name, 'applicant']}
                      rules={[{ required: true, message: 'Owner is required' }]}>
                      <Select placeholder="Owner">
                        {application?.applicants?.map((a) => (
                          <Select.Option key={a._id}>
                            {getApplicantFullName(a.userInfo)}
                          </Select.Option>
                        ))}
                        <Select.Option key={NON_APPLICANT}>{NON_APPLICANT}</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <div className="flex items-center gap-3">
                      <Form.Item
                        {...restField}
                        name={[name, 'percentage']}
                        rules={[{ required: true, message: 'Percentage is required' }]}
                        initialValue={100}
                        className="mb-0">
                        <InputNumber addonAfter="%" min={0} max={100} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </div>
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button type="dashed" block onClick={() => add()}>
                  + Add Owner
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <div className="text-right">
          <Button onClick={hideAssetModal} className="mr-4">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

AddAssetModal.propTypes = {
  isAssetModalOpen: PropTypes.bool,
  hideAssetModal: PropTypes.func,
  application: PropTypes.object,
  refetchApplication: PropTypes.func,
  assetForEdit: PropTypes.object
};

export default memo(AddAssetModal);
