import { About } from '../components/About';
import Footer from '../components/common/home/Footer';
import Regualtions from '../components/common/home/Regualtions';
import Nav from '../components/common/home/Nav';

function AboutPage() {
  return (
    <div>
      <Nav />
      <About />
      <Footer />
      <Regualtions />
    </div>
  );
}

export default AboutPage;
