import { memo, useCallback, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Empty,
  App,
  Modal
} from 'antd';
import { nanoid } from 'nanoid';
import { AU_STATES, Countries, EmploymentType } from '../../utils/constants';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import EmploymentCard from './EmploymentCard';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

//below two lines fixes an error in which date picker throws an error; https://github.com/ant-design/ant-design/issues/26190#issuecomment-703673400
dayjs.extend(weekday);
dayjs.extend(localeData);
function EmploymentTab({ applicant, refetchApplication }) {
  const [form] = Form.useForm();
  const { notification } = App.useApp();
  const [isLoading, setIsLoading] = useState(false);
  const [IsModalOpen, setIsModalOpen] = useState(false);
  const { id: applicationId } = useParams();
  const endpoint = `/applications/${applicationId}/applicants/${applicant?._id}/employments`;
  const employmentForEdit = useRef(null);

  const openModalWithEmployment = useCallback(
    (employment) => {
      if (employment) {
        employmentForEdit.current = employment;
        form.setFieldsValue({
          ...employment,
          startDate: employment.startDate ? dayjs(employment.startDate) : null,
          endDate: employment.endDate ? dayjs(employment.endDate) : null
        });
      } else {
        employmentForEdit.current = null;
        form.resetFields();
      }
      setIsModalOpen(true);
    },
    [form]
  );

  const deleteEmployment = useCallback(async (id) => {
    const endpoint = `/applications/${applicationId}/applicants/${applicant._id}/employments/${id}`;
    try {
      await axios.delete(endpoint);
      notification.success({
        message: `Deleted successfully`
      });
      refetchApplication();
    } catch (error) {
      notification.error({
        message: 'Could not delete employment',
        description: error.response?.data.message
      });
      console.error(error);
    }
  }, []);

  const onFinish = async (values) => {
    const method = employmentForEdit.current ? 'put' : 'post';
    const _endpoint = employmentForEdit.current
      ? `${endpoint}/${employmentForEdit.current._id}`
      : endpoint;
    try {
      setIsLoading(true);
      await axios[method](_endpoint, values);
      notification.success({
        message: `Employment ${employmentForEdit.current ? 'updated' : 'added'} successfully`
      });
      setIsLoading(false);
      form.resetFields();
      refetchApplication();
      setIsModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: `Could not ${employmentForEdit.current ? 'update' : 'add'} employment`,
        description: error.response?.data?.message
      });
      console.error(error);
    }
  };

  const validateMessages = {
    required: '${label} is required'
  };

  if (!applicant) return null;

  return (
    <div className="mt-2 bg-white pl-5 pr-10 py-4 border border-gray-300 rounded-md">
      {applicant.employments?.length < 1 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      {applicant.employments?.map((emp, i) => (
        <EmploymentCard
          title={`Employment ${i + 1}`}
          employment={emp}
          key={emp._id}
          className="mb-4"
          onEditClick={openModalWithEmployment}
          onDeleteClick={deleteEmployment}
        />
      ))}
      <Button icon={<PlusOutlined />} onClick={() => openModalWithEmployment(null)}>
        Add Employment
      </Button>
      <Modal
        title={`${employmentForEdit.current ? 'Update' : 'Add'} Employment`}
        style={{ top: 20 }}
        open={IsModalOpen}
        onCancel={() => setIsModalOpen(false)}
        width={800}
        onOk={form.submit}
        okButtonProps={{ loading: isLoading }}
        okText="Save">
        <div className="max-h-[500px] overflow-x-hidden pr-3">
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            className="pt-5"
            validateMessages={validateMessages}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Employer Name"
                  rules={[{ required: true, message: 'Employer name is required' }]}
                  name="employerName">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Position" rules={[{ required: true }]} name="position">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="ABN" rules={[{ required: true }]} name="abn">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Industry" rules={[{ required: true }]} name="industry">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Contact Name"
                  rules={[{ required: true, message: 'Contact name is required' }]}
                  name="contactName">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Phone" rules={[{ required: true }]} name="phone">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Row gutter={22}>
                  <Col span={10}>
                    <Form.Item
                      label="Start Date"
                      rules={[{ required: true, message: 'Start date is required' }]}
                      name="startDate">
                      <DatePicker
                        format="DD/MM/YYYY"
                        disabledDate={(current) => {
                          return current > new Date();
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item label="End Date" name="endDate">
                      <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Current" name="isCurrent" valuePropName="checked">
                      <Checkbox />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      label="Time at Employer (Years)"
                      rules={[{ required: true, message: 'Duration is required' }]}
                      name="yearsDuration">
                      <Select>
                        {[...Array(50)].map((x, i) => (
                          <Select.Option key={nanoid()} value={i}>
                            {`${i} year${i < 2 ? '' : 's'}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Time at Employer (Months)"
                      rules={[{ required: true, message: 'Duration is required' }]}
                      name="monthsDuration">
                      <Select>
                        {[...Array(12)].map((x, i) => (
                          <Select.Option key={nanoid()} value={i}>
                            {`${i} month${i < 2 ? '' : 's'}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      label="Employment Type"
                      rules={[{ required: true, message: 'Employment type is required' }]}
                      name="type">
                      <Select
                        options={Object.values(EmploymentType).map((v) => ({
                          value: v,
                          label: v
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="Address" rules={[{ required: true }]} name="fullAddress">
                  <Input.Search />
                </Form.Item>
              </Col>
              <Col span={12} />
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item label="Unit #" name="unitNumber">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      label="Street Number"
                      rules={[{ required: true, message: 'Street number is required' }]}
                      name="streetNumber">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      label="Street Name"
                      rules={[{ required: true, message: 'Street name is required' }]}
                      name="streetName">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Suburb" rules={[{ required: true }]} name="city">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item label="Postcode" rules={[{ required: true }]} name="postcode">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item label="State" name="state" rules={[{ required: true }]}>
                      <Select options={AU_STATES.map((s) => ({ label: s, value: s }))} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[{ required: true }]}
                  initialValue="AUS">
                  <Select
                    options={Countries.map(({ name: cName, code }) => ({
                      label: cName,
                      value: code
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
export default memo(EmploymentTab);

EmploymentTab.propTypes = {
  applicant: PropTypes.object,
  refetchApplication: PropTypes.func
};
