import { Field, Form, Formik } from 'formik';
import { getFromLocalStorage, parseJwt, setLocalStorageData } from '../utils';
import axios from 'axios';
import { API_HOST } from '../utils/constants';
import { useState } from 'react';

export const Verify = () => {
  const [apiError, setApiError] = useState();

  function validateAmount(value) {
    let error;
    if (!value) {
      error = 'Required';
    } else if (!/^[0-9]{1,10}$/i.test(value)) {
      error = 'Invalid OTP';
    }
    return error;
  }

  const onSubmit = async (values) => {
    let mobile = getFromLocalStorage('mobile');

    try {
      const { data: responseData } = await axios.post(`${API_HOST}/auth/auth-number-verify`, {
        mobile,
        oneTimePassword: values.oneTimePassword
      });
      setApiError(null);
      const { token } = responseData;
      const userInfo = parseJwt(token);
      setLocalStorageData('token', token);
      setLocalStorageData('user', JSON.stringify(userInfo));
      window.localStorage.removeItem('mobile');
      // console.log(userInfo)
      window.location.href = '/dashboard';
    } catch (e) {
      console.log(e);
      setApiError(e?.response?.data?.message);
    }
  };

  return (
    <>
      <p className="text-center mt-16 text-2xl md:text-3xl px-2">Verify by entering SMS code</p>
      {apiError ? (
        <div className="text-center mt-5">
          <p className="p-5 bg-red-100 text-red-800 rounded-md w-96 m-auto">{apiError}</p>
        </div>
      ) : null}

      <div className="mt-10">
        <div className="mt-3 flex justify-between px-3 w-96 mx-auto">
          <Formik validateOnBlur initialValues={{ oneTimePassword: '' }} onSubmit={onSubmit}>
            {() => (
              <Form>
                <Field name="oneTimePassword" validate={validateAmount}>
                  {({
                    field, // { name, value, onChange, onBlur }
                    meta
                  }) => (
                    <div>
                      <input
                        type={'text'}
                        pattern="[0-9]*"
                        // disabled={smsSent}
                        className="common-input-text"
                        {...field}
                      />
                      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                    </div>
                  )}
                </Field>
                <div className="mt-6 px-3 w-96 mx-auto">
                  <button
                    type="submit"
                    className="bg-blue-700 hover:bg-blue-600 focus:bg-blue-600 text-white py-3 px-1 w-full rounded-md shadow-lg">
                    Verify
                  </button>
                </div>
                <p className="text-center mt-6 text-md">
                  <button>Resend SMS?</button>
                </p>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
