import PropTypes from 'prop-types';
import { Button, Col, Form, InputNumber, Modal, Row, Select, Input, App } from 'antd';
import { useEffect, useState, memo } from 'react';
import {
  API_HOST,
  CAR_LOAN,
  CREDIT_CARD,
  Frequency,
  HOME_LOAN,
  NON_APPLICANT,
  PERSONAL_LOAN,
  STORE_CARD,
  liabilityTypes
} from '../../utils/constants';
import { getApplicantFullName } from '../../utils';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import axios from 'axios';
function AddLiabilityModal({
  isLiabilityModalOpen,
  hideLiabilityModal,
  application,
  refetchApplication,
  liabilityForEdit
}) {
  const [form] = Form.useForm();
  const [type, setType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { notification } = App.useApp();
  const { id: applicationId } = useParams();
  const endpoint = `${API_HOST}/applications/${applicationId}/liabilities`;

  useEffect(() => {
    if (liabilityForEdit) {
      form.setFieldsValue(liabilityForEdit);
      setType(liabilityForEdit.type);
    } else {
      form.resetFields();
      setType(null);
    }
  }, [liabilityForEdit]);

  const renderFields = () => {
    if ([CREDIT_CARD, STORE_CARD].includes(type)) {
      return (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Lender" rules={[{ required: true }]} name="lender">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Payout" rules={[{ required: true }]} name="installmentAmount">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="installmentFrequency"
                label="Frequency"
                rules={[{ required: true, message: 'Frequency is required' }]}>
                <Select>
                  {Object.keys(Frequency).map((key) => (
                    <Select.Option value={Frequency[key]} key={Frequency[key]}>
                      {Frequency[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Credit Limit" rules={[{ required: true }]} name="creditLimit">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Outstanding Amount"
                rules={[{ required: true }]}
                name="outstandingAmount">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      );
    }
    if ([PERSONAL_LOAN, CAR_LOAN, HOME_LOAN].includes(type)) {
      return (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Lender" rules={[{ required: true }]} name="lender">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Outstanding Amount"
                rules={[{ required: true }]}
                name="outstandingAmount">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Installment Amount"
                rules={[{ required: true }]}
                name="installmentAmount">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Installment Frequency"
                rules={[{ required: true }]}
                name="installmentFrequency">
                <Select style={{ width: '100%' }}>
                  {Object.keys(Frequency).map((k) => (
                    <Select.Option key={k} value={k}>
                      {Frequency[k]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </>
      );
    }
  };

  const onFinish = async (values) => {
    const { ownership } = values;
    //if no owner is added
    if (!ownership || !ownership.length) {
      return notification.error({
        message: 'Ownership missing',
        description: 'One or more owners are required'
      });
    }

    //total percentage should be equal to 100%
    const totalPercentage = ownership?.reduce((acc, { percentage }) => acc + percentage, 0);
    if (totalPercentage != 100) {
      return notification.error({
        message: 'Invalid ownership percentage',
        description: 'Total percentage should be 100'
      });
    }

    //submit the data
    const method = liabilityForEdit ? 'put' : 'post';
    try {
      setIsLoading(true);
      const _endpoint = method == 'put' ? `${endpoint}/${liabilityForEdit._id}` : endpoint;
      await axios[method](_endpoint, values);
      setIsLoading(false);
      hideLiabilityModal();
      notification.success({
        message: `Liability ${method == 'put' ? 'updated' : 'added'} successfully`
      });
      form.resetFields();
      refetchApplication();
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: `Could not ${method == 'put' ? 'update' : 'add'} liability`,
        description: error.response?.data.message[0]
      });
      console.error(error);
    }
  };

  return (
    <Modal
      title={`${liabilityForEdit ? 'Update' : 'Add'} Liability`}
      footer={null}
      style={{ top: 20 }}
      open={isLiabilityModalOpen}
      onCancel={hideLiabilityModal}
      forceRender>
      <Form form={form} layout="vertical" className="mt-6" onFinish={onFinish}>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true
            }
          ]}>
          <Select placeholder="Select" onChange={(val) => setType(val)} allowClear>
            {liabilityTypes.map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {renderFields()}
        <hr />
        <h2 className="text-base font-semibold mt-3 mb-4">Ownership</h2>
        <Form.List name="ownership">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row key={key} gutter={12}>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      name={[name, 'applicant']}
                      rules={[{ required: true, message: 'Owner is required' }]}>
                      <Select placeholder="Owner">
                        {application?.applicants?.map((a) => (
                          <Select.Option key={a._id}>
                            {getApplicantFullName(a.userInfo)}
                          </Select.Option>
                        ))}
                        <Select.Option key={NON_APPLICANT}>{NON_APPLICANT}</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <div className="flex items-center gap-3">
                      <Form.Item
                        {...restField}
                        name={[name, 'percentage']}
                        rules={[{ required: true, message: 'Percentage is required' }]}
                        initialValue={100}
                        className="mb-0">
                        <InputNumber addonAfter="%" min={0} max={100} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </div>
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button type="dashed" block onClick={() => add()}>
                  + Add Owner
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <div className="text-right">
          <Button onClick={hideLiabilityModal} className="mr-4">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

AddLiabilityModal.propTypes = {
  isLiabilityModalOpen: PropTypes.bool,
  hideLiabilityModal: PropTypes.func,
  application: PropTypes.object,
  refetchApplication: PropTypes.func,
  liabilityForEdit: PropTypes.object
};

export default memo(AddLiabilityModal);
