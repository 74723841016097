const data = [
  {
    id: '1',
    name: 'Tell us about you',
    descrription: 'Provide details about your property needs and financial situation.'
  },
  {
    id: '2',
    name: 'We shortlist options',
    descrription:
      'Our smart tech compares thousands of options from 25+ lenders to match your needs'
  },
  {
    id: '3',
    name: 'Get Expert advice',
    descrription: 'Our expert team discusses with you the right option for you'
  },
  // {
  //   id: '3',
  //   name: 'Upload your docs',
  //   descrription: 'Easily upload all required docs online. This means less paperwork!'
  // },
  {
    id: '4',
    name: 'Choose your loan',
    descrription:
      'Our Approval Confidence tech only shows you loans with a high approval likelyhood.'
  },
  {
    id: '5',
    name: 'Settle your loan',
    descrription:
      'Once you submit your application our team will keep you regularly updated on its progress.'
  }
];

export default function CreditApproval() {
  return (
    <div className="py-10 bg-white container mx-auto">
      <div className="grid items-center grid-cols-1 md:grid-cols-2">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <img className="h-full w-full" src="/matches.png" alt="" />
        </div>
        <div className="mx-auto px-4 sm:px-6 lg:px-8 mt-10 md:mt-0">
          <div className="text-center md:text-left">
            <p className="mt-4 text-md text  uppercase font-semibold text-green-700">
              How it works
            </p>
            <p className="mt-2 text-3xl leading-8 text-gray-900 font-bold md:text-6xl">
              Start Your Loan Approval
            </p>
          </div>
          {data.map((datas) => (
            <ol key={datas.id} className="relative border-l m-8 ml-12 md:ml-8 mb-0 border-gray-200">
              <li className="mb-10 ml-6">
                <span className="flex absolute -left-5 justify-center items-center w-8 h-8 bg-blue-600 rounded-full ring-8 ring-white text-white">
                  <h3 className="p-4">{datas.id}</h3>
                </span>
                <h3 className="flex items-center mb-2 text-lg font-semibold text-grey-600">
                  {datas.name}
                </h3>
                <p className="-mb-4 text-base font-normal text-gray-600">{datas.descrription}</p>
              </li>
            </ol>
          ))}
        </div>
      </div>
    </div>
  );
}
