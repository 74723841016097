import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { VEHICLE_CONDITIONS } from '../../utils/car-loans/constants';
import { useProgressBarContext } from '../../contexts/ProgressBarContext';
import { getCarLoanFieldFromLocalStorage, saveCarLoanAppFieldInLocalStorage } from '../../utils';
import SecondaryOptionButton from '../../components/car-loans/buttons/SecondaryOptionButton';

export default function CarConditionPage() {
  const navigate = useNavigate();

  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(3);
  }, []);

  const onOptionClick = (val) => {
    const vehicle = getCarLoanFieldFromLocalStorage('vehicle');
    saveCarLoanAppFieldInLocalStorage('vehicle', { ...vehicle, condition: val });
    navigate('../usage');
  };

  return (
    <>
      <h3 className="application-question">Are you buying a new or used vehicle?</h3>
      <div className="application-form-container">
        {VEHICLE_CONDITIONS.map((type) => (
          <SecondaryOptionButton onOptionClick={onOptionClick} key={type}>
            {type}
          </SecondaryOptionButton>
        ))}
      </div>
    </>
  );
}
