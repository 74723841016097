import { useContext } from 'react';
import { ProgressContext } from '../../App';

export const ProgressBar = () => {
  const { progressPercentage } = useContext(ProgressContext);
  return (
    <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
      <div
        className="bg-blue-600 h-1.5 rounded-full dark:bg-blue-500"
        style={{ width: `${progressPercentage}%` }}></div>
    </div>
  );
};
