import PropTypes from 'prop-types';
/**
 * if we want to show a text in button, but want to pass different value in API calls (similar to option tags),
 *    pass value. Use case could be: rending buttons with icons
 * when value is not passed, calls callback with children
 */
export default function PrimaryOptionButton({ children, onOptionClick, value, ...rest }) {
  const handleClick = () => {
    if (onOptionClick) {
      if (value === null || value === undefined || value === '') {
        return onOptionClick(children);
      }
      onOptionClick(value);
    }
  };
  return (
    <button
      className="text-lg bg-primary-blue text-white font-semibold rounded-lg py-3 w-full hover:shadow-xl"
      onClick={handleClick}
      {...rest}>
      {children}
    </button>
  );
}

PrimaryOptionButton.propTypes = {
  children: PropTypes.node.isRequired,
  onOptionClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object])
};
