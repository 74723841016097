import { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import useSWR from 'swr';
import { fetcher, headers } from '../../utils';
import { API_HOST } from '../../utils/constants';
import axios from 'axios';

const schema = Yup.object().shape({
  relationshipStatus: Yup.string().required('Required')
});

export const Relationship = () => {
  const router = useNavigate();
  const { id: applicationId } = router.query;

  const [primaryApplicant, setPrimaryApplicant] = useState();

  const url = `${API_HOST}/applications`;
  const { data: responseData } = useSWR(applicationId ? `${url}/${applicationId}` : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setPrimaryApplicant(responseData.applicants.find((a) => a.type === 'Primary'));
    }
  }, [responseData]);

  async function onSubmit(values) {
    try {
      await axios.put(`${url}/${applicationId}/applicants/${primaryApplicant._id}`, values, {
        headers
      });
      router(`/applications/${applicationId}/applicants/${primaryApplicant._id}/dependants`);
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  return (
    <div className="w-96 md:w-3/12 px-4 mx-auto">
      <p className="text-center mt-8 text-2xl">What is your relationship status</p>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={schema}
        initialValues={{
          relationshipStatus: primaryApplicant?.relationshipStatus || ''
        }}>
        {({ errors, touched }) => (
          <Form>
            <div className="mt-2 space-y-2">
              <div className="bg-white border border-gray-400 py-3 px-3 rounded-md col-span-1">
                <label>
                  {' '}
                  <Field type="radio" name="relationshipStatus" value="Married" />
                  Married
                </label>
              </div>
              <div className="bg-white border border-gray-400 py-3 px-3 rounded-md col-span-1">
                <label>
                  {' '}
                  <Field type="radio" name="relationshipStatus" value="Single" />
                  Single
                </label>
              </div>
              <div className="bg-white border border-gray-400 py-3 px-3 rounded-md col-span-1">
                <label>
                  {' '}
                  <Field type="radio" name="relationshipStatus" value="De facto" />
                  De facto
                </label>
              </div>
              <div className="bg-white border border-gray-400 py-3 px-3 rounded-md col-span-1">
                <label>
                  {' '}
                  <Field type="radio" name="relationshipStatus" value="Divorced" />
                  Divorced
                </label>
              </div>
              <div className="bg-white border border-gray-400 py-3 px-3 rounded-md col-span-1">
                <label>
                  {' '}
                  <Field type="radio" name="relationshipStatus" value="It's complicated" />
                  {"It's complicated"}
                </label>
              </div>
              {errors.relationshipStatus && touched.relationshipStatus ? (
                <div className="error">{errors.relationshipStatus}</div>
              ) : null}
            </div>
            <button className="common-button mt-14" type="submit">
              Next
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
