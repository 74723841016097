import { Fragment, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, ShieldCheckIcon, ViewGridIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
// import { getToken } from '../../../../../app-portal-react/src/utils';
import { API_HOST } from '../../../utils/constants';
import useSWR from 'swr';
import { brandWebsiteUrl, fetcher } from '../../../utils';

const calculators = [
  {
    name: 'Repayment Calculator',
    description: "Connect with third-party tools that you're already using.",
    href: '/calculators/repayments',
    icon: ViewGridIcon
  }
];

export default function Nav() {
  const [blogs, setBlogs] = useState([]);

  const blogsUrl = `${API_HOST}/blogs?isActive=true`;

  const { data: blogsResponseData } = useSWR(blogsUrl, fetcher);

  useEffect(() => {
    if (blogsResponseData) {
      const { data } = blogsResponseData;
      setBlogs(
        data.map((blog) => ({
          name: blog.name,
          description: 'Understand how we take your privacy seriously.',
          href: `/blogs/${blog.slug}`,
          icon: ShieldCheckIcon
        }))
      );
    }
  }, [blogsResponseData]);

  return (
    <Popover className="relative bg-white">
      {({ open }) => (
        <>
          <div className="container mx-auto sm:px-6">
            <div className="flex justify-between items-center border-b-2 border-gray-100 py-4 md:justify-start md:space-x-10 mx-4 md:mx-0">
              <div className="md:w-1/3">
                <a href={brandWebsiteUrl} className="my-2">
                  <img src="/logo.svg" alt="Loanspad" width={150} className="block" />
                </a>
              </div>

              <div className="-mr-2 -my-2 md:hidden ">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>
          {/* Mobile menu starts here */}
          {open && (
            <Transition
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Popover.Panel
                static
                focus
                className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="-my-2 px-4 py-4">
                    <div className="flex items-center justify-between">
                      <div>
                        <img className="h-8 w-auto" src="/logo.svg" width={150} alt="Loanspad" />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6 py-3">
                      <nav className="grid gap-y-8">
                        {calculators.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                            <item.icon
                              className="flex-shrink-0 h-6 w-6 text-blue-600"
                              aria-hidden="true"
                            />
                            <span className="ml-3 text-base font-medium text-gray-900">
                              {item.name}
                            </span>
                          </a>
                        ))}
                        {blogs.map((item) => (
                          <Link key={item.slug} to={`/blogs/${item.slug}`}>
                            Blogs &rarr; {item.name}
                          </Link>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          )}
        </>
      )}
    </Popover>
  );
}
