import { Field, Form, Formik } from 'formik';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProgressContext } from '../App';
import { getFromLocalStorage, saveApplicantFieldInLocalStorage } from '../utils';
import { LOAN_APP_TYPE } from '../utils/constants';

export const ApplicantName = () => {
  const router = useNavigate();

  const { setProgressPercentage } = useContext(ProgressContext);

  useEffect(() => {
    setProgressPercentage(95);
  }, []);

  function validateAmount(value) {
    let error;
    if (!value) {
      error = 'Required';
    }
    return error;
  }

  async function onSubmit(values) {
    const user = window.localStorage.getItem('user');
    if (user) {
      const application = getFromLocalStorage(LOAN_APP_TYPE.HomeLoan);
      application.source = '';
      application.leadCreationSource = 'Funnel';
      application.applicants[0].mobile = values.mobile;

      try {
        // await axios.post(`${API_HOST}/leads`, application, { headers });
      } catch (e) {
        console.log(e);
      }
      router('/dashboard');
    } else {
      saveApplicantFieldInLocalStorage(
        LOAN_APP_TYPE.HomeLoan,
        'firstName',
        values.firstName,
        router,
        '/signup'
      );
    }
  }

  return (
    <>
      <p className="text-center mt-8 text-2xl px-2">One more thing, what is your first name?</p>

      <div className="mt-6 px-3 w-96 mx-auto">
        <Formik validateOnBlur initialValues={{ firstName: '' }} onSubmit={onSubmit}>
          {() => (
            <Form>
              <Field name="firstName" validate={validateAmount}>
                {({
                  field, // { name, value, onChange, onBlur }
                  meta
                }) => (
                  <div>
                    <input
                      type="text"
                      placeholder="Type your first name here"
                      className="common-input-text"
                      {...field}
                    />
                    {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                  </div>
                )}
              </Field>
              <p className="py-4">
                <button type="submit" className="common-button">
                  Next
                </button>
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
