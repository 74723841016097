import Faq from '../components/Faq';
import Rating from '../components/Rating';

const starrating = [
  {
    id: 1,
    rating: (
      <svg className="w-24" role="img" viewBox="0 0 251 46" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fill="#16b831" d="M0 46.330002h46.375586V0H0z"></path>
          <path
            fill="#16b831"
            d="M39.533936 19.711433L13.230239 38.80065l3.838216-11.797827L7.02115 19.711433h12.418975l3.837417-11.798624 3.837418 11.798624h12.418975zM23.2785 31.510075l7.183595-1.509576 2.862114 8.800152L23.2785 31.510075z"></path>
        </g>
        <g>
          <path fill="#16b831" d="M51.24816 46.330002h46.375587V0H51.248161z"></path>
          <path fill="#16b831" d="M51.24816 46.330002h23.187793V0H51.248161z"></path>
          <path
            d="M74.990978 31.32991L81.150908 30 84 39l-9.660206-7.202786L64.30279 39l3.895636-11.840666L58 19.841466h12.605577L74.499595 8l3.895637 11.841466H91L74.990978 31.329909z"
            fill="#FFF"></path>
        </g>
        <g>
          <path fill="#16b831" d="M102.532209 46.330002h46.375586V0h-46.375586z"></path>
          <path fill="#16b831" d="M102.532209 46.330002h23.187793V0h-23.187793z"></path>
          <path
            d="M142.066994 19.711433L115.763298 38.80065l3.838215-11.797827-10.047304-7.291391h12.418975l3.837418-11.798624 3.837417 11.798624h12.418975zM125.81156 31.510075l7.183595-1.509576 2.862113 8.800152-10.045708-7.290576z"
            fill="#FFF"></path>
        </g>
        <g>
          <path fill="#16b831" d="M153.815458 46.330002h46.375586V0h-46.375586z"></path>
          <path fill="#16b831" d="M153.815458 46.330002h23.187793V0h-23.187793z"></path>
          <path
            className="tp-star__shape"
            d="M193.348355 19.711433L167.045457 38.80065l3.837417-11.797827-10.047303-7.291391h12.418974l3.837418-11.798624 3.837418 11.798624h12.418974zM177.09292 31.510075l7.183595-1.509576 2.862114 8.800152-10.045709-7.290576z"
            fill="#FFF"></path>
        </g>
        <g>
          <path fill="#16b831" d="M205.064416 46.330002h46.375587V0h-46.375587z"></path>
          <path fill="#16b831" d="M205.064416 46.330002h23.187793V0h-23.187793z"></path>
          <path
            d="M244.597022 19.711433l-26.3029 19.089218 3.837419-11.797827-10.047304-7.291391h12.418974l3.837418-11.798624 3.837418 11.798624h12.418975zm-16.255436 11.798642l7.183595-1.509576 2.862114 8.800152-10.045709-7.290576z"
            fill="#FFF"></path>
        </g>
      </svg>
    )
  }
];
export const Works = () => {
  return (
    <>
      <div className="container mx-auto py-12 xl:py-24">
        <div className="w-11/12 xl:w-3/6 mx-auto text-center">
          <h2 className="text-5xl font-medium">How it works</h2>
          <p className="my-6 leading-7">
            Car financing can be a complicated process, but we&apos;ve got you covered. Through our
            simple step by step process and dedicated support team, we do all the hard work to get
            you approved and driving away in the car of your choice.
          </p>
          <a
            href="!#"
            className="bg-blue-700 text-white text-md rounded-full py-6 px-14 mt-4 mb-2 inline-block tracking-wide">
            Get my loan options
          </a>
          <p className="font-light text-sm">No impact on your credit score</p>
          <div className="font-medium mt-6">
            <span>Execllent</span>
            <a href="!#" className="inline-block align-middle mx-4">
              {starrating[0].rating}
            </a>
          </div>
        </div>
      </div>
      <div className="relative container mx-auto">
        {/* How it Works Wave Section Start */}
        <div className="cc-section pb-32 how-it-works-wave wf-section">
          <div className="cc-container wider">
            <div className="cc-wave-holder">
              <img src="/wave-line.png" alt="Wave Line" className="cc-wave-img" />
              <a href="#step-1-section" className="cc-steps-item w-inline-block">
                <div>1</div>
                <div className="cc-steps-item-note">Get matched &amp; compare lenders</div>
              </a>
              <a href="#step-2-section" className="cc-steps-item step2 w-inline-block">
                <div>2</div>
                <div className="cc-steps-item-note step2">Decide on the right car</div>
              </a>
              <a href="#step-3-section" className="cc-steps-item step3 w-inline-block">
                <div>3</div>
                <div className="cc-steps-item-note step3">We do all the checks</div>
              </a>
              <a href="#step-4-section" className="cc-steps-item step4 w-inline-block">
                <div>4</div>
                <div className="cc-steps-item-note step4">Get approved</div>
              </a>
              <a href="#step-5-section" className="cc-step5 w-inline-block">
                <div>
                  5<br />
                  Drive away
                </div>
              </a>
              <div className="cc-steps-dasheline"></div>
            </div>
          </div>
        </div>
        {/* How it Works Wave Section End */}
        <div className="space-y-6 xl:space-y-16 mb-16">
          {/* Step 1 Start */}
          <div className="cc-container wider gap-14" id="step-1-section">
            <div className="grid grid-cols-1 xl:grid-cols-2">
              <div className="bg-gray-50 place-content-center flex py-9">
                <img src="/step-1.svg" alt="Step 1" className="w-4/6 mx-auto" />
              </div>
              <div className="p-10 xl:p-20">
                <p>Step 1</p>
                <h2 className="text-4xl my-4 text-gray-600 font-medium">
                  Get matched & compare lenders
                </h2>
                <p className="text-sm leading-7 text-gray-600 font-light">
                  Simply fill in some details about you, the car and the loan to get matched to the
                  top 3 suitable lenders. You can view details such as loan interest, comparison
                  rates, loan features and fees all on the one dashboard.
                </p>
              </div>
            </div>
          </div>
          {/* Step 1 End */}
          {/* Step 2 Start */}
          <div className="cc-container wider gap-14" id="step-2-section">
            <div className="grid grid-cols-1 xl:grid-cols-2">
              <div className="bg-gray-50 place-content-center flex py-9">
                <img src="/step-2.svg" alt="Step 2" className="w-4/6 mx-auto" />
              </div>
              <div className="p-10 xl:p-20">
                <p>Step 2</p>
                <h2 className="text-4xl my-4 text-gray-600 font-medium">Decide on the right car</h2>
                <p className="text-sm leading-7 text-gray-600 font-light">
                  Choose a car from any reputable dealer or a private seller that is right for you.
                  Should you need help finding the right car, our dedicated support team are here to
                  help.
                </p>
              </div>
            </div>
          </div>
          {/* Step 2 End */}
          {/* Step 3 Start */}
          <div className="cc-container wider gap-14" id="step-3-section">
            <div className="grid grid-cols-1 xl:grid-cols-2">
              <div className="bg-gray-50 place-content-center flex py-9">
                <img src="/step-3.svg" alt="Step 3" className="w-4/6 mx-auto" />
              </div>
              <div className="p-10 xl:p-20">
                <p>Step 3</p>
                <h2 className="text-4xl my-4 text-gray-600 font-medium">We do all the checks</h2>
                <p className="text-sm leading-7 text-gray-600 font-light">
                  Once you’ve found the right car, we do the rest of the groundwork and carry out
                  all the checks – so you can move forward with peace of mind and buy with
                  confidence.
                </p>
              </div>
            </div>
          </div>
          {/* Step 3 End */}
          {/* Step 4 Start */}
          <div className="cc-container wider gap-14" id="step-4-section">
            <div className="grid grid-cols-1 xl:grid-cols-2">
              <div className="bg-gray-50 place-content-center flex py-9">
                <img src="/step-4.svg" alt="Step 4" className="w-4/6 mx-auto" />
              </div>
              <div className="p-10 xl:p-20">
                <p>Step 4</p>
                <h2 className="text-4xl my-4 text-gray-600 font-medium">Get approved</h2>
                <p className="text-sm leading-7 text-gray-600 font-light">
                  Now that you have the right car and all the checks are completed, the application
                  process is finalised. Congratulations! It’s time to receive your approved loan
                  offer.
                </p>
              </div>
            </div>
          </div>
          {/* Step 4 End */}
          {/* Step 5 Start */}
          <div className="cc-container wider gap-14" id="step-5-section">
            <div className="grid grid-cols-1 xl:grid-cols-2">
              <div className="bg-gray-50 place-content-center flex py-9">
                <img src="/step-5.svg" alt="Step 5" className="w-4/6 mx-auto" />
              </div>
              <div className="p-10 xl:p-20">
                <p>Step 5</p>
                <h2 className="text-4xl my-4 text-gray-600 font-medium">Drive away</h2>
                <p className="text-sm leading-7 text-gray-600 font-light">
                  You’ve now reviewed the loan offer and are happy to move forward. Sign the
                  documents and drive away. It’s that easy.
                </p>
              </div>
            </div>
          </div>
          {/* Step 5 End */}
        </div>
      </div>
      <div className="bg-gray-800 py-24">
        <div className="container mx-auto">
          <h2 className="text-white text-center font-semibold text-5xl">
            Rated Excellent on Loanspad
          </h2>
          <div className="px-10 mt-10">
            <Rating />
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <h2></h2>
        <Faq />
      </div>
      <div className="container mx-auto py-24 bg-gray-100">
        <div className="bg-white p-10 border border-1 mx-4 leading-7  text-sm font-light">
          <h3 className="text-xl font-semibold text-gray-600">Things you should know</h3>
          <p className="mt-5 mb-8">Test 2 - on page</p>
          <p>
            CarClarity is an online financial comparison service and is owned and operated by
            CarClarity ACL Pty Ltd ABN 50606 243 243 / ACN 606 243243 and Australian Credit License
            478 874.
          </p>
          <p>
            CarClarity provides you access to our expert team who can assist in selecting suitable
            car loans from our panel of 30+ lenders and over 1000 financial services products. The
            financial products compared on this website do not necessarily compare all features that
            may be relevant to you and CarClarity does not compare all rates in market.
          </p>
          <p>
            All applications are subject to the credit provider’s credit assessment and loan
            eligibility criteria. Terms, conditions, fees and charges apply. Information provided is
            factual information only, and is not intended to imply any recommendation about any
            financial product(s) or constitute tax advice. If you require financial or tax advice
            you should consult a licensed financial or tax adviser. CarClarity may receive a
            commission from lenders were commend.
          </p>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 w-4/6 mx-auto mt-24 space-y-4 items-center">
          <div className="mb-10 xl:mb-0">
            <img src="/contact-img.svg" className="w-4/6 mx-auto" />
          </div>
          <div>
            <h4 className="text-2xl font-semibold text-gray-600">
              Get free expert advice on financing your next vehicle
            </h4>
            <p className="my-6 text-sm font-light leading-6">
              Our experts will help you figure out the cheapest new car loans available for your
              financial situation.
            </p>
            <a
              href="mailto:info@loanspad.com"
              className="border border-1 border-blue-700 hover:bg-blue-700 text-blue-700 hover:text-white transition inline-block py-3 px-6 rounded-full">
              Message Us
            </a>
            <a href="tel:1300 323 181" className="text-blue-700 ml-6">
              {' '}
              1300 323 181
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
