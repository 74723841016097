import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { fetcher, headers, extractNumeric } from '../../../utils';
import { API_HOST } from '../../../utils/constants';
import axios from 'axios';

import CurrencyInput from '../../common/CurrencyInput';

const schema = Yup.object().shape({
  make: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  model: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  year: Yup.number()
    .min(1900, 'Too Old!')
    .max(new Date().getFullYear(), 'Cannot be in future')
    .required('Required'),
  value: Yup.string().required('Required'),
  hasLiability: Yup.string().required('Required')
});

export const Vehicle = () => {
  const router = useNavigate();
  const { id: applicationId } = router.query;

  const [, setApplication] = useState([]);
  const [primaryApplicant, setPrimaryApplicant] = useState();
  const [, setProperty] = useState();
  const [valSchema, setValSchema] = useState(schema);

  const url = `${API_HOST}/applications`;
  const { data: responseData } = useSWR(applicationId ? `${url}/${applicationId}` : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setApplication(responseData);
      setProperty(responseData.properties[0]);
      setPrimaryApplicant(responseData.applicants.find((a) => a.type === 'Primary'));
    }
  }, [responseData]);

  const onSubmit = async (values) => {
    const vals = {
      ...values,
      type: 'Motor Vehicle',
      value: extractNumeric(values.value),
      estimateBasis: 'Self'
    };

    try {
      await axios.post(`${url}/${applicationId}/assets`, vals, { headers });
      router(`/applications/${applicationId}/applicants/${primaryApplicant._id}/employments`);
    } catch (error) {
      console.log(error);
    }
  };

  const getYearsOptions = () => {
    const els = [];
    for (let i = new Date().getFullYear(); i >= 1900; i--) {
      els.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return els;
  };

  const onSkip = () => {
    setValSchema();
    router(`/applications/${applicationId}/applicants/${primaryApplicant._id}/employments`);
  };

  return (
    <>
      <p className="text-center mt-8 text-2xl">Assets - Vehicle</p>
      <div className="mx-auto px-4 md:w-3/12">
        <div className="mt-10 mx-auto">
          <Formik
            enableReinitialize
            initialValues={{
              make: '',
              model: '',
              year: '',
              value: '',
              hasLiability: ''
            }}
            onSubmit={onSubmit}
            validationSchema={valSchema}>
            {({ values, errors, touched }) => (
              <Form>
                <div className="mt-4">
                  <label>Who is the Manufacturer of Vehicle?</label>
                  <Field name="make" className="form-input px-4 py-3 rounded-md w-full" />
                  {errors.make && touched.make ? <div className="error">{errors.make}</div> : null}
                </div>
                <div className="mt-4">
                  <label>What is the Model of vehicle?</label>
                  <Field name="model" className="form-input px-4 py-3 rounded-md w-full" />
                  {errors.model && touched.model ? (
                    <div className="error">{errors.model}</div>
                  ) : null}
                </div>
                <div className="mt-4">
                  <label>Which Year this vehicle was manufactured?</label>
                  <Field as="select" name="year" className="form-input px-4 py-3 rounded-md w-full">
                    {getYearsOptions()}
                  </Field>

                  {errors.year && touched.year ? <div className="error">{errors.year}</div> : null}
                </div>
                <div className="mt-4">
                  <label>Approximately whats the value of this vehicle?</label>
                  <Field name="value" className="form-input px-4 py-3 rounded-md w-full">
                    {({ field }) => (
                      <>
                        <CurrencyInput
                          placeholder="$0"
                          type="text"
                          className="common-input-text"
                          {...field}
                        />
                        {errors.value && touched.value ? (
                          <div className="error">{errors.value}</div>
                        ) : null}
                      </>
                    )}
                  </Field>
                </div>
                <div className="mt-10">
                  <label>Do you owe money on this vehicle?</label>
                  <div className="grid grid-cols-2 space-x-2 mt-2">
                    <div className="bg-white border border-gray-400 py-3 px-3 rounded-md col-span-1">
                      <label>
                        {' '}
                        <Field type="radio" name="hasLiability" value="true" />
                        Yes
                      </label>
                    </div>
                    <div className="bg-white border border-gray-400 py-3 px-3 rounded-md col-span-1">
                      <label>
                        {' '}
                        <Field type="radio" name="hasLiability" value="false" />
                        No
                      </label>
                    </div>
                    {errors.hasLiability && touched.hasLiability ? (
                      <div className="error">{errors.hasLiability}</div>
                    ) : null}
                  </div>
                </div>

                {values.hasLiability === 'true' ? (
                  <>
                    <div className="mt-4">
                      <label>How much do you owe?</label>
                      <Field
                        name="outstandingAmount"
                        className="form-input px-4 py-3 rounded-md w-full">
                        {({ field, meta }) => (
                          <>
                            <CurrencyInput
                              placeholder="$0"
                              type="text"
                              className="common-input-text"
                              {...field}
                            />
                            {meta.touched && meta.error && (
                              <div className="error">{meta.error}</div>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="mt-4">
                      <label>Who is the lender?</label>
                      <Field name="lender" className="form-input px-4 py-3 rounded-md w-full" />
                      {errors.lender && touched.lender ? (
                        <div className="error">{errors.lender}</div>
                      ) : null}
                    </div>
                    <div className="mt-4">
                      <label>How much do yo repay?</label>
                      <div className="grid grid-cols-2 space-x-2">
                        <div>
                          <Field
                            name="installmentAmount"
                            className="form-input px-4 py-3 rounded-md w-full">
                            {({ field }) => (
                              <>
                                <CurrencyInput
                                  placeholder="$0"
                                  type="text"
                                  className="common-input-text"
                                  {...field}
                                />
                                {errors.installmentAmount && touched.installmentAmount ? (
                                  <div className="error">{errors.installmentAmount}</div>
                                ) : null}
                              </>
                            )}
                          </Field>
                        </div>
                        <div>
                          <Field
                            as="select"
                            name="installmentFrequency"
                            className="form-input mt-3 px-4 py-3 rounded-md w-full">
                            <option></option>
                            <option value={'Monthly'}>Monthly</option>
                            <option value={'Fortnightly'}>Fortnightly</option>
                            <option value={'Weekly'}>Weekly</option>
                          </Field>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                <button
                  type="button"
                  className="py-8 mt-10 common-radio-rec-ghost-btn"
                  onClick={onSkip}>
                  {"I don't have any, skip it"}{' '}
                </button>
                <button type="submit" className="py-8 mt-5 common-button">
                  Next{' '}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
