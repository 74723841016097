import Nav from '../components/common/home/Nav';
import { Blog } from '../components/Blog';
import Footer from '../components/common/home/Footer';
import Regualtions from '../components/common/home/Regualtions';

function BlogPage() {
  return (
    <div>
      <Nav />
      <Blog />
      <Footer />
      <Regualtions />
    </div>
  );
}

export default BlogPage;
