import { useNavigate } from 'react-router-dom';
import { VEHICLE_TYPES } from '../../utils/car-loans/constants';

import { useProgressBarContext } from '../../contexts/ProgressBarContext';
import { useEffect } from 'react';
import { DOMAIN } from '../../utils/constants';
import { getCarLoanFieldFromLocalStorage, saveCarLoanAppFieldInLocalStorage } from '../../utils';
import SecondaryOptionButton from '../../components/car-loans/buttons/SecondaryOptionButton';

export default function VehicleTypePage() {
  const navigate = useNavigate();

  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(1);
    //move below code to onClick handler of landing page > Start car loan button
    saveCarLoanAppFieldInLocalStorage('source', 'website');
    saveCarLoanAppFieldInLocalStorage('type', 'Finance');
    saveCarLoanAppFieldInLocalStorage('loanTermYears', 7);
    saveCarLoanAppFieldInLocalStorage('domain', DOMAIN.Car);
    saveCarLoanAppFieldInLocalStorage('numOfApplicants', 1);
  }, []);

  const onOptionClick = (val) => {
    const vehicle = getCarLoanFieldFromLocalStorage('vehicle');
    saveCarLoanAppFieldInLocalStorage('vehicle', { ...vehicle, type: val });
    navigate('../select-vehicle');
  };

  return (
    <div>
      <h3 className="application-question">Which vehicle type are you interested in?</h3>
      <div className="application-form-container">
        {VEHICLE_TYPES.map((type) => (
          <SecondaryOptionButton onOptionClick={onOptionClick} key={type}>
            {type}
          </SecondaryOptionButton>
        ))}
      </div>
    </div>
  );
}
