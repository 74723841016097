import { Field, Form, Formik } from 'formik';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProgressContext } from '../App';
import { extractNumeric, getFromLocalStorage, saveAppFieldInLocalStorage } from '../utils';
import CurrencyInput from './common/CurrencyInput';
import { LOAN_APP_TYPE } from '../utils/constants';

export const Deposit = () => {
  const router = useNavigate();

  const { setProgressPercentage } = useContext(ProgressContext);

  useEffect(() => {
    setProgressPercentage(20);
  }, []);

  function validateOnBlur(value) {
    let error;
    const application = getFromLocalStorage(LOAN_APP_TYPE.HomeLoan);

    if (!value) {
      error = 'Required';
    } else if (+extractNumeric(value) >= application.purchasePrice) {
      error = `Deposit amount cannot be more than purchase price ${application.purchasePrice}`;
    }
    return error;
  }

  function onSubmit(values) {
    saveAppFieldInLocalStorage(
      LOAN_APP_TYPE.HomeLoan,
      'depositAmount',
      extractNumeric(values.depositAmount),
      router,
      '/home-loans/finance/buying-situation'
    );
    return false;
  }

  return (
    <div className="px-4 mx-auto">
      <p className="pt-8 text-2xl text-center ">How much deposit do you have?</p>
      <div className="mt-6 px-3 w-96 mx-auto">
        <Formik validateOnBlur initialValues={{ depositAmount: '' }} onSubmit={onSubmit}>
          {() => (
            <Form>
              <Field name="depositAmount" validate={validateOnBlur}>
                {({
                  field, // { name, value, onChange, onBlur }
                  meta
                }) => (
                  <div>
                    <CurrencyInput
                      placeholder="$0"
                      type="text"
                      className="common-input-text"
                      {...field}
                    />
                    {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                  </div>
                )}
              </Field>
              <p className="py-4">
                <button type="submit" className="common-button">
                  Next
                </button>
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
