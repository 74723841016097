import { LOAN_APP_TYPE } from './constants';
export const brandWebsiteUrl = process.env.REACT_APP_BRAND_WEBSITE_URL;

export const toCurrency = (number, maximumFractionDigits = 0) =>
  new Intl.NumberFormat('en-AU', {
    maximumFractionDigits,
    style: 'currency',
    currency: 'AUD',
    currencyDisplay: 'narrowSymbol'
  }).format(number);

export function extractNumeric(text) {
  return +text.replace(/\D+/g, '');
}

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export function isJsonParsable(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const getLocalStorageData = () => {
  const loanspadStr = window.localStorage.getItem('loanspad');
  let loanspad;
  if (isJsonParsable(loanspadStr)) {
    loanspad = JSON.parse(loanspadStr);
  }
  return loanspad;
};

export const setLocalStorageData = (field, value) => {
  let loanspad = window.localStorage.getItem('loanspad');
  loanspad = loanspad ? JSON.parse(loanspad) : {};
  loanspad[field] = value;
  window.localStorage.setItem('loanspad', JSON.stringify(loanspad));
};

export const getApplicantFullName = (applicant = null) => {
  if (!applicant) return '';

  const { firstName, middleName, lastName } = applicant;
  return [firstName, middleName, lastName].join(' ');
};

export const getTotalDuration = (applicant, arrayElementName) => {
  let yearsDuration = 0;
  let monthsDuration = 0;
  applicant[arrayElementName].forEach((element) => {
    yearsDuration += element.yearsDuration;
    monthsDuration += element.monthsDuration;
  });
  if (monthsDuration > 11) {
    monthsDuration -= 12;
    yearsDuration++;
  }
  return { yearsDuration, monthsDuration };
};

export function clearExistingApplicationInLocalStorate() {
  window.localStorage.removeItem('application');
}

export function saveAppFieldInLocalStorage(domain, field, value, navigate, redirectUrl = null) {
  let loanspad = window.localStorage.getItem('loanspad');
  loanspad = loanspad ? JSON.parse(loanspad) : { [domain]: { applicants: [{}] } };
  const application = loanspad[domain] || { applicants: [{}] };
  application[field] = value;
  loanspad[domain] = application;
  window.localStorage.setItem('loanspad', JSON.stringify(loanspad));
  if (navigate) {
    navigate(redirectUrl);
  }
}

export function saveCarLoanAppFieldInLocalStorage(...rest) {
  saveAppFieldInLocalStorage(LOAN_APP_TYPE.CarLoan, ...rest);
}

export function saveApplicantFieldInLocalStorage(domain, field, value, navigate, redirectUrl) {
  let loanspad = window.localStorage.getItem('loanspad');
  loanspad = loanspad ? JSON.parse(loanspad) : { [domain]: { applicants: [{}] } };
  const application = loanspad[domain];

  application.applicants[0][field] = value;
  loanspad[domain] = application;
  window.localStorage.setItem('loanspad', JSON.stringify(loanspad));
  if (navigate) {
    navigate(redirectUrl, { replace: true });
  }
}

export function getFromLocalStorage(domain, field) {
  let loanspad = window.localStorage.getItem('loanspad');
  loanspad = loanspad ? JSON.parse(loanspad) : { [domain]: { applicants: [{}] } };
  const application = loanspad[domain];

  if (field) {
    return application[field];
  }
  return application;
}

export function getCarLoanFieldFromLocalStorage(...rest) {
  return getFromLocalStorage(LOAN_APP_TYPE.CarLoan, ...rest);
}

export const clearAppFromStorage = (domain) => {
  let loanspad = window.localStorage.getItem('loanspad');
  const application = { applicants: [{}] };
  loanspad[domain] = application;
  window.localStorage.setItem('loanspad', JSON.stringify(loanspad));
};

export const clearStorage = () => {
  window.localStorage.removeItem('loanspad');
};

export const token = getFromLocalStorage('token');

export const headers = { Authorization: `Bearer ${token}` };
export const fetcher = (url) =>
  fetch(url, { headers }).then((r) => {
    if (r.status === 401) {
      clearStorage();
    }
    return r.json();
  });

export const currency = (amount) =>
  Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0
  }).format(amount);

export const getLoggedInUser = () => {
  const loanspad = getLocalStorageData();
  if (!loanspad || !isJsonParsable(loanspad.user)) return null;
  return JSON.parse(loanspad.user);
};
