import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import { fetcher, headers } from '../../utils';
import { API_HOST, APPLICANT_PRIMARY } from '../../utils/constants';
import axios from 'axios';
export const EConsent = () => {
  const router = useNavigate();
  const { id: applicationId } = router.query;
  const [primaryApplicant, setPrimaryApplicant] = useState();
  const [isDisabled, setIsDisabled] = useState(true);

  const url = `${API_HOST}/applications`;
  const { data: responseData } = useSWR(applicationId ? `${url}/${applicationId}` : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setPrimaryApplicant(responseData.applicants.find((a) => a.type === APPLICANT_PRIMARY));
    }
  }, [responseData]);

  const onSubmit = async () => {
    try {
      await axios.put(
        `${url}/${applicationId}/applicants/${primaryApplicant._id}`,
        { isEConsented: !isDisabled },
        { headers }
      );
      router(`/applications/${applicationId}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <p className="text-center mt-8 text-2xl">
        Please read our privacy policy and e-consent to proceed
      </p>
      <div className="text-center mt-8 text-sm w-4/12 mx-auto">
        <p>
          By submitting your personal information, you consent to Lendi dealing with any information
          you provide in accordance with our privacy policy(opens in new window), which contains
          information about how we treat your personal information and how you may access and
          correct your personal information, how you may complain and how you may contact the credit
          reporting body we use. Our policy also tells you what happens if you fail to meet your
          repayments or commit a serious credit infringement, what happens if you believe you may
          have been a victim of fraud, and other notifiable matters. Lendi may exchange your
          personal information with Equifax for the purposes of obtaining a credit report about you
          to assess your application for finance and assess your creditworthiness. If you do not
          consent to our use of your information, we may not be able to proceed with your
          application.
        </p>
        <p className="mt-5">
          By clicking {"'I agree'"}, I consent to receiving notices and other documents from you by
          email to my address provided, and understand that if I git this consent: a) you may no
          longer send me paper copies of notices and other documents; b) I should regularly check my
          email address for documents; and c) I may withdraw my consent to receiving documents by
          email at any time.
        </p>
        <p className="mt-5">
          I confirm that I have the facilities to print any notice or document you send me by email,
          if desired
        </p>
      </div>
      <div className="mx-auto px-4 md:w-3/12">
        <div className="mt-10 mx-auto">
          <input type={'checkbox'} onChange={(e) => setIsDisabled(!e.target.checked)} /> I Agree
          <p className="py-8 mt-1">
            <button onClick={onSubmit} disabled={isDisabled} className="common-button">
              Next
            </button>
          </p>
        </div>
      </div>
    </>
  );
};
