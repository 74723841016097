import BlogCard from './BlogCard';
import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { API_HOST } from '../utils/constants';
import { fetcher } from '../utils';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

export const Blog = () => {
  const [blog, setBlog] = useState();
  const [blogPosts, setBlogPosts] = useState([]);
  const params = useParams();
  const { slug } = params;

  const blogsUrl = `${API_HOST}/blogs/${slug}`;

  const { data: responseData } = useSWR(slug ? blogsUrl : null, fetcher);

  async function fetchBlogPosts(blogId) {
    const { data: blogPostsResponse } = await axios.get(
      `${API_HOST}/blog-posts?blog=${blogId}&isActive=true`
    );
    setBlogPosts(blogPostsResponse.data);
  }

  useEffect(() => {
    if (responseData) {
      setBlog(responseData);
      fetchBlogPosts(responseData._id);
    }
  }, [responseData]);

  return (
    <>
      <Helmet>
        <title>{blog?.metaTitle ? `${blog?.metaTitle} - Loanspad` : 'Loanspad'} </title>
        <meta name="description" content={blog?.metaDescription} />
        <meta name="keywords" content={blog?.metaKeywords?.join(',')} />
        <meta property="og:image" content={blog?.imgUrl} />
        <meta property="og:title" content={blog?.metaTitle} />
        <meta property="og:description" content={blog?.metaDescription} />
      </Helmet>

      <div className="bg-gray-100">
        <div className="w-full">
          <div className="blog-hero-section">
            <div className="grid items-center grid-cols-1 border-b-2">
              <div className="mx-auto hero-img py-20">
                <img src="/blog.jpg" className="absolute blog-img" />
                <div className="container mx-auto">
                  <div className="grey-overlay"></div>
                  <div className="blog-hero-content">
                    <h2 className="text-2xl md:text-4xl leading-8 text-white font-bold">
                      {blog?.name}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto">
          <div className="mt-10 pb-10 lg:mx-2 mx-5">
            <div className="grid lg:grid-cols-3 md:grid-cols-none sm:grid-cols-none gap-7">
              {blogPosts.map((cval) => (
                <BlogCard key={cval._id} blogPost={cval} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
