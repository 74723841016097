import { useNavigate } from 'react-router-dom';
import { RESIDENCY_STATUSES } from '../../utils/car-loans/constants';
import { useProgressBarContext } from '../../contexts/ProgressBarContext';
import { useEffect } from 'react';
import { saveApplicantFieldInLocalStorage } from '../../utils';
import { LOAN_APP_TYPE } from '../../utils/constants';
import SecondaryOptionButton from '../../components/car-loans/buttons/SecondaryOptionButton';

export default function ResidencyPage() {
  const navigate = useNavigate();

  const { setCurrentStep } = useProgressBarContext();

  useEffect(() => {
    setCurrentStep(8);
  }, []);

  const onOptionClick = (val) => {
    saveApplicantFieldInLocalStorage(LOAN_APP_TYPE.CarLoan, 'residencyStatus', val);
    navigate('../age-eligibility');
  };
  return (
    <div>
      <h3 className="application-question">What is your residency status?</h3>
      <div className="application-form-container">
        {RESIDENCY_STATUSES.map((type) => (
          <SecondaryOptionButton onOptionClick={onOptionClick} key={type}>
            {type}
          </SecondaryOptionButton>
        ))}
      </div>
    </div>
  );
}
