import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProgressContext } from '../App';
import { saveAppFieldInLocalStorage } from '../utils';
import {
  LOAN_APP_TYPE,
  REFINANCE_REASON_BUILD_ON_MY_LAND,
  REFINANCE_REASON_CONSOLIDATE_DEBT,
  REFINANCE_REASON_DECREASE_REPAYMENTS,
  REFINANCE_REASON_PAYOFF_LOAN_FASTER
} from '../utils/constants';

export const ReasonForRefinance = () => {
  const router = useNavigate();
  const location = useLocation();
  const [, , mode] = location.pathname.split('/');

  const { setProgressPercentage } = useContext(ProgressContext);

  useEffect(() => {
    setProgressPercentage(40);
  }, []);

  const setValue = (val) => {
    saveAppFieldInLocalStorage(
      LOAN_APP_TYPE.HomeLoan,
      'refinanceReason',
      val,
      router,
      `/home-loans/${mode}/property-use`
    );
  };

  return (
    <>
      <p className="text-center text-2xl">What is your reason for refinance?</p>
      <div className="mt-6 px-3 w-96 mx-auto">
        <p className="py-4">
          <button
            onClick={() => setValue(REFINANCE_REASON_DECREASE_REPAYMENTS)}
            className="common-radio-rec-ghost-btn">
            {REFINANCE_REASON_DECREASE_REPAYMENTS}
          </button>
        </p>
        <p className="py-4">
          <button
            onClick={() => setValue(REFINANCE_REASON_PAYOFF_LOAN_FASTER)}
            className="common-radio-rec-ghost-btn">
            {REFINANCE_REASON_PAYOFF_LOAN_FASTER}
          </button>
        </p>
        <p className="py-4">
          <button
            onClick={() => setValue(REFINANCE_REASON_CONSOLIDATE_DEBT)}
            className="common-radio-rec-ghost-btn">
            {REFINANCE_REASON_CONSOLIDATE_DEBT}
          </button>
        </p>
        <p className="py-4">
          <button
            onClick={() => setValue(REFINANCE_REASON_BUILD_ON_MY_LAND)}
            className="common-radio-rec-ghost-btn">
            {REFINANCE_REASON_BUILD_ON_MY_LAND}
          </button>
        </p>
      </div>
    </>
  );
};
