import '../styles/global.css';
import 'remixicon/fonts/remixicon.css';

import Nav from './common/home/Nav';
import Hero from './common/home/Hero';
import Help from './common/home/Help';
import Footer from './common/home/Footer';
import Regulations from './common/home/Regualtions';
import CreditApproval from './common/home/CreditApproval';
import ChooseUs from './common/home/ChooseUs';
import HeroPoints from './common/home/HeroPoints';
import CompareLenders from './common/home/CompareLenders';
import BottomBanner from './common/home/BottomBanner';
import Testimonials from './Testimonials';

function Home() {
  return (
    <div className="">
      <Nav />
      <Hero />
      <HeroPoints />
      <CreditApproval />
      <CompareLenders />
      <ChooseUs />
      <Testimonials />
      <BottomBanner />
      {/* <Reviews /> */}
      <Help />
      <Footer />
      <Regulations />
    </div>
  );
}

export default Home;
