import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { API_HOST, APPLICATION_TYPE_FINANCE, DOMAIN } from '../utils/constants';
import { useEffect, useState } from 'react';
import { fetcher, parseJwt, token, currency } from '../utils';
import dayjs from 'dayjs';
import { HomeFilled, CarFilled } from '@ant-design/icons';

export const Dashboard = () => {
  const [applications, setApplications] = useState(null);
  const url = `${API_HOST}/applications`;
  const { data: responseData } = useSWR(url, fetcher);

  useEffect(() => {
    if (responseData) {
      setApplications(responseData.data);
    }
  }, [responseData]);

  const user = parseJwt(token);

  const getCardIcon = (domain) => {
    let IconComponent = domain == DOMAIN.Car ? CarFilled : HomeFilled;
    return <IconComponent style={{ fontSize: '20px' }} />;
  };

  return (
    <div className="w-full md:w-8/12 mx-auto">
      <p className="text-2xl">Hi {user?.firstName} 👋</p>
      <p className="mt-8 text-xl">Your Applications</p>
      {!applications && (
        <div className="grid grid-cols-4 mt-5 space-x-2">
          <div className="col border rounded-md"></div>
        </div>
      )}
      <div className="grid grid-cols-4 mt-5 space-x-2">
        {applications?.map((application) => {
          const { properties } = application;
          return (
            <Link to={`/applications/${application._id}`} key={application._id}>
              <div className="col border rounded-md">
                <div className="bg-gradient-to-r from-cyan-500 to-blue-500 pt-3 pb-3 px-4 rounded-t-md text-white">
                  <div className="mb-2">{getCardIcon(application.domain)}</div>
                  <div className="text-lg">
                    {application.type === APPLICATION_TYPE_FINANCE ? 'New Purchase' : 'Refinance'}
                    {/* {properties[0]} */}
                  </div>
                  <div className="text-4xl mt-2">
                    {application.type === APPLICATION_TYPE_FINANCE
                      ? currency(application.purchasePrice - (application.depositAmount || 0))
                      : currency(application?.currentLoanAmount)}
                  </div>

                  <div className="text-xs mt-5">
                    <p>Started: {dayjs(application.createdAt).format('DD/MM/YYYY hh:mma')}</p>
                    <p>Updated: {dayjs(application.updatedAt).format('DD/MM/YYYY hh:mma')}</p>
                  </div>
                </div>
                <div className="bg-gray-100 p-5 rounded-b-md">
                  {application.domain == DOMAIN.Home &&
                    (properties[0]?.address || 'Address not known')}
                </div>
              </div>
            </Link>
          );
        })}
        <div className="col border rounded-md bg-gray-100 text-xl ">
          <div className="text-center h-full align-middle p-5">
            <Link to="/application-type" className="mt-5">
              <i className="inline-block ri-add-circle-fill text-6xl text-blue-500"></i>
              <p>Start new Application</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
