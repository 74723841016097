import { useLocation, useNavigate } from 'react-router-dom';

export const SelfEmployed = () => {
  const router = useNavigate();
  const location = useLocation();

  const [, mode] = location.pathname.split('/');
  return (
    <>
      <p className="text-center mt-8 text-2xl px-2">
        Does the business have tax returns available?
      </p>
      <div className="mt-6 px-3 w-96 mx-auto">
        <p className="py-4">
          <button
            onClick={() => router(`/${mode}/applicants-properties`)}
            className="text-blue-800 bg-white border-2 border-blue-800 py-3 px-10 w-full block rounded-md hover:shadow-xl">
            Yes
          </button>
        </p>
        <p className="py-4">
          <button
            onClick={() => router(`/${mode}/applicants-properties`)}
            className="text-blue-800 bg-white border-2 border-blue-800 py-3 px-10 w-full block rounded-md hover:shadow-xl">
            No
          </button>
        </p>
      </div>
    </>
  );
};
