import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProgressContext } from '../App';
import { saveAppFieldInLocalStorage } from '../utils';
import { BuildType, LOAN_APP_TYPE } from '../utils/constants';

export const PropertyStatus = () => {
  const router = useNavigate();
  const { setProgressPercentage } = useContext(ProgressContext);

  useEffect(() => {
    setProgressPercentage(50);
  }, []);

  const setValue = (val) => {
    saveAppFieldInLocalStorage(
      LOAN_APP_TYPE.HomeLoan,
      'buildType',
      val,
      router,
      '/home-loans/finance/property-use'
    );
  };

  return (
    <>
      <p className="text-center mt-8 text-2xl px-2">
        What kind of property are you looking to buy?
      </p>

      <div className="mt-6 px-3 w-96 mx-auto">
        <p className="py-4">
          <button
            onClick={() => setValue(BuildType.newlyBuiltOffThePlan)}
            className="common-radio-rec-ghost-btn">
            Newly build / Off the plan
          </button>
        </p>
        <p className="py-4">
          <button
            onClick={() => setValue(BuildType.establishedHome)}
            className="common-radio-rec-ghost-btn">
            Established Home
          </button>
        </p>
        <p className="py-4">
          <button
            onClick={() => setValue(BuildType.vacantLand)}
            className="common-radio-rec-ghost-btn">
            Vacant Land to build on
          </button>
        </p>
      </div>
    </>
  );
};
