import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProgressContext } from '../App';
import { saveApplicantFieldInLocalStorage } from '../utils';
import { LOAN_APP_TYPE, MODES } from '../utils/constants';

export const CreditHistory = () => {
  const router = useNavigate();
  const location = useLocation();
  const [, , mode] = location.pathname.split('/');

  const { setProgressPercentage } = useContext(ProgressContext);

  useEffect(() => {
    setProgressPercentage(mode === MODES.finance ? 70 : 60);
  }, []);

  const setValue = (val) => {
    saveApplicantFieldInLocalStorage(
      LOAN_APP_TYPE.HomeLoan,
      'depictedCreditHistory',
      val,
      router,
      `/home-loans/${mode}/employment-type`
    );
  };

  return (
    <>
      <p className="text-center mt-0 md:mt-8 text-2xl px-2">{"What's your credit history?"}</p>
      <p className="text-center mt-2 text-md px-2">
        This helps us match the best loan for your scenario.
      </p>

      <div className="mt-6 px-3 w-96 mx-auto">
        <p className="py-4">
          <button onClick={() => setValue('Excellent')} className="common-radio-rec-ghost-btn">
            Excellent - No issues
          </button>
        </p>
        <p className="py-4">
          <button onClick={() => setValue('Average')} className="common-radio-rec-ghost-btn">
            Average, Paid default
          </button>
        </p>
        <p className="py-4">
          <button onClick={() => setValue('Fair')} className="common-radio-rec-ghost-btn">
            Fair, Lets talk
          </button>
        </p>
        <p className="py-4">
          <button onClick={() => setValue("Don't know")} className="common-button">
            {"I don't know"}
          </button>
        </p>
      </div>
    </>
  );
};
