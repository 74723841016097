import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../utils';
import { API_HOST, APPLICANT_PRIMARY, DRIVERS_LICENCE_FRONT } from '../../utils/constants';
import axios from 'axios';

export const UploadIdentification = () => {
  const router = useNavigate();
  const { id: applicationId, applicantId } = router.query;

  const inputFile1 = useRef(null);
  const inputFile2 = useRef(null);

  const [, setApplication] = useState([]);
  const [primaryApplicant, setPrimaryApplicant] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const url = `${API_HOST}/applications`;
  const { data: responseData } = useSWR(applicationId ? `${url}/${applicationId}` : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setApplication(responseData);
      setPrimaryApplicant(responseData?.applicants?.find((a) => a.type === APPLICANT_PRIMARY));
    }
  }, [responseData]);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleSubmission = async () => {
    const formData = new FormData();

    formData.append('type', DRIVERS_LICENCE_FRONT);
    formData.append('file', selectedFile);

    let durl = `${url}/${applicationId}/applicants/${applicantId}/documents/upload`;
    // durl = query ? `${durl}?${query}` : durl;

    await axios.put(durl, formData);
  };

  // async function onSubmit(values) {
  //   try {
  //     await axios.put(
  //       `${url}/${applicationId}/applicants/${primaryApplicant._id}`,
  //       values,
  //       { headers },
  //     );
  //     router(
  //       `/applications/${applicationId}/applicants/${primaryApplicant._id}/e-consent`,
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  return (
    <>
      <p className="text-center mx-auto text-2xl">
        Upload photos of your Australian Driver Licence
      </p>
      <p className="text-center mx-auto mt-2 text-md">
        Ensure photos are clear and the text is not obstructed
      </p>
      <p className="text-center w-96 mx-auto mt-2 text-md">Front and Back</p>
      <div className="w-96 md:w-3/12 px-4 mx-auto">
        <div className="mt-10">
          <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
            <div
              className="bg-blue-100 align-middle text-center px-24 py-12"
              onClick={() => inputFile1.current.click()}>
              <i className="ri-camera-line text-3xl text-blue-500"></i>
              <input
                type="file"
                id="file"
                ref={inputFile1}
                onChange={changeHandler}
                style={{ display: 'none' }}
              />
            </div>
            <div
              className="bg-blue-100 align-middle text-center px-24 py-12"
              onClick={() => inputFile1.current.click()}>
              <i className="ri-camera-line text-3xl text-blue-500"></i>
              <input
                type="file"
                id="file"
                ref={inputFile2}
                onChange={changeHandler}
                style={{ display: 'none' }}
              />
            </div>
          </div>
        </div>

        <div className="mt-4  text-center">
          <button
            onClick={handleSubmission}
            disabled={!isFilePicked}
            type="submit"
            className="common-button block">
            Next
          </button>
        </div>
        <div className="mt-8 text-center">
          <button
            onClick={() =>
              router(`/applications/${applicationId}/applicants/${primaryApplicant._id}/e-consent`)
            }>
            Skip this for now
          </button>
        </div>
      </div>
    </>
  );
};
