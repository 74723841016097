import PropTypes from 'prop-types';
import { Button, Col, Form, InputNumber, Modal, Row, Select, App, Checkbox } from 'antd';
import { memo, useEffect, useState } from 'react';
import { API_HOST, Frequency } from '../../utils/constants';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function AddEmploymentIncomeModal({
  isEmpIncomeModalOpen,
  hideEmpModal,
  empForIncomeEdit,
  refetchApplication,
  primaryApplicant
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [hasExtraEarnings, setHasExtraEarnings] = useState(false);
  const { id: applicationId } = useParams();
  const { notification } = App.useApp();

  useEffect(() => {
    if (empForIncomeEdit) {
      form.setFieldsValue(empForIncomeEdit);
      setHasExtraEarnings(empForIncomeEdit.hasExtraEarnings);
    } else {
      form.resetFields();
      setHasExtraEarnings(null);
    }
  }, [empForIncomeEdit]);

  const onFinish = async (values) => {
    const endpoint = `${API_HOST}/applications/${applicationId}/applicants/${primaryApplicant._id}/employments/${empForIncomeEdit._id}/incomes`;
    try {
      setIsLoading(true);
      await axios.patch(endpoint, values);
      setIsLoading(false);
      hideEmpModal();
      notification.success({
        message: 'Employment income updated'
      });
      form.resetFields();
      refetchApplication();
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: 'Could not update employment income',
        description: error.response?.data?.message[0]
      });
      console.error(error);
    }
  };

  return (
    <Modal
      title="Update Employment Income"
      footer={null}
      style={{ top: 20 }}
      open={isEmpIncomeModalOpen}
      onCancel={hideEmpModal}
      forceRender>
      <Form form={form} layout="vertical" className="mt-6" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="netIncome"
              label="Net Income"
              rules={[{ required: true, message: 'Net Income is required' }]}
              initialValue={1000}>
              <InputNumber
                className="w-full"
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="netIncomeFrequency"
              label="Frequency"
              rules={[{ required: true, message: 'Frequency is required' }]}>
              <Select>
                {Object.keys(Frequency).map((key) => (
                  <Select.Option value={Frequency[key]} key={Frequency[key]}>
                    {Frequency[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="hasExtraEarnings" label="Has Extra Earnings?" valuePropName="checked">
              <Checkbox onChange={(e) => setHasExtraEarnings(e.target.checked)} />
            </Form.Item>
          </Col>
        </Row>
        {hasExtraEarnings ? (
          <>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="commissionPretax" label="Commission Pretax" initialValue={1000}>
                  <InputNumber
                    className="w-full"
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="commissionPretaxFrequency" label="Frequency">
                  <Select>
                    {Object.keys(Frequency).map((key) => (
                      <Select.Option value={Frequency[key]} key={Frequency[key]}>
                        {Frequency[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="overtimePretax" label="Overtime Pretax" initialValue={1000}>
                  <InputNumber
                    className="w-full"
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="overtimePretaxFrequency" label="Frequency">
                  <Select>
                    {Object.keys(Frequency).map((key) => (
                      <Select.Option value={Frequency[key]} key={Frequency[key]}>
                        {Frequency[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="allowancesPretax" label="Allowances Pretax" initialValue={1000}>
                  <InputNumber
                    className="w-full"
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="allowancesPretaxFrequency" label="Frequency">
                  <Select>
                    {Object.keys(Frequency).map((key) => (
                      <Select.Option value={Frequency[key]} key={Frequency[key]}>
                        {Frequency[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null}
        <div className="text-right">
          <Button onClick={hideEmpModal} className="mr-4">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default memo(AddEmploymentIncomeModal);

AddEmploymentIncomeModal.propTypes = {
  isEmpIncomeModalOpen: PropTypes.bool,
  hideEmpModal: PropTypes.func,
  empForIncomeEdit: PropTypes.object,
  refetchApplication: PropTypes.func,
  primaryApplicant: PropTypes.object
};
