import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProgressContext } from '../App';
import { saveApplicantFieldInLocalStorage } from '../utils';
import { LOAN_APP_TYPE, STRING_YES } from '../utils/constants';

export const FirstHome = () => {
  const router = useNavigate();

  const { setProgressPercentage } = useContext(ProgressContext);

  useEffect(() => {
    setProgressPercentage(40);
  }, []);

  const setValue = (val) => {
    saveApplicantFieldInLocalStorage(
      LOAN_APP_TYPE.HomeLoan,
      'isFirstHomeBuyer',
      val === STRING_YES,
      router,
      '/home-loans/finance/property-status'
    );
  };

  return (
    <>
      <p className="text-center mt-8 text-2xl px-2">Are you a first home buyer?</p>

      <div className="mt-6 px-3 w-96 mx-auto">
        <p className="py-4">
          <button onClick={() => setValue('Yes')} className="common-radio-rec-ghost-btn">
            Yes
          </button>
        </p>
        <p className="py-4">
          <button onClick={() => setValue('No')} className="common-radio-rec-ghost-btn">
            No
          </button>
        </p>
      </div>
    </>
  );
};
