import { useNavigate } from 'react-router-dom';

export const StartApplication = () => {
  const router = useNavigate();
  return (
    <>
      <p className="text-center mt-8 text-2xl px-2">What would you like to do?</p>

      <div className="mt-6 px-3 w-96 mx-auto">
        <p className="py-4">
          <button
            onClick={() => router('/home-loans/refinance/current-loan-amount')}
            className="common-radio-rec-ghost-btn">
            I want to refinance
          </button>
        </p>
        <p className="py-4">
          <button
            onClick={() => router('/home-loans/finance/purchase-price')}
            className="common-radio-rec-ghost-btn">
            I want to buy a home
          </button>
        </p>
      </div>
    </>
  );
};
