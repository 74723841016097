import { memo } from 'react';
import { Card, Row, Col, Typography, Space, Tag, Divider, Tooltip, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
function EmploymentCard({
  title,
  employment,
  className: passedClassName,
  onEditClick,
  onDeleteClick
}) {
  return (
    <Card
      title={
        <Space>
          <span>{title}</span>
          {employment.isCurrent ? <Tag color="orange">Current</Tag> : ''}
        </Space>
      }
      type="inner"
      extra={
        <Space size="middle">
          <EditOutlined
            style={{ fontSize: 16 }}
            className="cursor-pointer"
            onClick={() => onEditClick(employment)}
          />
          <Tooltip placement="bottom" title="Delete">
            <div>
              <Popconfirm
                title="Are you sure to delete applicant?"
                onConfirm={() => onDeleteClick(employment._id)}
                okButtonProps={{ danger: true, type: 'primary', size: 'middle' }}
                okText="Delete"
                cancelButtonProps={{ size: 'middle' }}>
                <DeleteOutlined className="text-red-600 cursor-pointer" style={{ fontSize: 16 }} />{' '}
              </Popconfirm>
            </div>
          </Tooltip>
        </Space>
      }
      className={clsx(passedClassName)}>
      <Row gutter={24}>
        <Col span={6}>
          <Typography.Text strong className="block">
            Employer Name
          </Typography.Text>
          {employment.employerName}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Position
          </Typography.Text>
          {employment.position}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Industry
          </Typography.Text>
          {employment.industry}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            ABN
          </Typography.Text>
          {employment.abn}
        </Col>
      </Row>
      <Row gutter={24} className="mt-4">
        <Col span={6}>
          <Typography.Text strong className="block">
            Contact Name
          </Typography.Text>
          {employment.contactName}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Phone
          </Typography.Text>
          {employment.phone}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Start Date
          </Typography.Text>
          {dayjs(employment.startDate).format('DD/MM/YYYY')}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            End Date
          </Typography.Text>
          {employment.endDate ? dayjs(employment.endDate).format('DD/MM/YYYY') : '--'}
        </Col>
      </Row>
      <Row gutter={24} className="mt-4">
        <Col span={6}>
          <Typography.Text strong className="block">
            Time at employment (Yrs){' '}
          </Typography.Text>
          {`${employment.yearsDuration} year${employment.yearsDuration < 2 ? '' : 's'}`}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Time at employment (Mths)
          </Typography.Text>
          {`${employment.monthsDuration} month${employment.monthsDuration < 2 ? '' : 's'}`}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Employment Type
          </Typography.Text>
          {employment.type}
        </Col>
      </Row>

      <div className="mt-4">
        <Divider orientation="left" orientationMargin="0">
          Address
        </Divider>
      </div>
      <Row gutter={24} className="mt-4">
        <Col span={6}>
          <Typography.Text strong className="block">
            Unit #
          </Typography.Text>
          {employment.unitNumber || '--'}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Street #
          </Typography.Text>
          {employment.streetNumber}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Street Name
          </Typography.Text>
          {employment.streetName}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Suburb
          </Typography.Text>
          {employment.city}
        </Col>
      </Row>
      <Row gutter={24} className="mt-3">
        <Col span={6}>
          <Typography.Text strong className="block">
            State
          </Typography.Text>
          {employment.state?.toUpperCase()}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Postcode
          </Typography.Text>
          {employment.postcode}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Country
          </Typography.Text>
          {employment.country}
        </Col>
      </Row>
    </Card>
  );
}

export default memo(EmploymentCard);

EmploymentCard.propTypes = {
  title: PropTypes.string.isRequired,
  employment: PropTypes.object.isRequired,
  className: PropTypes.string,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func
};
