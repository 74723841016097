import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { extractNumeric, saveAppFieldInLocalStorage } from '../utils';
import CurrencyInput from './common/CurrencyInput';
import * as Yup from 'yup';
import { useContext, useEffect } from 'react';
import { ProgressContext } from '../App';
import { DOMAIN, LOAN_APP_TYPE } from '../utils/constants';

const schema = Yup.object().shape({
  currentLoanAmount: Yup.string().required('Required')
});

export const CurrentLoanAmount = () => {
  const router = useNavigate();

  const { setProgressPercentage } = useContext(ProgressContext);

  useEffect(() => {
    setProgressPercentage(10);
  }, []);

  function onSubmit(values) {
    saveAppFieldInLocalStorage(LOAN_APP_TYPE.HomeLoan, 'domain', DOMAIN.Home);
    saveAppFieldInLocalStorage(
      LOAN_APP_TYPE.HomeLoan,
      'currentLoanAmount',
      extractNumeric(values.currentLoanAmount),
      router,
      '/home-loans/refinance/current-interest-rate'
    );
  }
  return (
    <>
      <p className="text-center mt-8 text-2xl px-2">What is your Current Loan Amount?</p>

      <div className="mt-6 px-3 w-96 mx-auto">
        <Formik
          enableReinitialize
          validationSchema={schema}
          initialValues={{ currentLoanAmount: '' }}
          onSubmit={onSubmit}>
          {({ errors, touched }) => (
            <Form>
              <Field name="currentLoanAmount" className="form-input px-4 py-3 rounded-md ">
                {({ field }) => (
                  <CurrencyInput
                    placeholder="$0"
                    {...field}
                    className="form-input px-4 py-3 rounded-md w-full"
                  />
                )}
              </Field>
              {errors.currentLoanAmount && touched.currentLoanAmount ? (
                <div className="error">{errors.currentLoanAmount}</div>
              ) : null}
              <p className="py-4">
                <button type="submit" className="common-button">
                  Next
                </button>
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
