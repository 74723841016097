//VEHICLE TYPES
export const VEHICLE_TYPE_CAR = 'Car';
export const VEHICLE_TYPE_TRUCK = 'Truck';
export const VEHICLE_TYPE_SUV = 'SUV';
export const VEHICLE_TYPE_VAN = 'Van';
export const VEHICLE_TYPES = [
  VEHICLE_TYPE_CAR,
  VEHICLE_TYPE_TRUCK,
  VEHICLE_TYPE_SUV,
  VEHICLE_TYPE_VAN
];

//VEHICLE DECISION
export const VEHICLE_NOT_DECIDED = "Haven't decided yet";

//VEHICLE CONDITION
export const VEHICLE_CONDITION_NEW = 'New';
export const VEHICLE_CONDITION_USED = 'Used';
export const VEHICLE_CONDITIONS = [VEHICLE_CONDITION_NEW, VEHICLE_CONDITION_USED];

//VEHICLE USAGE
export const VEHICLE_USAGE_PERSONAL = 'Personal';
export const VEHICLE_USAGE_COMMERCIAL = 'Commercial';
export const VEHICLE_USAGES = [VEHICLE_USAGE_PERSONAL, VEHICLE_USAGE_COMMERCIAL];
