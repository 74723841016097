/* eslint-disable no-undef */
export const ENV = process.env.REACT_APP_ENV;
export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
export const API_HOST = process.env.REACT_APP_API_HOST;

export const DRIVERS_LICENCE_FRONT = 'Driver Licence (Front)';
export const DRIVERS_LICENCE_BACK = 'Driver Licence (Back)';
export const MEDICARE_CARD = 'Medicare Card';
export const PAYSLIP1 = 'Payslip 1';
export const PAYSLIP2 = 'Payslip 2';
export const BANK_STATEMENTS = 'Bank Statements';
export const PASSPORT = 'Passport';
export const RESIDENCY_VISA_DOCS = 'Residency/Visa Doucments';
export const TAX_RETURN_INDIVIDUAL = 'Tax Return (Individual)';
export const COMPANY_FINANCIALS = 'Company Financials';
export const EMPLOYMENT_CONFIRMATION = 'Employment Confirmation';
export const PAYG_SUMMARY = 'PAYG Summary';
export const RENTAL_INCOME_STATEMENTS = 'Rental Income Statements';
export const SPOUSAL_INCOME_CONFIRMATION = 'Spousal Income Confirmation';
export const TENANCY_AGREEMENT = 'Tenancy Agreement';
export const PROOF_OF_ADDRESS = 'Proof of Address';

export const DOCUMENTS = [
  DRIVERS_LICENCE_FRONT,
  DRIVERS_LICENCE_BACK,
  MEDICARE_CARD,
  PAYSLIP1,
  PAYSLIP2,
  BANK_STATEMENTS,
  PASSPORT,
  RESIDENCY_VISA_DOCS,
  TAX_RETURN_INDIVIDUAL,
  COMPANY_FINANCIALS,
  EMPLOYMENT_CONFIRMATION,
  PAYG_SUMMARY,
  RENTAL_INCOME_STATEMENTS,
  SPOUSAL_INCOME_CONFIRMATION,
  TENANCY_AGREEMENT,
  PROOF_OF_ADDRESS
];

export const MODE_FINANCE = 'finance';
export const MODE_REFINANCE = 'refinance';

export const MODES = {
  [MODE_FINANCE]: MODE_FINANCE,
  [MODE_REFINANCE]: MODE_REFINANCE
};

export const ENVIRONMENTS = {
  dev: 'dev',
  qa: 'qa',
  staging: 'staging',
  production: 'production'
};

export const STRING_YES = 'Yes';
export const STRING_NO = 'No';
export const APPLICATION_TYPE_FINANCE = 'Finance';
export const APPLICATION_TYPE_REFINANCE = 'Refinance';

export const APPLICANT_PRIMARY = 'Primary';
export const APPLICANT_SECONDARY = 'Secondary';

export const PROPERTY_TYPE_TOWN_HOUSE = 'Town House';
export const PROPERTY_TYPE_FULLY_DETATCHED_HOUSE = 'Fully Detached Home';
export const PROPERTY_TYPE_APARTMENT = 'Apartment';
export const PROPERTY_TYPE_VILLA = 'Villa';
export const PROPERTY_TYPE_LAND = 'Land';

export const PROPERTY_TYPES = [
  PROPERTY_TYPE_TOWN_HOUSE,
  PROPERTY_TYPE_FULLY_DETATCHED_HOUSE,
  PROPERTY_TYPE_APARTMENT,
  PROPERTY_TYPE_VILLA,
  PROPERTY_TYPE_LAND
];

export const STATE_NSW = 'NSW';
export const STATE_QLD = 'QLD';
export const STATE_VIC = 'VIC';
export const STATE_WA = 'WA';
export const STATE_TAS = 'TAS';
export const STATE_SA = 'SA';
export const STATE_NT = 'NT';
export const STATE_ACT = 'ACT';

export const AU_STATES = [
  STATE_NSW,
  STATE_QLD,
  STATE_VIC,
  STATE_WA,
  STATE_TAS,
  STATE_SA,
  STATE_NT,
  STATE_ACT
];

export const BuildType = {
  newlyBuiltOffThePlan: 'Newly build/Off the plan',
  establishedHome: 'Established Home',
  vacantLand: 'Vacant Land'
};

export const BUYING_SITUATION_JUST_EXPOLORING = 'Just Exploring';
export const BUYING_SITUATION_BUYING_IN_6_MTHS = 'Buying in next 6 months';
export const BUYING_SITUATION_ACTIVELY_LOOKING = 'Actively Looking';
export const BUYING_SITUATION_EXCHANGED_CONTRACTS = 'Exchanged contracts';

export const BUYING_SITUATIONS = [
  BUYING_SITUATION_JUST_EXPOLORING,
  BUYING_SITUATION_BUYING_IN_6_MTHS,
  BUYING_SITUATION_ACTIVELY_LOOKING,
  BUYING_SITUATION_EXCHANGED_CONTRACTS
];

export const LEAD_SOURCE_WEBSITE = 'Website';
export const LEAD_SOURCES = [LEAD_SOURCE_WEBSITE];

export const LEAD_CHANNEL_GOOGLE = 'Google';
export const LEAD_CHANNEL_FACEBOOK = 'Facebook';
export const LEAD_CHANNEL_TIKTOK = 'TikTok';
export const LEAD_CHANNEL_EMAIL = 'Email';
export const LEAD_CHANNELS = [
  LEAD_CHANNEL_GOOGLE,
  LEAD_CHANNEL_FACEBOOK,
  LEAD_CHANNEL_TIKTOK,
  LEAD_CHANNEL_EMAIL
];

export const LEAD_APPLICATION_TYPES = [APPLICATION_TYPE_FINANCE, APPLICATION_TYPE_REFINANCE];

export const LEAD_CREATION_SOURCE_FUNNEL = 'Funnel';
export const LEAD_CREATION_SOURCE_REFERRAL_PORTAL = 'Referral Portal';
export const LEAD_CREATION_SOURCE_CRM = 'CRM';

export const LEAD_CREATION_SOURCES = [
  LEAD_CREATION_SOURCE_FUNNEL,
  LEAD_CREATION_SOURCE_REFERRAL_PORTAL,
  LEAD_CREATION_SOURCE_CRM
];

export const BUILD_TYPE_NEWLY_BUILT = 'Newly build/Off the plan';
export const BUILD_TYPE_ESTABLESHED_HOME = 'Established Home';
export const BUILD_TYPE_VACANT_LAND = 'Vacant Land';
export const BUILD_TYPES = [
  BUILD_TYPE_NEWLY_BUILT,
  BUILD_TYPE_ESTABLESHED_HOME,
  BUILD_TYPE_VACANT_LAND
];

export const DEPICTED_CREDIT_HISTORY_EXCELLENT = 'Excellent';
export const DEPICTED_CREDIT_HISTORY_AVERAGE = 'Average';
export const DEPICTED_CREDIT_HISTORY_FAIR = 'Fair';
export const DEPICTED_CREDIT_HISTORY_DONT_KNOW = "Don't know";
export const DEPICTED_CREDIT_HISTORIES = [
  DEPICTED_CREDIT_HISTORY_EXCELLENT,
  DEPICTED_CREDIT_HISTORY_AVERAGE,
  DEPICTED_CREDIT_HISTORY_FAIR,
  DEPICTED_CREDIT_HISTORY_DONT_KNOW
];

export const EMPLOYEMENT_STATUS_EMPLOYEE = 'Employee';
export const EMPLOYEMENT_STATUS_SELF_EMPLOYED = 'Self-Employed';
export const EMPLOYEMENT_STATUS_OTHER = 'Other';
export const EMPLOYEMENT_STATUSES = [
  EMPLOYEMENT_STATUS_EMPLOYEE,
  EMPLOYEMENT_STATUS_SELF_EMPLOYED,
  EMPLOYEMENT_STATUS_OTHER
];

export const REFINANCE_REASON_DECREASE_REPAYMENTS = 'Decrease Repayments';
export const REFINANCE_REASON_PAYOFF_LOAN_FASTER = 'Pay off loan faster';
export const REFINANCE_REASON_CONSOLIDATE_DEBT = 'Consolidate debt/cash out';
export const REFINANCE_REASON_BUILD_ON_MY_LAND = 'Build on my land or property';
export const REFINANCE_REASONS = [
  REFINANCE_REASON_DECREASE_REPAYMENTS,
  REFINANCE_REASON_PAYOFF_LOAN_FASTER,
  REFINANCE_REASON_CONSOLIDATE_DEBT,
  REFINANCE_REASON_BUILD_ON_MY_LAND
];

export const PROPERTY_TYPE_FULLY_DETATCHED_HOME = 'Fully detatched home';
export const PROPERTY_TYPE_FULLY_TOWN_HOUSE = 'Town House';
export const PROPERTY_TYPE_FULLY_APARTMENT = 'Apartment';

export const PROPERTY_USAGE_OWNER_OCCUPIED = 'Owner Occupied';
export const PROPERTY_USAGE_INVESTMENT = 'Investment';

export const PROPERTY_USAGES = [PROPERTY_USAGE_OWNER_OCCUPIED, PROPERTY_USAGE_INVESTMENT];

export const LOAN_APP_TYPE = {
  HomeLoan: 'homeLoanApp',
  CarLoan: 'carLoanApp'
};

export const DOMAIN = {
  Home: 'Home',
  Car: 'Car'
};

export const SAVING_ACCOUNT = 'Saving Account';
export const SUPERANNUATION = 'Superannuation';
export const SHARES = 'Shares';
export const MOTOR_VEHICLE = 'Motor Vehicle';
export const WATERCRAFT = 'Watercraft';
export const PROPERTY = 'Property';
export const TERM_DEPOSIT = 'Term Deposit';
export const MANAGED_FUNDS = 'Managed Funds';
export const HOME_CONTENTS = 'Home Contents';
export const TOOLS_EQUIPMENT = 'Tools & Equipment';

export const assetTypes = [
  SAVING_ACCOUNT,
  SUPERANNUATION,
  SHARES,
  MOTOR_VEHICLE,
  WATERCRAFT,
  PROPERTY,
  TERM_DEPOSIT,
  MANAGED_FUNDS,
  HOME_CONTENTS,
  TOOLS_EQUIPMENT
];

export const NON_APPLICANT = 'Non-applicant';

export const CREDIT_CARD = 'Credit Card';
export const STORE_CARD = 'Store Card';
export const CAR_LOAN = 'Car Loan';
export const PERSONAL_LOAN = 'Personal Loan';
export const HOME_LOAN = 'Home Loan';

export const liabilityTypes = [CREDIT_CARD, STORE_CARD, CAR_LOAN, PERSONAL_LOAN, HOME_LOAN];

export const Frequency = {
  Weekly: 'Weekly',
  Fortnightly: 'Fortnightly',
  Monthly: 'Monthly',
  Yearly: 'Yearly'
};

export const INCOME_TYPE_RENTAL = 'Rental';
export const INCOME_TYPE_INVESTMENT = 'Investment Income';
export const INCOME_TYPE_SALARY_SACRIFICE = 'Salary Sacrifice';
export const INCOME_TYPE_CENTRELINK = 'Centrelink';
export const INCOME_TYPE_FOREIGN = 'Foreign';
export const INCOME_TYPE_CHILD_MAINTENANCE = 'Child Maintenance';
export const INCOME_TYPE_FAMILY_TAX_BENEFIT = 'Family Tax Benefit';

export const INCOME_TYPES = [
  INCOME_TYPE_RENTAL,
  INCOME_TYPE_INVESTMENT,
  INCOME_TYPE_SALARY_SACRIFICE,
  INCOME_TYPE_CENTRELINK,
  INCOME_TYPE_FOREIGN,
  INCOME_TYPE_CHILD_MAINTENANCE,
  INCOME_TYPE_FAMILY_TAX_BENEFIT
];

export const expenseTypes = [
  'Groceries',
  'Childcare',
  'Education',
  'Clothing and personal care',
  'Telephone, internet, Streaming subscriptions',
  'General Basic Insurances',
  'Higher Education/Vocational Training/Professional Fees',
  'Medical and health',
  'Pet Care',
  'Transport',
  'Recreation and entertainment',
  'Primary Residence Running Costs',
  'Council fee on Principal Place of Residence',
  'Insurance (Sickness and Personal, Accident, Life)',
  'Private Schooling and Tuition',
  'Investment Property Running Costs',
  'Health Insurance',
  'Secondary Residence Running Costs',
  'Other Expense',
  'Rent',
  'Board',
  'Child and Spousal Maintenance',
  'Other Commitment'
].sort();

export const EmploymentType = {
  fullTime: 'Full Time',
  partTime: 'Part Time',
  casual: 'Casual',
  unemployed: 'Unemployed'
};

export const Countries = [
  {
    name: 'Afghanistan',
    code: 'AFG'
  },
  {
    name: 'Albania',
    code: 'ALB'
  },
  {
    name: 'Algeria',
    code: 'DZA'
  },
  {
    name: 'American Samoa',
    code: 'ASM'
  },
  {
    name: 'Andorra',
    code: 'AND'
  },
  {
    name: 'Angola',
    code: 'AGO'
  },
  {
    name: 'Anguilla',
    code: 'AIA'
  },
  {
    name: 'Antigua and Barbuda',
    code: 'ATG'
  },
  {
    name: 'Argentina',
    code: 'ARG'
  },
  {
    name: 'Armenia',
    code: 'ARM'
  },
  {
    name: 'Aruba',
    code: 'ABW'
  },
  {
    name: 'Australia',
    code: 'AUS'
  },
  {
    name: 'Austria',
    code: 'AUT'
  },
  {
    name: 'Azerbaijan',
    code: 'AZE'
  },
  {
    name: 'Bahamas',
    code: 'BHS'
  },
  {
    name: 'Bahrain',
    code: 'BHR'
  },
  {
    name: 'Bangladesh',
    code: 'BGD'
  },
  {
    name: 'Barbados',
    code: 'BRB'
  },
  {
    name: 'Belarus',
    code: 'BLR'
  },
  {
    name: 'Belgium',
    code: 'BEL'
  },
  {
    name: 'Belize',
    code: 'BLZ'
  },
  {
    name: 'Benin',
    code: 'BEN'
  },
  {
    name: 'Bermuda',
    code: 'BMU'
  },
  {
    name: 'Bhutan',
    code: 'BTN'
  },
  {
    name: 'Bolivia',
    code: 'BOL'
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BIH'
  },
  {
    name: 'Botswana',
    code: 'BWA'
  },
  {
    name: 'Brazil',
    code: 'BRA'
  },
  {
    name: 'British Virgin Islands',
    code: 'VGB'
  },
  {
    name: 'Brunei Darussalam',
    code: 'BRN'
  },
  {
    name: 'Bulgaria',
    code: 'BGR'
  },
  {
    name: 'Burkina Faso',
    code: 'BFA'
  },
  {
    name: 'Burundi',
    code: 'BDI'
  },
  {
    name: 'Cambodia',
    code: 'KHM'
  },
  {
    name: 'Cameroon',
    code: 'CMR'
  },
  {
    name: 'Canada',
    code: 'CAN'
  },
  {
    name: 'Cape Verde',
    code: 'CPV'
  },
  {
    name: 'Central African Republic',
    code: 'CAF'
  },
  {
    name: 'Chad',
    code: 'TCD'
  },
  {
    name: 'Chile',
    code: 'CHL'
  },
  {
    name: 'China',
    code: 'CHN'
  },
  {
    name: 'Hong Kong',
    code: ' \n         HKG'
  },
  {
    name: 'Macao',
    code: 'MAC'
  },
  {
    name: 'Colombia',
    code: 'COL'
  },
  {
    name: 'Comoros',
    code: 'COM'
  },
  {
    name: 'Congo',
    code: 'COG'
  },
  {
    name: 'Costa Rica',
    code: 'CRI'
  },
  {
    name: "Côte d'Ivoire",
    code: 'CIV'
  },
  {
    name: 'Croatia',
    code: 'HRV'
  },
  {
    name: 'Cuba',
    code: 'CUB'
  },
  {
    name: 'Cyprus',
    code: 'CYP'
  },
  {
    name: 'Czech Republic',
    code: 'CZE'
  },
  {
    name: 'Denmark',
    code: 'DNK'
  },
  {
    name: 'Djibouti',
    code: 'DJI'
  },
  {
    name: 'Dominica',
    code: 'DMA'
  },
  {
    name: 'Dominican Republic',
    code: 'DOM'
  },
  {
    name: 'Ecuador',
    code: 'ECU'
  },
  {
    name: 'Egypt',
    code: 'EGY'
  },
  {
    name: 'El Salvador',
    code: 'SLV'
  },
  {
    name: 'Equatorial Guinea',
    code: 'GNQ'
  },
  {
    name: 'Eritrea',
    code: 'ERI'
  },
  {
    name: 'Estonia',
    code: 'EST'
  },
  {
    name: 'Ethiopia',
    code: 'ETH'
  },
  {
    name: 'Faroe Islands',
    code: ' \n      FRO'
  },
  {
    name: 'Fiji',
    code: 'FJI'
  },
  {
    name: 'Finland',
    code: 'FIN'
  },
  {
    name: 'France',
    code: 'FRA'
  },
  {
    name: 'French Guiana',
    code: 'GUF'
  },
  {
    name: 'French Polynesia',
    code: 'PYF'
  },
  {
    name: 'Gabon',
    code: 'GAB'
  },
  {
    name: 'Gambia',
    code: 'GMB'
  },
  {
    name: 'Georgia',
    code: 'GEO'
  },
  {
    name: 'Germany',
    code: 'DEU'
  },
  {
    name: 'Ghana',
    code: 'GHA'
  },
  {
    name: 'Greece',
    code: 'GRC'
  },
  {
    name: 'Greenland',
    code: 'GRL'
  },
  {
    name: 'Grenada',
    code: 'GRD'
  },
  {
    name: 'Guadeloupe',
    code: 'GLP'
  },
  {
    name: 'Guam',
    code: 'GUM'
  },
  {
    name: 'Guatemala',
    code: 'GTM'
  },
  {
    name: 'Guinea-Bissau',
    code: 'GNB'
  },
  {
    name: 'Haiti',
    code: 'HTI'
  },
  {
    name: 'Honduras',
    code: 'HND'
  },
  {
    name: 'Iceland',
    code: 'ISL'
  },
  {
    name: 'Indonesia',
    code: 'IDN'
  },
  {
    name: 'Iraq',
    code: 'IRQ'
  },
  {
    name: 'Italy',
    code: 'ITA'
  },
  {
    name: 'Japan',
    code: 'JPN'
  },
  {
    name: 'Jordan',
    code: 'JOR'
  },
  {
    name: 'Kazakhstan',
    code: 'KAZ'
  },
  {
    name: 'Kenya',
    code: 'KEN'
  },
  {
    name: 'Kiribati',
    code: 'KIR'
  },
  {
    name: 'Korea',
    code: 'PRK'
  },
  {
    name: 'Korea',
    code: 'KOR'
  },
  {
    name: 'Kuwait',
    code: 'KWT'
  },
  {
    name: 'Kyrgyzstan',
    code: 'KGZ'
  },
  {
    name: 'Lao PDR',
    code: 'LAO'
  },
  {
    name: 'Latvia',
    code: 'LVA'
  },
  {
    name: 'Lebanon',
    code: 'LBN'
  },
  {
    name: 'Lesotho',
    code: 'LSO'
  },
  {
    name: 'Liberia',
    code: 'LBR'
  },
  {
    name: 'Libya',
    code: 'LBY'
  },
  {
    name: 'Liechtenstein',
    code: 'LIE'
  },
  {
    name: 'Lithuania',
    code: 'LTU'
  },
  {
    name: 'Luxembourg',
    code: 'LUX'
  },
  {
    name: 'Madagascar',
    code: 'MDG'
  },
  {
    name: 'Malawi',
    code: 'MWI'
  },
  {
    name: 'Malaysia',
    code: 'MYS'
  },
  {
    name: 'Maldives',
    code: 'MDV'
  },
  {
    name: 'Mali',
    code: 'MLI'
  },
  {
    name: 'Malta',
    code: 'MLT'
  },
  {
    name: 'Marshall Islands',
    code: 'MHL'
  },
  {
    name: 'Martinique',
    code: 'MTQ'
  },
  {
    name: 'Mauritania',
    code: 'MRT'
  },
  {
    name: 'Mauritius',
    code: 'MUS'
  },
  {
    name: 'Mexico',
    code: 'MEX'
  },
  {
    name: 'Micronesia, Federated States of',
    code: 'FSM'
  },
  {
    name: 'Moldova',
    code: 'MDA'
  },
  {
    name: 'Monaco',
    code: 'MCO'
  },
  {
    name: 'Mongolia',
    code: 'MNG'
  },
  {
    name: 'Montenegro',
    code: 'MNE'
  },
  {
    name: 'Montserrat',
    code: 'MSR'
  },
  {
    name: 'Morocco',
    code: 'MAR'
  },
  {
    name: 'Mozambique',
    code: 'MOZ'
  },
  {
    name: 'Myanmar',
    code: 'MMR'
  },
  {
    name: 'Namibia',
    code: 'NAM'
  },
  {
    name: 'Nauru',
    code: 'NRU'
  },
  {
    name: 'Nepal',
    code: 'NPL'
  },
  {
    name: 'Netherlands',
    code: 'NLD'
  },
  {
    name: 'Netherlands Antilles',
    code: 'ANT'
  },
  {
    name: 'New Caledonia',
    code: 'NCL'
  },
  {
    name: 'New Zealand',
    code: 'NZL'
  },
  {
    name: 'Nicaragua',
    code: 'NIC'
  },
  {
    name: 'Niger',
    code: 'NER'
  },
  {
    name: 'Nigeria',
    code: 'NGA'
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MNP'
  },
  {
    name: 'Norway',
    code: 'NOR'
  },
  {
    name: 'Oman',
    code: 'OMN'
  },
  {
    name: 'Pakistan',
    code: 'PAK'
  },
  {
    name: 'Palau',
    code: 'PLW'
  },
  {
    name: 'Palestinian Territory',
    code: ' \n      PSE'
  },
  {
    name: 'Panama',
    code: 'PAN'
  },
  {
    name: 'Papua New Guinea',
    code: 'PNG'
  },
  {
    name: 'Paraguay',
    code: 'PRY'
  },
  {
    name: 'Peru',
    code: 'PER'
  },
  {
    name: 'Philippines',
    code: 'PHL'
  },
  {
    name: 'Pitcairn',
    code: 'PCN'
  },
  {
    name: 'Poland',
    code: 'POL'
  },
  {
    name: 'Portugal',
    code: 'PRT'
  },
  {
    name: 'Puerto Rico',
    code: 'PRI'
  },
  {
    name: 'Qatar',
    code: 'QAT'
  },
  {
    name: 'Réunion',
    code: 'REU'
  },
  {
    name: 'Romania',
    code: 'ROU'
  },
  {
    name: 'Russian Federation',
    code: 'RUS'
  },
  {
    name: 'Rwanda',
    code: 'RWA'
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KNA'
  },
  {
    name: 'Saint Lucia',
    code: 'LCA'
  },
  {
    name: 'Saint Vincent and Grenadines',
    code: 'VCT'
  },
  {
    name: 'Samoa',
    code: 'WSM'
  },
  {
    name: 'San Marino',
    code: 'SMR'
  },
  {
    name: 'Sao Tome and Principe',
    code: 'STP'
  },
  {
    name: 'Saudi Arabia',
    code: 'SAU'
  },
  {
    name: 'Senegal',
    code: 'SEN'
  },
  {
    name: 'Serbia',
    code: 'SRB'
  },
  {
    name: 'Seychelles',
    code: 'SYC'
  },
  {
    name: 'Sierra Leone',
    code: 'SLE'
  },
  {
    name: 'Singapore',
    code: 'SGP'
  },
  {
    name: 'Slovakia',
    code: 'SVK'
  },
  {
    name: 'Slovenia',
    code: 'SVN'
  },
  {
    name: 'Solomon Islands',
    code: 'SLB'
  },
  {
    name: 'Somalia',
    code: 'SOM'
  },
  {
    name: 'South Africa',
    code: 'ZAF'
  },
  {
    name: 'Spain',
    code: 'ESP'
  },
  {
    name: 'Sri Lanka',
    code: 'LKA'
  },
  {
    name: 'Sudan',
    code: 'SDN'
  },
  {
    name: 'Suriname',
    code: 'SUR'
  },
  {
    name: 'Swaziland',
    code: 'SWZ'
  },
  {
    name: 'Sweden',
    code: 'SWE'
  },
  {
    name: 'Switzerland',
    code: 'CHE'
  },
  {
    name: 'Syrian Arab Republic',
    code: 'SYR'
  },
  {
    name: 'Tajikistan',
    code: 'TJK'
  },
  {
    name: 'Tanzania',
    code: 'TZA'
  },
  {
    name: 'Thailand',
    code: 'THA'
  },
  {
    name: 'Timor-Leste',
    code: 'TLS'
  },
  {
    name: 'Togo',
    code: 'TGO'
  },
  {
    name: 'Tonga',
    code: 'TON'
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TTO'
  },
  {
    name: 'Tunisia',
    code: 'TUN'
  },
  {
    name: 'Turkey',
    code: 'TUR'
  },
  {
    name: 'Turkmenistan',
    code: 'TKM'
  },
  {
    name: 'Tuvalu',
    code: 'TUV'
  },
  {
    name: 'Uganda',
    code: 'UGA'
  },
  {
    name: 'Ukraine',
    code: 'UKR'
  },
  {
    name: 'United Arab Emirates',
    code: 'ARE'
  },
  {
    name: 'United Kingdom',
    code: 'GBR'
  },
  {
    name: 'United States of America',
    code: 'USA'
  },
  {
    name: 'Uruguay',
    code: 'URY'
  },
  {
    name: 'Uzbekistan',
    code: 'UZB'
  },
  {
    name: 'Vanuatu',
    code: 'VUT'
  },
  {
    name: 'Venezuela',
    code: 'VEN'
  },
  {
    name: 'Viet Nam',
    code: 'VNM'
  },
  {
    name: 'Virgin Islands, US',
    code: 'VIR'
  },
  {
    name: 'Yemen',
    code: 'YEM'
  },
  {
    name: 'Zambia',
    code: 'ZMB'
  },
  {
    name: 'Zimbabwe',
    code: 'ZWE'
  }
];
