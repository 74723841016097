import { useState, useEffect } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher, headers } from '../../utils';
import { API_HOST } from '../../utils/constants';
import axios from 'axios';

export const Dependants = () => {
  const router = useNavigate();
  const { id: applicationId } = router.query;

  const [primaryApplicant, setPrimaryApplicant] = useState();

  const url = `${API_HOST}/applications`;
  const { data: responseData } = useSWR(applicationId ? `${url}/${applicationId}` : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setPrimaryApplicant(responseData.applicants.find((a) => a.type === 'Primary'));
    }
  }, [responseData]);

  const onSubmit = async (values) => {
    try {
      await axios.put(`${url}/${applicationId}/applicants/${primaryApplicant._id}`, values, {
        headers
      });
      router(`/applications/${applicationId}/location`);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <p className="text-center mt-8 text-2xl">How many dependants do you have?</p>
      <p className="text-center mt-8  w-96 mx-auto text-md">
        A dependant is anyone under the age of 18 who relies on you financially
      </p>
      <div className="mx-auto px-4 md:w-3/12">
        <div className="mt-10 mx-auto">
          <Formik
            initialValues={{ dependants: [] }}
            onSubmit={onSubmit}
            render={({ values }) => (
              <Form>
                <FieldArray
                  name="dependants"
                  render={(arrayHelpers) => (
                    <div className="mt-10">
                      {values.dependants.map((dependant, index) => {
                        return (
                          <div className="flex mt-4" key={index}>
                            <div className="w-3/12">
                              {index === 0 && <label>Age</label>}
                              <div key={index}>
                                <Field
                                  placeholder="Age"
                                  type="number"
                                  min="0"
                                  max="17"
                                  className="common-input-text"
                                  pattern="[0-9]+"
                                  name={`dependants.${index}.age`}
                                />
                              </div>
                            </div>
                            <div className="w-8/12 ml-2">
                              {index === 0 && <label>With</label>}
                              <div key={index}>
                                <Field
                                  as="select"
                                  placeholder="With"
                                  className="common-input-text"
                                  name={`dependants.${index}.with`}>
                                  <option></option>
                                  <option value="Non-Applicant">Non-Applicant</option>
                                </Field>
                              </div>
                            </div>
                            <div className="pt-8 ml-4">
                              <a
                                className="cursor-pointer"
                                title="Delete"
                                onClick={() => arrayHelpers.remove(index)}>
                                <i className="ri-close-circle-line ri-lg text-gray-400"></i>
                              </a>
                            </div>
                          </div>
                        );
                      })}
                      <button
                        type="button"
                        className="common-radio-rec-ghost-btn mt-8"
                        onClick={() => arrayHelpers.push('')}>
                        <i className="ri-add-line align-bottom"></i> Add a Dependant
                      </button>
                      <div className="mt-6">
                        <button type="submit" className="common-button">
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                />
              </Form>
            )}
          />
        </div>
      </div>
    </>
  );
};
