import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProgressContext } from '../App';
import { saveAppFieldInLocalStorage } from '../utils';
import { LOAN_APP_TYPE, MODES } from '../utils/constants';

export const PropertyUse = () => {
  const router = useNavigate();
  const location = useLocation();
  const [, , mode] = location.pathname.split('/');

  const { setProgressPercentage } = useContext(ProgressContext);

  useEffect(() => {
    setProgressPercentage(mode === MODES.finance ? 60 : 50);
  }, []);

  const setValue = (val) => {
    saveAppFieldInLocalStorage(
      LOAN_APP_TYPE.HomeLoan,
      'usage',
      val,
      router,
      `/home-loans/${mode}/credit-history`
    );
  };

  return (
    <>
      <p className="text-center mt-8 text-2xl px-2">How will this property be used?</p>

      <div className="mt-6 px-3 w-96 mx-auto">
        <p className="py-4">
          <button onClick={() => setValue('Owner Occupied')} className="common-radio-rec-ghost-btn">
            I will live there
          </button>
        </p>
        <p className="py-4">
          <button onClick={() => setValue('Investment')} className="common-radio-rec-ghost-btn">
            Its an investment
          </button>
        </p>
      </div>
    </>
  );
};
